<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="bvm_border rev_graf">
          <label class="graf_title"
            >{{ $t('keyTurnover_days') }} <a @click="$router.push('/settings?tab=service')">{{ $t('setSetting') }}</a>
            <q-img @click="addFav('keyTurnover_days', optionsBar, 'bar')" src="/images/icons/chart/b_shape_org.svg" alt="fav" class="favorite_h" />
          </label>
          <div>
            <BarChart :ch-options="optionsBar" style="height: 350px" v-if="ready" />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="bvm_border rev_graf">
          <label class="graf_title"
            >{{ $t('keyABC_analysis') }}
            <q-img
              @click="addFav('keyABC_analysis', { r: debRows, c: debColumns }, 'ave_tab')"
              src="/images/icons/chart/b_shape_org.svg"
              alt="fav"
              class="favorite_h"
            />
          </label>
          <div>
            <q-table
              v-if="debRows.length"
              class="prod-serv-dynamic"
              :data="debRows"
              :columns="debColumns"
              :loading="loading"
              row-key="id"
              style="height: 350px"
              :rows-per-page-options="[0]"
            >
              <template v-slot:header="props">
                <q-tr>
                  <q-th align="left" style="z-index: 5;width: max-content;" v-for="m in props.cols" :hidden="m.field == 'id'" :key="m.field">
                    {{ labels.tables[m.label] }}
                  </q-th>
                </q-tr>
              </template>
              <template v-slot:body="props">
                <q-tr :id="props.row.title" :data-set="props.row.id" class="prod-serv-tr">
                  <q-td v-for="col in props.cols" :key="Math.random() + col.field" :hidden="col.field == 'id'">
                    <span v-if="col.field.indexOf('proc') >= 0">
                      <b>{{ col.value }}%</b>
                    </span>
                    <span v-else-if="col.field == 'category'">
                      <span
                        class="cat_info"
                        @click="
                          () => {
                            props.expand = !props.expand;
                            getProd(props.row, !props.expand);
                          }
                        "
                      >
                        <span style="color: #FF975D" class="material-icons">{{ !props.expand ? 'add' : 'remove' }}</span>
                        &nbsp;<b class="c_title">{{ col.value }}</b></span
                      >
                    </span>
                    <div v-else class="type_cat">{{ col.value }}</div>
                  </q-td>
                </q-tr>
                <q-tr
                  style="background-color: rgba(220,220,220,0.38)"
                  :class="'prod' + props.row.id + (t % 2 == 0 ? ' bvm_b_table_grey' : ' bvm_b_table_white')"
                  v-show="props.expand && props.row.sub.length > 0"
                  v-for="(sub, t) in props.row.sub"
                  :key="Math.random() + sub.id"
                >
                  <q-td
                    v-for="(s, i) in sub"
                    :key="Math.random() + i"
                    :hidden="i == 'id'"
                    style="text-align: right; white-space: pre-wrap;max-width: 75px; font-size: 12px;"
                  >
                    <span v-if="i == 'category'" class="cat_info" style="min-width: 150px"> {{ s }} </span>
                    <span v-else class="type_cat" style="background-color:#FFECD0;color:#3D1601;width: 50px;padding: 2px 0">{{ s }}</span>
                  </q-td>
                </q-tr>
              </template>
              <template v-slot:bottom class="tt"></template>
            </q-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="rew_title">{{ $t('keyRevenue_increase') }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <Check_slider s-color="org" :s_data="s_dataLeft"></Check_slider>
      </div>
      <div class="col-12 col-md-6">
        <Check_slider s-color="org" :s_data="s_dataRight"></Check_slider>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/chart/BarChart';
import Check_slider from '@/components/items/check_slider';

export default {
  name: 't_products_service',
  components: { Check_slider, BarChart },
  props: {
    fType: {
      type: String
    },
    filters: {
      type: Object
    }
  },
  data: () => {
    return {
      abcTypes: ['day', 'weak', 'month', 'quart', 'year'],
      abcType: 'quart',
      slide: 1,
      slide2: 1,
      s_dataLeft: [
        {
          title: 'Рекомендации по товарной номенклатуре',
          kpif: 'Текущая достаточность',
          kpit: 'Потенциальная достаточность 30 дней',
          object: 'Товары',
          summ: 0,
          modal: {
            show: false,
            rows: [],
            columns: [
              {
                field: 'category',
                label: 'Рекомендации по повышению цены на основе изменения себестоимости'
              },
              {
                field: 'kpif',
                label: 'KPI факт'
              },
              {
                field: 'kpit',
                label: 'KPI цель'
              }
            ],
            loading: false
          }
        }
      ],
      s_dataRight: [
        {
          title: 'Рекомендации по товарной номенклатуре',
          kpif: 'Сегменты ABC',
          kpit: 'Потенциальная выручка(обзор товара)',
          object: 'Товары',
          summ: 0,
          modal: {
            show: false,
            rows: [],
            columns: [
              {
                field: 'category',
                label: 'Рекомендации по повышению цены на основе изменения себестоимости'
              },
              {
                field: 'kpif',
                label: 'KPI факт'
              },
              {
                field: 'kpit',
                label: 'KPI цель'
              }
            ],
            loading: false
          }
        }
      ],
      optionsBar: {
        color: ['#F39058'],
        legend: {
          padding: [0, 0, 30, 30],
          type: 'plain',
          data: [],
          left: 'auto'
        },
        dataZoom: {
          type: 'slider',
          start: 0,
          end: 10,
          z: 4,
          filterMode: 'filter',
          disabled: false,
          zoomLock: false,
          zoomOnMouseWheel: true,
          moveOnMouseMove: true,
          moveOnMouseWheel: false,
          preventDefaultMouseMove: true,
          height: 18,
          throttle: 100,
          startValue: 0,
          endValue: 1
        },
        xAxis: {
          type: 'category',
          data: [],
          axisPointer: {
            type: 'shadow'
          },
          axisLabel: {
            interval: 0,
            formatter: function(val) {
              return val;
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(val) {
              return val;
            }
          }
        },
        series: []
      },
      ready: true,
      debRows: [],
      debColumns: [],
      loading: true,
      labels: [],
      index: 0
    };
  },
  watch: {
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    },
    fType(val) {
      console.log(val);
    },
    filters() {
      this.init();
    }
  },
  created() {
    this.labels = this.$t('receiver');
  },
  mounted() {
    for (let f in this.filters) {
      this.$watch('filters.' + f, v => {});
    }

    console.log(this.filters.fType);
    if (this.filters.fType) {
      this.init().then(() => {});
    }
  },
  methods: {
    addFav(type, data, g) {
      this.$h.post('/favorite/add', { t: type, d: data, g: g }).then(r => {});
    },
    selectType(t) {
      this.fType = t;
    },
    exportAll() {},

    getTableData() {
      this.loading = true;
      this.index++;
      this.$h
        .post('/stat/avercheck/get-table-data', { f: this.filters })
        .then(r => {
          this.debColumns = r.data.data.f;
          this.debRows = r.data.data.d;
        })
        .then(() => {
          this.loading = false;
          this.index--;
        });
    },
    getProd(ct, p) {
      if (!p) {
        this.index++;
        this.loading = true;
        this.$h.post(`/stat/avercheck/get-table-prod?catId=${ct.id}`, { f: this.filters }).then(r => {
          ct.sub = r.data.data;
          this.loading = false;
          this.index--;
        });
      } else {
        ct.sub = [];
      }
    },
    getAdviceData() {
      this.loading = true;
      this.index++;
      this.$h
        .get('/stat/avercheck/get-advice-data')
        .then(r => {
          this.s_dataRight[0].modal.columns = r.data.data.f;
          this.s_dataRight[0].modal.rows = r.data.data.d;
          this.s_dataRight[0].summ = r.data.data.s;
          this.s_dataRight[0].link = 'products_ABC';
        })
        .then(() => {
          this.loading = false;
          this.index--;
        });
    },

    getTurnData() {
      this.s_dataLeft[0].modal.loading = true;
      this.index++;
      this.$h
        .get('/stat/avercheck/get-turn-data')
        .then(async r => {
          this.s_dataLeft[0].modal.columns = r.data.data.f;
          this.s_dataLeft[0].modal.rows = r.data.data.d;
          this.s_dataLeft[0].summ = r.data.data.s;
          this.s_dataLeft[0].link = 'products_l';
        })
        .then(() => {
          this.s_dataLeft[0].modal.loading = false;
          this.index--;
        });
    },
    getData() {
      this.index++;
      let l = this.optionsBar.xAxis.data.length;
      if (l) {
        this.optionsBar.xAxis.data.splice(0, l);
      }
      let d = this.optionsBar.legend.data.length;
      if (d) {
        this.optionsBar.legend.data.splice(0, d);
      }
      let s = this.optionsBar.series.length;
      if (s) {
        this.optionsBar.series.splice(0, s);
      }
      this.$h
        .post('/stat/avercheck/get-products-service', { f: this.filters })
        .then(r => {
          let months = r.data.data.m;
          let d1 = r.data.data.d1;

          this.optionsBar.xAxis.data = months;
          this.optionsBar.legend.data.push({
            name: 'Количество дней',
            padding: [0, 15]
          });
          this.optionsBar.series.push({
            name: 'Количество дней',
            type: 'bar',
            tooltip: {
              valueFormatter: function(value) {
                return value;
              }
            },
            label: {
              show: true,
              position: 'top',
              formatter: '{c}',
              color: '#F39058',
              fontSize: 12
            },
            itemStyle: {
              borderRadius: [4, 4, 0, 0] //, 0, 0],
            },
            data: d1
          });
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    async init() {
      await this.getData();
      await this.getTableData();
      await this.getAdviceData();
      await this.getTurnData();
    }
  }
};
</script>

<style lang="sass">
.prod-serv-tr
  height: 30px
  border-radius: 6px
  border: none
  border-color: rgba(0, 0, 0, 0) !important

.prod-serv-dynamic

  tbody.q-virtual-scroll__padding
    display: none

  tbody th, tbody td
    padding: 3px

  tbody td
    background: #FFF8EE
    padding: 0 2px
    height: 24px !important

  thead tr th
    position: sticky
    z-index: 1
    background-color: #ffffff !important
    text-align: center

  thead tr:last-child th
    z-index: 3

  thead tr:first-child th
    top: 0
    z-index: 1

  tr:first-child th:first-child
    /* highest z-index */
    z-index: 3

  td:first-child
    z-index: 1

  td:first-child, th:first-child
    position: sticky
    left: 0
    width: 200px !important
    white-space: pre-wrap
</style>
