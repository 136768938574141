<template>
  <div>
    <Z_pricing :f-type="fType" :filters="filters" v-if="$store.state.user.type === 'zarina'"></Z_pricing>
    <S_pricing :f-type="fType" :filters="filters" v-else-if="$store.state.user.type === 'services'"></S_pricing>
    <T_pricing :f-type="fType" :filters="filters" v-else></T_pricing>
  </div>
</template>

<script>
import T_pricing from '@/components/average_check/default/t_pricing';
import S_pricing from '@/components/average_check/services/t_pricing';
import Z_pricing from '@/components/average_check/zarina/z_pricing';

export default {
  name: 'pricing',
  components: { T_pricing, Z_pricing, S_pricing },
  props: {
    fType: {
      type: String
    },
    filters: {
      type: Object
    }
  }
};
</script>

<style scoped></style>
