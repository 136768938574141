<template>
  <q-page class="ub-page">
    <div class="row items-center">
      <q-btn @click="mUserCreate = true">{{ $t('addUser') }}</q-btn>
    </div>
    <div style="padding: 4px;">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :theme="vgtTheme"
        styleClass="vgt-table condensed bordered striped sws-table"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'id', type: 'asc' }
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: limit,
          perPageDropdown: [10, 20, 30, 50, 80],
          dropdownAllowAll: true,
          //jumpFirstOrLast : true,
          firstLabel: 'First Page',
          lastLabel: 'Last Page',
          nextLabel: $t('next'),
          prevLabel: $t('prev'),
          rowsPerPageLabel: $t('rpp'),
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
          infoFn: params => `my own page ${params.firstRecordOnPage}`
        }"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        @on-sort-change="onSortChange"
      >
        >
        <template slot="table-column" slot-scope="props">
          {{ $t('user_f')[props.column.field] }}
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'id'">
            <q-btn @click="$router.push(`/users/setting?id=${props.row.id}`)">{{ $t('edit') }}</q-btn>
          </span>
          <span v-else-if="props.column.field == 'action'">
            <q-btn @click="EditUser(props.row)">{{ $t('edit') }}</q-btn>
          </span>
          <span v-else-if="props.column.field == 'passwd'">
            <q-btn @click="EditUserPasswd(props.row.id, props.row.username)">{{ $t('setpasswd') }}</q-btn>
          </span>
          <span v-else-if="props.column.field == 'ftp'">
            <q-btn @click="EditUserFTP(props.row.id, props.row.username)"> FTP </q-btn>
          </span>
        </template>
      </vue-good-table>
    </div>
    <q-dialog v-model="mUserCreate" persistent>
      <q-card style="min-width: 600px">
        <q-card-section class="row">
          <q-form class="row" @submit="onCreate">
            <div v-for="(d, i) in userCreateDate" :key="i" class="col-md-6 col-12">
              <q-select v-if="i == 'role'" filled v-model="userEditData[i]" map-options emit-value :options="options" :label="$t('user_f')[i]" />
              <q-select v-else-if="i == 'status'" filled v-model="userEditData[i]" map-options emit-value :options="tariff" :label="$t('user_f')[i]" />
              <q-input
                v-else-if="i == 'cpassword'"
                type="password"
                lazy-rules
                required
                :rules="[val => (val.length > 0 && val == userCreateDate['password']) || $t('alert').conf_pass]"
                filled
                v-model="userCreateDate[i]"
                :value="d"
                :label="$t('user_f')[i]"
              />
              <q-input
                v-else-if="i == 'password'"
                type="password"
                lazy-rules
                required
                :rules="[val => (val && val.length > 6) || $t('alert').length_pass]"
                filled
                v-model="userCreateDate[i]"
                :value="d"
                :label="$t('user_f')[i]"
              />
              <q-input v-else-if="i == 'subemail'" type="email" filled v-model="userCreateDate[i]" :value="d" :label="$t('user_f')[i]" />
              <q-input
                v-else-if="i == 'email'"
                type="email"
                lazy-rules
                required
                :rules="[val => (val && val.length > 0) || $t('alert').pl_type + ' ' + $t('user_f')[i]]"
                filled
                v-model="userCreateDate[i]"
                :value="d"
                :label="$t('user_f')[i]"
              />
              <q-input
                v-else
                lazy-rules
                required
                :rules="[val => (val && val.length > 0) || $t('alert').pl_type + ' ' + $t('user_f')[i]]"
                filled
                v-model="userCreateDate[i]"
                :value="d"
                :label="$t('user_f')[i]"
              />
            </div>
            <q-btn flat :label="$t('cancel')" color="primary" v-close-popup />
            <q-btn flat :label="$t('addUser')" type="submit" color="primary" />
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="mUserEdit" persistent>
      <q-card style="min-width: 600px">
        <q-card-section class="row">
          <q-form class="row" @submit="onChangeUserData">
            <div v-for="(d, i) in userEditData" :key="i" class="col-md-6 col-12">
              <q-select v-if="i == 'role'" filled v-model="userEditData[i]" map-options emit-value :options="options" :label="$t('user_f')[i]" />
              <q-select v-else-if="i == 'status'" filled v-model="userEditData[i]" map-options emit-value :options="tariff" :label="$t('user_f')[i]" />
              <q-input
                v-else-if="i == 'phone'"
                lazy-rules
                required
                :rules="[val => (val && val.length > 0) || $t('alert').pl_type + ' ' + $t('user_f')[i]]"
                filled
                v-model="userEditData[i]"
                :value="d"
                :label="$t('user_f')[i]"
              />
              <q-input v-else-if="i == 'subemail'" type="email" filled v-model="userEditData[i]" :value="d" :label="$t('user_f')[i]" />
              <q-input
                :disable="i == 'id'"
                :hidden="i == 'id'"
                lazy-rules
                required
                :rules="[val => (val && val.length > 0) || $t('alert').pl_type + ' ' + $t('user_f')[i]]"
                v-else-if="i !== 'created_at' && i !== 'originalIndex' && i !== 'vgt_id' && i != 'status'"
                filled
                v-model="userEditData[i]"
                :value="d"
                :label="$t('user_f')[i]"
              />
            </div>
            <q-btn flat :label="$t('cancel')" color="primary" v-close-popup />
            <q-btn flat type="submit" :label="$t('update')" color="primary" />
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="mUserPasswd" persistent>
      <q-card style="min-width: 300px">
        <q-card-section>
          <div class="text-h6">{{ userEditPasswd.username }}</div>
        </q-card-section>
        <q-card-section class="row items-center">
          <q-form class="col-12" @submit="onChangePasswd">
            <div>
              <q-input
                type="password"
                lazy-rules
                required
                :rules="[val => (val && val.length > 6) || $t('alert').length_pass]"
                filled
                v-model="userEditPasswd.password"
                :label="$t('user_f').password"
              />
            </div>
            <div>
              <q-input
                type="password"
                lazy-rules
                required
                :rules="[val => (val.length > 0 && val == userEditPasswd.password) || $t('alert').conf_pass]"
                filled
                v-model="userEditPasswd.cpassword"
                :label="$t('user_f').cpassword"
              />
            </div>
            <q-btn flat :label="$t('cancel')" color="primary" v-close-popup />
            <q-btn flat :label="$t('update')" type="submit" color="primary" />
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="mUserFtp" persistent>
      <q-card style="min-width: 300px">
        <q-card-section>
          <div class="text-h6">{{ userEditFtp.username }}</div>
        </q-card-section>
        <q-card-section class="row items-center">
          <q-form class="col-12" @submit="onChangeFtp">
            <div>
              <q-input
                type="text"
                lazy-rules
                required
                :rules="[val => (val && val.length > 4) || $t('alert').length_pass]"
                filled
                v-model="userEditFtp.server"
                :label="$t('user_f').server"
              />
            </div>
            <div>
              <q-input
                type="text"
                lazy-rules
                required
                :rules="[val => (val && val.length > 4) || $t('alert').length_pass]"
                filled
                v-model="userEditFtp.login"
                :label="$t('user_f').login"
              />
            </div>
            <div>
              <q-input
                type="text"
                lazy-rules
                required
                :rules="[val => (val.length > 0 && val == userEditFtp.password) || $t('alert').conf_pass]"
                filled
                v-model="userEditFtp.password"
                :label="$t('user_f').password"
              />
            </div>
            <q-btn flat :label="$t('cancel')" color="primary" v-close-popup />
            <q-btn flat :label="$t('update')" type="submit" color="primary" />
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { vgtMethods } from '../../mixins/vgtmethods';
import { mapState } from 'vuex';
import { Notify } from 'quasar';

export default {
  name: 'Userlist',
  components: {},
  mixins: [vgtMethods],
  data() {
    return {
      mUserCreate: false,
      mUserEdit: false,
      mUserPasswd: false,
      mUserFtp: false,
      userEditPasswd: { username: '', id: 0, password: '', cpassword: '' },
      userEditFtp: { username: '', id: 0, login: '', password: '', server: 'localhost' },
      userEditData: {},
      userCreateDate: {},
      page: 1,
      limit: 30,
      sort: 'id',
      tsort: 'asc',
      timer: null,
      isDark: false,
      labels: {},
      columns: [
        //{ label: '', field: 'expand', width: '1%', tdClass: 'text-center pointer' },
      ],
      rows: [],
      tariff: [],
      options: {}
    };
  },
  computed: {
    ...mapState({
      dark: state => state.layout.dark
    }),
    vgtTheme: function() {
      return this.dark ? 'nocturnal' : 'default';
    }
  },
  watch: {},
  mounted() {
    this.initialize();
    this.labels = this.$t('user_f');
    this.ready = true;
  },
  methods: {
    onCreate() {
      this.$h.post('/users/create', { user: this.userCreateDate }).then(resp => {
        this.mUserCreate = false;
        if (resp.data.error == false) {
          Notify.create({
            position: 'top-right',
            type: 'positive',
            message: this.$t('success'),
            timeout: 1000
          });
        }
      });
    },
    onChangeUserData() {
      this.$h.post('/users/update', { user: this.userEditData }).then(resp => {
        this.mUserEdit = false;
        if (resp.data.error == false) {
          Notify.create({
            position: 'top-right',
            type: 'positive',
            message: this.$t('success'),
            timeout: 1000
          });
        }
      });
    },
    onChangePasswd() {
      this.$h.post('/users/set-passwd', { user: this.userEditPasswd }).then(resp => {
        if (resp.data.error == false) {
          Notify.create({
            position: 'top-right',
            type: 'positive',
            message: this.$t('success'),
            timeout: 1000
          });
        }
        this.mUserPasswd = false;
      });
    },
    onChangeFtp() {
      this.$h.post('/users/set-ftp', { ftp: this.userEditFtp }).then(resp => {
        if (resp.data.error == false) {
          Notify.create({
            position: 'top-right',
            type: 'error',
            message: resp.data.message,
            timeout: 1000
          });
          this.mUserFtp = false;
        } else if (resp.data.error) {
          Notify.create({
            position: 'top',
            type: 'error',
            message: resp.data.message,
            timeout: 3000
          });
        }
      });
    },
    initialize: function() {
      this.$h.get('/users/list?' + 'limit=' + this.limit + '&page=' + this.page + '&sort=' + this.sort + '&tsort=' + this.tsort).then(resp => {
        this.options = resp.data.data.options;
        this.columns = resp.data.data.title;
        this.rows = resp.data.data.list;
        this.userCreateDate = resp.data.data.creat_f;
        this.tariff = resp.data.data.tariff;
      });
    },
    updateStats: function() {
      // Update tables
      this.rows = utils.getApiStatsArray(dashboardData);
    },
    onPageChange(params) {
      this.limit = params.currentPerPage;
      this.page = params.currentPage;
      this.initialize();
    },
    onPerPageChange(params) {
      this.limit = params.currentPerPage;
      this.page = params.currentPage;
      this.initialize();
    },
    onSortChange(params) {
      this.sort = params[0].field;
      this.tsort = params[0].type;
      this.initialize();
    },
    EditUser(data) {
      this.userEditData = data;
      this.mUserEdit = true;
    },
    EditUserPasswd(id, username) {
      this.userEditPasswd.username = username;
      this.userEditPasswd.id = id;
      this.mUserPasswd = true;
    },
    EditUserFTP(id, username) {
      this.userEditFtp.username = username;
      this.userEditFtp.id = id;
      this.$h.get(`/users/get-ftp?uId=${id}`).then(resp => {
        this.userEditFtp.login = resp.data.data.login;
        this.userEditFtp.password = resp.data.data.password;
        this.userEditFtp.server = resp.data.data.server;
      });
      this.mUserFtp = true;
    }
  }
};
</script>
<style>
.q-notification__message {
  font-size: 16px !important;
  font-weight: bold;
  text-align: center;
}
</style>
