<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-12">
        <crm-filter
          v-if="fReady"
          @selectPeriod="selectFType"
          @selectFilters="selectFilters"
          :category="category"
          :f-types="fTypes"
          :m-types="mTypes"
          :rangDate="rangDate"
        ></crm-filter>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-12">
        <div class="bvm_border rev_a_filter">
          <div class="row">
            <div class="col-md-3 col-12">
              <div :class="'bvm_border rev_a_col_header rev_a_' + tp + '_' + tpS">
                <div class="row">
                  <div class="col-md-2 col-2">
                    <img :src="'/images/icons/' + tp + '.svg'" alt="" />
                  </div>
                  <div class="col-md-10 col-10">
                    <div class="rev_a_text_fl">
                      {{ textTp }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-12">
                    <div class="rev_a_f_l">
                      <span style="float: left">&nbsp;&nbsp; {{ $t('key_main_factor') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="bvm_border rev_a_col_header rev_a_col_org">
                <div class="row">
                  <div class="col-md-2 col-2">
                    <div class="rev_a_summ">
                      <img class="rev_a_img" src="/images/cek.svg" alt="" />
                    </div>
                  </div>
                  <div class="col-md-10 col-10">
                    <div class="rev_a_summ">
                      {{
                        aveCheck.v.toLocaleString('ru-Ru', {
                          style: 'currency',
                          currency: 'RUB',
                          maximumFractionDigits: 0
                        })
                      }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-12"></div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-12">
                    <div class="rev_a_f">
                      <div style="float: left">
                        <b>{{ $t('keyAverage_check') }}</b>
                      </div>
                      <div style="float: right">
                        <img :src="aveCheck.p > 0 ? '/images/icons/v_up.svg' : '/images/icons/v_down.svg'" />&nbsp;&nbsp; {{ aveCheck.p }}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="bvm_border rev_a_col_header rev_a_col_blue">
                <div class="row">
                  <div class="col-md-2 col-2">
                    <div class="rev_a_summ">
                      <img class="rev_a_img" src="/images/conversion.svg" alt="" />
                    </div>
                  </div>
                  <div class="col-md-10 col-10">
                    <div class="rev_a_summ">
                      {{ custFlow.v }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-12">
                    <b>{{ $t('keyPurchaseProbability') }}</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-12">
                    <div class="rev_a_f">
                      <div style="float: left">
                        {{ $t('keyPurchase_frequency_conversion') }}
                      </div>
                      <div style="float: right">
                        <img :src="custFlow.p > 0 ? '/images/icons/v_up.svg' : '/images/icons/v_down.svg'" />&nbsp;&nbsp; {{ custFlow.p }}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="bvm_border rev_a_col_header rev_a_col_purp">
                <div class="row">
                  <div class="col-md-2 col-2">
                    <div class="rev_a_summ">
                      <img class="rev_a_img" src="/images/client.svg" alt="" />
                    </div>
                  </div>
                  <div class="col-md-10 col-10">
                    <div class="rev_a_summ">
                      {{ purchProb.v }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-12"></div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-12">
                    <div class="rev_a_f">
                      <div style="float: left">
                        <b>{{ $t('keyCustomer_flow') }}</b>
                      </div>
                      <div style="float: right">
                        <img :src="purchProb.p > 0 ? '/images/icons/v_up.svg' : '/images/icons/v_down.svg'" />&nbsp;&nbsp; {{ purchProb.p }}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="bvm_border rev_graf">
          <label class="reva_graf_title"
            >{{ $t('keyConversion_dynamics') }}
            <q-img @click="addFav('keyConversion_dynamics', optionsCon, 'line')" src="/images/icons/favorite_blue.svg" alt="fav" class="favorite_h"
          /></label>
          <div>
            <LineChart :ch-options="optionsCon" style="height: 270px" v-if="ready" />
          </div>
          <div class="row">
            <div class="col-md-6 col-6">
              <div class="rew_f_s">
                <div class="rev_a_summ">
                  <img class="rev_a_img" src="/images/conversion.svg" alt="" />
                  <span style="color: #0889b2"
                    >{{
                      dinConvers.toLocaleString('ru-Ru', {
                        style: 'currency',
                        currency: 'RUB',
                        maximumFractionDigits: 0
                      })
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-6">
              <div class="rev_a_f_s" style="cursor: pointer" @click="$router.push('/increasing')">
                <div class="rev_f_text t_blue">{{ $t('keyIncrease_Conversion') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="bvm_border rev_graf">
          <label class="reva_graf_title"
            >{{ $t('keyPurchase_frequency_dynamics') }}
            <q-img @click="addFav('keyPurchase_frequency_dynamics', optionsP, 'line')" src="/images/icons/favorite_blue.svg" alt="fav" class="favorite_h"
          /></label>
          <div>
            <LineChart :ch-options="optionsP" style="height: 270px" v-if="ready" />
          </div>
          <div class="row">
            <div class="col-md-6 col-6">
              <div class="rew_f_s">
                <div class="rev_a_summ">
                  <img class="rev_a_img" src="/images/conversion.svg" alt="" />
                  <span style="color: #0889b2"
                    >{{
                      dinPricing.toLocaleString('ru-Ru', {
                        style: 'currency',
                        currency: 'RUB',
                        maximumFractionDigits: 0
                      })
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-6">
              <div class="rev_a_f_s" style="cursor: pointer" @click="$router.push('/increasing/frequency')">
                <div class="rev_f_text t_blue">{{ $t('keyIncrease_shopping_frequency') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="bvm_border rev_graf">
          <label class="reva_graf_title"
            >{{ $t('keyCustomer_flow_dynamics') }}
            <q-img @click="addFav('keyCustomer_flow_dynamics', optionsCl, 'line')" src="/images/icons/favorite_purp.svg" alt="fav" class="favorite_h" />
          </label>
          <div>
            <LineChart :ch-options="optionsCl" style="height: 270px" v-if="ready" />
          </div>
          <div class="row">
            <div class="col-md-6 col-6">
              <div class="rew_f_s">
                <div class="rev_a_summ">
                  <img class="rev_a_img" src="/images/client.svg" alt="" />
                  <span style="color: #593ad3"
                    >{{
                      dinClients.toLocaleString('ru-Ru', {
                        style: 'currency',
                        currency: 'RUB',
                        maximumFractionDigits: 0
                      })
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-6">
              <div class="rev_a_f_s" style="cursor: pointer" @click="$router.push('/growth/customers')">
                <div class="rev_f_text t_purp">{{ $t('keyIncrease_number_clients') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="bvm_border rev_graf">
          <label class="reva_graf_title"
            >{{ $t('keyDynamics_average_check') }}
            <q-img @click="addFav('keyDynamics_average_check', optionsCh, 'line')" src="/images/icons/favorite_org.svg" alt="fav" class="favorite_h"
          /></label>
          <div>
            <LineChart :ch-options="optionsCh" style="height: 270px" v-if="ready" />
          </div>
          <div class="row">
            <div class="col-md-6 col-6">
              <div class="rew_f_s">
                <div class="rev_a_summ">
                  <img class="rev_a_img t_org" src="/images/cek.svg" alt="" />
                  <span style="color: #c94800"
                    >{{
                      avetch.toLocaleString('ru-Ru', {
                        style: 'currency',
                        currency: 'RUB',
                        maximumFractionDigits: 0
                      })
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-6">
              <div class="rev_a_f_s" style="cursor: pointer" @click="$router.push('/average_check/structure_checks')">
                <div class="rev_f_text t_org">{{ $t('keyIncrease_average_check') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/chart/LineChart';
import crmFilter from '@/components/items/crmFilter';

export default {
  name: 'revenue_analysis',
  components: { LineChart, crmFilter },
  data: () => {
    return {
      s_data: [],
      fTypes: ['weak', 'month', 'quart', 'year'],
      fType: 'year',
      mTypes: [],
      category: [],
      fShow: false,
      ready: false,
      fReady: false,
      filters: {},
      rangDate: [],
      purchProb: { v: 0, p: 0 },
      custFlow: { v: 0, p: 0 },
      aveCheck: { v: 0, p: 0 },
      setRange: 0,
      optionsCon: {
        series: [],
        color: '#0889B2',
        grid: {
          top: '20',
          bottom: '35',
          containLabel: false
        },
        legend: {
          padding: [0, 0, 0, 0],
          width: '100%',
          //type: 'scroll',
          data: [],
          overflow: 'break'
        },
        tooltip: {
          trigger: 'axis',
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], '10%'];
          },
          renderMode: 'html',
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          step: 1,
          splitLine: {
            show: true
          },
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true
          }
        },
        title: {}
      },
      optionsP: {
        series: [],
        color: '#0889B2',
        grid: {
          top: '20',
          bottom: '35',
          containLabel: false
        },
        legend: {
          padding: [0, 0, 0, 0],
          width: '100%',
          //type: 'scroll',
          data: [],
          overflow: 'break'
        },
        tooltip: {
          trigger: 'axis',
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], '10%'];
          },
          renderMode: 'html',
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          step: 1,
          splitLine: {
            show: true
          },
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true
          },
          axisLabel: {
            formatter: function(val) {
              return val;
            }
          }
        },
        title: {}
      },
      optionsCl: {
        series: [],
        color: '#593AD3',
        grid: {
          top: '20',
          bottom: '35',
          containLabel: false
        },
        legend: {
          padding: [0, 0, 0, 0],
          width: '100%',
          //type: 'scroll',
          data: [],
          overflow: 'break'
        },
        tooltip: {
          trigger: 'axis',
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], '10%'];
          },
          renderMode: 'html',
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          step: 1,
          splitLine: {
            show: true
          },
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true
          },
          axisLabel: {
            formatter: function(val) {
              return val;
            }
          }
        },
        title: {}
      },
      optionsCh: {
        series: [],
        color: '#C94800',
        grid: {
          top: '20',
          bottom: '35',
          containLabel: false
        },
        legend: {
          padding: [0, 0, 0, 0],
          width: '100%',
          //type: 'scroll',
          data: [],
          overflow: 'break'
        },
        tooltip: {
          trigger: 'axis',
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], '10%'];
          },
          renderMode: 'html',
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          step: 1,
          splitLine: {
            show: true
          },
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          type: 'value',
          step: 300,
          splitLine: {
            show: true
          }
        },
        title: {}
      },
      avetch: 0,
      dinClients: 0,
      dinPricing: 0,
      dinConvers: 0,
      index: 0,
      calendarOpen: false,
      months: [],
      years: [],
      fP: 0,
      pF: 0,
      tp: 'up',
      tpS: 'summ',
      textTp: '',
      bUrl: '/stat/analitics/'
    };
  },
  watch: {
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
        this.ready = true;
      } else {
        this.$store.state.progress = true;
        this.ready = false;
      }
    },
    filters() {
      if (this.index == 0) {
        this.optionsCh.series.splice(0, this.optionsCh.series.length);
        this.optionsP.series.splice(0, this.optionsP.series.length);
        this.optionsCon.series.splice(0, this.optionsCon.series.length);
        this.optionsCl.series.splice(0, this.optionsCl.series.length);
        this.init();
      }
    }
  },
  created() {
    this.$store.state.subTitle = new Date();
    this.getMain();
  },
  methods: {
    addFav(type, data, g) {
      this.$h.post('/favorite/add', { t: type, d: data, g: g }).then(r => {});
    },
    selectFType(arg) {
      // this.fType = arg;
    },
    selectFilters(arg) {
      this.filters = {};
      for (let a in arg) {
        this.filters[a] = arg[a];
      }
      //\\
    },
    getMain() {
      this.$h
        .get(`${this.bUrl}get-main`)
        .then(r => {
          this.category = r.data.data.category;
          this.mTypes = r.data.data.types;
          let d = r.data.data.date;
          for (let l in d) {
            this.rangDate.push(parseInt(d[l].y));
          }
        })
        .then(() => {
          this.ready = true;
          this.fReady = true;
        });
    },
    getSubMain() {
      this.index++;
      this.optionsCh.series.splice(0, this.optionsCh.series.length);
      this.optionsP.series.splice(0, this.optionsP.series.length);
      this.optionsCon.series.splice(0, this.optionsCon.series.length);
      this.optionsCl.series.splice(0, this.optionsCl.series.length);
      this.$h
        .post(`${this.bUrl}get-sub-main`, { f: this.filters })
        .then(r => {
          this.custFlow = r.data.data.custFlow;
          this.purchProb = r.data.data.purchProb;
          this.aveCheck = r.data.data.aveCheck;
          this.category = r.data.data.category;
          this.tp = r.data.data.i;
          this.tpS = r.data.data.sType;
          this.textTp = this.$t('fact')[this.tp][this.tpS];
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    exportAll() {},
    getReviewData() {
      this.index++;
      this.$h
        .get(`${this.bUrl}get-years?type=${this.fType}`)
        .then(r => {
          this.years = r.data.data;
        })
        .then(() => {
          this.index--;
        });
    },
    getDataConversion() {
      this.index++;
      this.$h
        .post(`${this.bUrl}get-conversion`, { f: this.filters })
        .then(r => {
          let months = r.data.data.m;
          let d1 = r.data.data.d1;
          let mt = [];
          switch (this.filters.fType) {
            case 'month':
              for (let m in months) {
                mt.push(this.$t('monthNames')[m].substring(0, 3));
              }
              this.optionsCon.xAxis.axisLabel.interval = 0;
              break;
            case 'year':
              mt = months;
              this.optionsCon.xAxis.axisLabel.interval = 0;
              break;
            case 'weak':
              mt = months;
              this.optionsCon.xAxis.axisLabel.interval = 3;
              break;
            case 'quart':
              mt = months;
              this.optionsCon.xAxis.axisLabel.interval = 0;
              break;
          }
          this.optionsCon.xAxis.data = mt;
          this.optionsCon.series.push({
            data: d1,
            areaStyle: {
              color: '#0889B2',
              origin: 'auto',
              shadowBlur: 0,
              opacity: 0.05
            },
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          });
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    getDataPurch() {
      this.index++;
      this.$h
        .post(`${this.bUrl}get-purch`, { f: this.filters })
        .then(r => {
          let months = r.data.data.m;
          let d1 = r.data.data.d1;
          let mt = [];
          switch (this.filters.fType) {
            case 'month':
              for (let m in months) {
                mt.push(this.$t('monthNames')[m].substring(0, 3));
              }
              this.optionsP.xAxis.axisLabel.interval = 0;
              break;
            case 'year':
              mt = months;
              this.optionsP.xAxis.axisLabel.interval = 0;
              break;
            case 'weak':
              mt = months;
              this.optionsP.xAxis.axisLabel.interval = 3;
              break;
            case 'quart':
              mt = months;
              this.optionsP.xAxis.axisLabel.interval = 0;
              break;
          }
          this.optionsP.xAxis.data = mt;
          this.optionsP.series.push({
            data: d1,
            areaStyle: {
              color: '#0889B2',
              origin: 'auto',
              shadowBlur: 0,
              opacity: 0.05
            },
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          });
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    getDataClients() {
      this.index++;
      this.$h
        .post(`${this.bUrl}get-clients`, { f: this.filters })
        .then(r => {
          let months = r.data.data.m;
          let d1 = r.data.data.d1;
          let mt = [];
          switch (this.filters.fType) {
            case 'month':
              for (let m in months) {
                mt.push(this.$t('monthNames')[m].substring(0, 3));
              }
              this.optionsCl.xAxis.axisLabel.interval = 0;
              break;
            case 'year':
              mt = months;
              this.optionsCl.xAxis.axisLabel.interval = 0;
              break;
            case 'weak':
              mt = months;
              this.optionsCl.xAxis.axisLabel.interval = 3;
              break;
            case 'quart':
              mt = months;
              this.optionsCl.xAxis.axisLabel.interval = 0;
              break;
          }
          this.optionsCl.xAxis.data = mt;
          this.optionsCl.series.push({
            data: d1,
            areaStyle: {
              color: '#593AD3',
              origin: 'auto',
              shadowBlur: 0,
              opacity: 0.05
            },
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          });
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    getDataChecks() {
      this.index++;

      this.$h
        .post(`${this.bUrl}get-checks`, { f: this.filters })
        .then(r => {
          this.avetch = r.data.data.s;
          let months = r.data.data.m;
          let d1 = r.data.data.d1;
          let mt = [];

          switch (this.filters.fType) {
            case 'month':
              for (let m in months) {
                mt.push(this.$t('monthNames')[m].substring(0, 3));
              }
              this.optionsCh.xAxis.axisLabel.interval = 0;
              break;
            case 'year':
              mt = months;
              this.optionsCh.xAxis.axisLabel.interval = 0;
              break;
            case 'weak':
              mt = months;
              this.optionsCh.xAxis.axisLabel.interval = 3;
              break;
            case 'quart':
              mt = months;
              this.optionsCh.xAxis.axisLabel.interval = 0;
              break;
          }
          const met = this.$t('met');
          this.optionsCh.xAxis.data = mt;
          this.optionsCh.yAxis.axisLabel = {
            formatter: function(val) {
              if (parseInt(val).toString().length < 4) {
                return val;
              } else if (parseInt(val).toString().length < 6) {
                return val / 1000 + met.th;
              } else {
                return val / 1000000 + met.mln;
              }
            }
          };
          this.optionsCh.series.push({
            data: d1,
            areaStyle: {
              color: '#C94800',
              origin: 'auto',
              shadowBlur: 0,
              opacity: 0.05
            },
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          });
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    async init() {
      await this.getSubMain();
      await this.getReviewData();
      await this.getDataConversion();
      await this.getDataPurch();
      await this.getDataChecks();
      await this.getDataClients();
    }
  },
  mounted() {
    //this.init();
  }
};
</script>

<style scoped></style>
