<template>
  <div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="bvm_border rev_graf">
          <label class="reva_graf_title"
            >{{ $t('keyConversion_dynamics') }}
            <q-img @click="addFav('keyConversion_dynamics', optionsCon, 'line')" src="/images/icons/favorite_blue.svg" alt="fav" class="favorite_h" />
          </label>
          <div>
            <LineChart :ch-options="optionsCon" style="height: 270px" v-if="ready" />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="bvm_border rev_graf">
          <label class="reva_graf_title"
            >{{ $t('keyPurchase_frequency_dynamics') }}
            <q-img @click="addFav('keyPurchase_frequency_dynamics', optionsP, 'line')" src="/images/icons/favorite_blue.svg" alt="fav" class="favorite_h" />
          </label>
          <div>
            <LineChart :ch-options="optionsP" style="height: 270px" v-if="ready" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <Check_slider s-color="blue" :s_data="s_dataLeft"></Check_slider>
      </div>
    </div>
  </div>
</template>
<div>
</div>
<script>
import Check_slider from '@/components/items/check_slider';
import LineChart from '@/chart/LineChart';

export default {
  name: 'conversion',
  props: {
    fType: {
      type: String
    },
    filters: {
      type: Object
    }
  },
  components: { LineChart, Check_slider },
  data: () => {
    return {
      slide: 1,
      slide2: 1,
      optionsCon: {
        series: [],
        color: '#0889B2',
        grid: {
          top: '20',
          bottom: '35',
          containLabel: false
        },
        legend: {
          padding: [0, 0, 0, 0],
          width: '100%',
          //type: 'scroll',
          data: [],
          overflow: 'break'
        },
        tooltip: {
          trigger: 'axis',
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], '10%'];
          },
          renderMode: 'html',
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          step: 1,
          splitLine: {
            show: true
          },
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true
          }
        },
        title: {}
      },
      optionsP: {
        series: [],
        color: '#0889B2',
        grid: {
          top: '20',
          bottom: '35',
          containLabel: false
        },
        legend: {
          padding: [0, 0, 0, 0],
          width: '100%',
          //type: 'scroll',
          data: [],
          overflow: 'break'
        },
        tooltip: {
          trigger: 'axis',
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], '10%'];
          },
          renderMode: 'html',
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          step: 1,
          splitLine: {
            show: true
          },
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true
          },
          axisLabel: {
            formatter: function(val) {
              return val;
            }
          }
        },
        title: {}
      },
      s_dataLeft: [
        {
          title: 'Рекомендация по клиентам',
          kpif: 'Количество клиентов в сегменте',
          kpit: 'Потенциальное количество клиентов в сегменте',
          object: 'Сегмент клиентов',
          summ: 0,
          modal: {
            show: false,
            rows: [],
            columns: [
              {
                field: 'category',
                label: 'Рекомендация по клиентам'
              },
              {
                field: 'kpif',
                label: 'KPI факт'
              },
              {
                field: 'kpit',
                label: 'KPI цель'
              },
              {
                field: 'kpis',
                label: 'Потенциальная выручка'
              }
            ],
            loading: false
          }
        }
      ],
      ready: true,
      index: 0,
      bUrl: '/stat/analitics/'
    };
  },
  watch: {
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    },
    filters() {
      if (this.index == 0) {
        this.init();
      }
    }
  },
  created() {},
  mounted() {
    if (this.filters.fType) {
      this.init();
    }
  },
  methods: {
    selectType(t) {
      //this.fType = t;
    },
    exportAll() {},
    addFav(type, data, g) {
      this.$h.post('/favorite/add', { t: type, d: data, g: g }).then(r => {});
    },
    getDataConversion() {
      this.index++;
      this.$h
        .post(`${this.bUrl}get-conversion`, { f: this.filters })
        .then(r => {
          let months = r.data.data.m;
          let d1 = r.data.data.d1;
          let mt = [];
          switch (this.filters.fType) {
            case 'month':
              for (let m in months) {
                mt.push(this.$t('monthNames')[m].substring(0, 3));
              }
              this.optionsCon.xAxis.axisLabel.interval = 0;
              break;
            case 'year':
              mt = months;
              this.optionsCon.xAxis.axisLabel.interval = 0;
              break;
            case 'weak':
              mt = months;
              this.optionsCon.xAxis.axisLabel.interval = 3;
              break;
            case 'quart':
              mt = months;
              this.optionsCon.xAxis.axisLabel.interval = 0;
              break;
          }
          this.optionsCon.xAxis.data = mt;
          this.optionsCon.series.push({
            data: d1,
            areaStyle: {
              color: '#0889B2',
              origin: 'auto',
              shadowBlur: 0,
              opacity: 0.05
            },
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          });
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    getDataPurch() {
      this.index++;
      this.$h
        .post(`${this.bUrl}get-purch`, { f: this.filters })
        .then(r => {
          let months = r.data.data.m;
          let d1 = r.data.data.d1;
          let mt = [];
          switch (this.filters.fType) {
            case 'month':
              for (let m in months) {
                mt.push(this.$t('monthNames')[m].substring(0, 3));
              }
              this.optionsP.xAxis.axisLabel.interval = 0;
              break;
            case 'year':
              mt = months;
              this.optionsP.xAxis.axisLabel.interval = 0;
              break;
            case 'weak':
              mt = months;
              this.optionsP.xAxis.axisLabel.interval = 3;
              break;
            case 'quart':
              mt = months;
              this.optionsP.xAxis.axisLabel.interval = 0;
              break;
          }
          this.optionsP.xAxis.data = mt;
          this.optionsP.series.push({
            data: d1,
            areaStyle: {
              color: '#0889B2',
              origin: 'auto',
              shadowBlur: 0,
              opacity: 0.05
            },
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          });
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    getRFM() {
      this.index++;
      this.$h.get(`/stat/increasing/get-rfm`).then(r => {
        this.s_dataLeft[0].modal.rows = r.data.data.seg;
        this.s_dataLeft[0].summ = r.data.data.summ;
        this.s_dataLeft[0].link = 'products_RFM_conv';

        this.loading = false;
        this.index--;
      });
    },
    init() {
      this.optionsP.series.splice(0, this.optionsP.series.length);
      this.optionsCon.series.splice(0, this.optionsCon.series.length);
      this.getDataConversion();
      this.getDataPurch();
      this.getRFM();
    }
  }
};
</script>

<style scoped></style>
