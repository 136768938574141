<template>
  <div>
    <q-carousel
      v-if="s_data.length"
      v-model="nSlide"
      transition-prev="slide-right"
      transition-next="slide-left"
      swipeable
      animated
      :control-color="'s_' + sColor"
      navigation
      padding
      arrows
      height="250px"
      class="bvm_border ave_graf"
      ref="carousel"
    >
      <q-carousel-slide v-for="(s, i) in s_data" :name="i" :key="i" class="column no-wrap">
        <div class="row bslide_m">
          <div class="col-5">
            <div class="bslide_text">Предлагаются к обработке новые потенциальные клиенты</div>
          </div>
          <div class="col-7">
            <div class="row">
              <div class="col-4">
                <div :class="'bslide_tip bsl_color_' + sColor">
                  <img :src="'/images/icons/slide/pric_info_' + sColor + '.svg'" alt="fav" class="favorite_h" />
                  KPI факт
                </div>
                <div class="bslide_tip_text ">Количество клиентов: 590</div>
              </div>
              <div class="col-4">
                <div :class="'bslide_tip bsl_color_' + sColor">
                  <img :src="'/images/icons/slide/pric_target_' + sColor + '.svg'" alt="fav" class="favorite_h" />KPI факт
                </div>
                <div class="bslide_tip_text">Количество клиентов: 590</div>
              </div>
              <div class="col-4">
                <div :class="'bslide_tip bsl_color_' + sColor">
                  <img :src="'/images/icons/slide/pric_shape_' + sColor + '.svg'" alt="fav" class="favorite_h" />KPI факт
                </div>
                <div class="bslide_tip_text">Мероприятия</div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="foot_slide_more">
          <div class="row">
            <div class="col-5">
              <div class="foot_info">
                <div class="p_t">Выручка до:</div>
                <div :class="'summ bsl_color_' + sColor">+ 9 500 000 ₽</div>
              </div>
            </div>
            <div class="col-7">
              <div class="foot_info">
                <div class="row">
                  <div class="col-6">
                    <div @click="fixed = !fixed" :class="'slide_t_more bsl_color_' + sColor">
                      <img :src="'/images/icons/slide/more_' + sColor + '.svg'" alt="fav" class="favorite_h" />
                      <span>Подробная информация</span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div :class="'slide_but bbsl_color_' + sColor">Беру в работу</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </q-carousel-slide>
    </q-carousel>
    <q-dialog v-model="fixed">
      <q-card class="bvm_modal_rec">
        <q-card-section>
          <q-table
            class="ave-serv-dynamic"
            :data="rows"
            :columns="columns"
            :loading="loading"
            row-key="id"
            style="height: 400px"
            virtual-scroll
            :rows-per-page-options="[0]"
          ></q-table>
        </q-card-section>
        <q-card-actions align="center">
          <div style="text-align: center">
            <div @click="fixed = !fixed" :class="'slide_t_more bsl_color_' + sColor">
              <img :src="'/images/icons/slide/more_' + sColor + '.svg'" alt="fav" class="favorite_h" />
              <span>Свернуть</span>
            </div>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'slider',
  props: {
    sColor: {
      type: String,
      default: 'black'
    },
    s_data: {
      type: Array,
      default: function() {
        return [];
      }
    },
    rows: {
      type: Array,
      default: function() {
        return [];
      }
    },
    columns: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data: () => {
    return {
      loading: false,
      fixed: false,
      nSlide: 0,
      autoplay: false
    };
  }
};
</script>

<style scoped>
.bvm_modal_rec {
  min-width: 700px;
  width: 75%;
  border-radius: 16px;
}
</style>
