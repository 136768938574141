<template>
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="bvm_border rev_graf">
        <label class="graf_title">{{ $t('keyCustomers_traffic_sales') }}<img src="/images/icons/b_shape.svg" alt="fav" class="favorite_h"/></label>
        <div>
          <BarChart :ch-options="optionsBar" style="height: 350px" v-if="ready" />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <Slider sColor="blue"></Slider>
    </div>
    <div class="col-12 col-md-6">
      <Slider sColor="blue"></Slider>
    </div>
  </div>
</template>

<script>
import BarChart from '@/chart/BarChart';
import Slider from '@/components/items/slider';

export default {
  name: 'customers',
  components: { Slider, BarChart },
  props: {
    fType: {
      type: String
    },
    filters: {
      type: Object
    }
  },
  data: () => {
    return {
      slide1: 1,
      slide2: 1,
      ready: true,
      optionsBar: {
        color: '#593AD3',
        legend: {
          padding: [0, 0, 30, 30],
          type: 'plain',
          data: [],
          left: 'auto'
        },
        xAxis: {
          step: 1,
          splitLine: {
            show: true
          },
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(val) {
              return val + ' %';
            }
          }
        },
        series: []
      },
      months: [],
      index: 0,
      bUrl: 'stat/increasing/'
    };
  },
  watch: {
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    },
    fType(val) {
      this.getData();
    },
    filters() {
      if (this.index == 0) {
        this.getData();
      }
    }
  },
  created() {
    this.months = this.$t('monthNames');
    this.labels = this.$t('receiver');
  },
  mounted() {
    if (this.filters.fType) {
      this.getData();
    }
  },
  methods: {
    selectType(t) {
      //this.fType = t;
    },
    exportAll() {},
    getData() {
      this.index++;
      this.$h
        .get('/stat/increasing/get-traff')
        .then(r => {
          let cat = r.data.data.cat;
          let d1 = r.data.data.d1;

          this.optionsBar.xAxis.data = cat;
          this.optionsBar.legend.data.push({
            name: '% от общего трафика',
            padding: [0, 15]
          });
          this.optionsBar.series.push({
            name: '% от общего трафика',
            type: 'bar',
            tooltip: {
              valueFormatter: function(value) {
                return value;
              }
            },
            label: {
              show: false,
              position: 'top',
              formatter: '{c} M',
              color: '#593AD3',
              fontSize: 8
            },
            itemStyle: {
              borderRadius: [25, 25, 0, 0], //, 0, 0],
              //shadowBlur: 3
              opacity: 1
            },
            data: d1
          });
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    }
  }
};
</script>

<style lang="sass"></style>
