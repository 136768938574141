<template>
  <q-page class="ub-page">
    <div class="row">
      <div class="col-md-3 col-12">
        <div class="bvm_border rev_col_header">
          <div class="row">
            <div class="col-md-2 col-2">
              <img src="/images/icons/Shape.svg" alt="" />
            </div>
            <div class="col-md-8 col-8">
              <div class="rew_h_text">{{ $t('keyPlane') }}</div>
              <div class="rew_h_text">
                <b>{{ $t('monthNames')[pfData.m] + ' ' + pfData.y }}</b>
              </div>
            </div>
            <div class="col-md-2 col-2">
              <img class="rev_btn" src="/images/icons/settings.svg" alt="" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="rew_summ_z">
                Сумма -
                {{
                  plan.toLocaleString('ru-Ru', {
                    style: 'currency',
                    currency: 'RUB',
                    maximumFractionDigits: 0
                  })
                }}
              </div>
              <div class="rew_summ_z">
                Марж. -
                {{ marg }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="rew_f">
                <span style="float: left">&nbsp;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12">
        <div class="bvm_border rev_col_header">
          <div class="row">
            <div class="col-md-2 col-2">
              <img src="/images/icons/dates.svg" alt="" />
            </div>
            <div class="col-md-10 col-10">
              <div class="rew_h_text">{{ $t('keyFact_on') }}</div>
              <div class="rew_h_text">
                <b>{{ $t('monthNames')[pfData.m] + ' ' + pfData.y }}</b>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="rew_summ_z">
                Факт. -
                {{
                  pFact
                    ? pFact.toLocaleString('ru-Ru', {
                        style: 'currency',
                        currency: 'RUB',
                        maximumFractionDigits: 0
                      })
                    : '-'
                }}
              </div>
              <div class="rew_summ_z">Марж. - {{ pMarg }} %</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div :class="'rew_f ' + (pF ? ' rew_f_green' : 'rew_f_red')">
                <span :style="'float: left;' + (pF > 0 ? 'color:#1BBD79' : 'color:#E03030')">
                  <img :src="pF > 0 ? '/images/icons/v_up.svg' : '/images/icons/v_down.svg'" />&nbsp;&nbsp; {{ pF }}%</span
                >
                <span :style="'float: right;' + (pF > 0 ? 'color:#1BBD79' : 'color:#E03030')">{{
                  pFact
                    ? (pFact - pFactPrev).toLocaleString('ru-Ru', {
                        style: 'currency',
                        currency: 'RUB',
                        maximumFractionDigits: 0
                      })
                    : '-'
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12">
        <div class="bvm_border rev_col_header">
          <div class="row">
            <div class="col-md-2 col-2">
              <img src="/images/icons/dates.svg" alt="" />
            </div>
            <div class="col-md-10 col-10">
              <div class="rew_h_text">{{ $t('keyForecast') }}</div>
              <div class="rew_h_text">
                <b>{{ $t('monthNames')[pfData.m] + ' ' + pfData.y }}</b>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="rew_summ">
                {{
                  forecast
                    ? forecast.toLocaleString('ru-Ru', {
                        style: 'currency',
                        currency: 'RUB',
                        maximumFractionDigits: 0
                      })
                    : '-'
                }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="rew_f">
                <span :style="'float: left;' + (fP > 0 ? 'color:#1BBD79' : 'color:#E03030')"
                  ><img :src="fP > 0 ? '/images/icons/v_up.svg' : '/images/icons/v_down.svg'" />&nbsp;&nbsp; {{ fP }}%</span
                >
                <span :style="'float: right;' + (fP > 0 ? 'color:#1BBD79' : 'color:#E03030')">{{
                  forecastP
                    ? forecastP.toLocaleString('ru-Ru', {
                        style: 'currency',
                        currency: 'RUB',
                        maximumFractionDigits: 0
                      })
                    : '-'
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12">
        <div :class="'bvm_border rev_col_header rev_col_' + tp + '_' + tpS">
          <div class="row">
            <div class="col-md-2 col-2">
              <img :src="'/images/icons/' + tp + '.svg'" alt="" />
            </div>
            <div class="col-md-3 col-3"></div>
            <div class="col-md-6 col-6">
              <q-btn class="rev_btn rew_btn_s" @click="$router.push('/revenue_analysis')">{{ $t('keyMore') }}</q-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="rew_text_fl">
                {{ textTp }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="rew_f_l">
                <span style="float: left">&nbsp;&nbsp; {{ $t('key_main_factor') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12">
        <label class="rew_title">{{ $t('keyRecommendations_revenue') }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-12">
        <div class="bvm_border rev_col_header">
          <div class="row">
            <div class="col-md-2 col-2">
              <img src="/images/icons/check_org.svg" alt="" />
            </div>
            <div class="col-md-5 col-5">
              <div class="rew_h_text">{{ $t('keyRaise_average_check') }}</div>
            </div>
            <div class="col-md-5 col-5">
              <div @click="$router.push('/average_check/structure_checks')" class="rew_btn_org rev_btn">{{ $t('keyMore') }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="rew_summ_b">
                <div class="lft">
                  {{
                    spotAve.v.toLocaleString('ru-Ru', {
                      style: 'currency',
                      currency: 'RUB',
                      maximumFractionDigits: 0
                    })
                  }}
                </div>
                <div class="rft">
                  {{
                    potAve
                      ? '+' +
                        potAve.toLocaleString('ru-Ru', {
                          style: 'currency',
                          currency: 'RUB',
                          maximumFractionDigits: 0
                        })
                      : '-'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12">
        <div class="bvm_border rev_col_header">
          <div class="row">
            <div class="col-md-2 col-2">
              <img src="/images/icons/conversion_blue.svg" alt="" />
            </div>
            <div class="col-md-5 col-5">
              <div class="rew_h_text">{{ $t('keyIncrease_conversion') }}</div>
            </div>
            <div class="col-md-5 col-5">
              <div @click="$router.push('/increasing')" class="rew_btn_bl rev_btn">{{ $t('keyMore') }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div v-if="this.$store.state.user.type === 'zarina'" class="rew_summ_b">
                <div class="lft">{{ spotConf.v }}% ({{ $t('keyUnits') }})&nbsp;/&nbsp;</div>
                <div class="lft">{{ spotConfP.v }}% (₽)</div>
                <div class="rft">
                  {{
                    potConf
                      ? '+' +
                        potConf.toLocaleString('ru-Ru', {
                          style: 'currency',
                          currency: 'RUB',
                          maximumFractionDigits: 0
                        })
                      : '-'
                  }}
                </div>
              </div>
              <div v-else-if="this.$store.state.user.type === 'goods'" class="rew_summ_b">
                <div class="lft">{{ spotConf.v }}% ({{ $t('keyUnits') }})&nbsp;/&nbsp;</div>
                <div class="lft">{{ spotConfP.v }}% (₽)</div>
                <div class="rft">
                  {{
                    potConf
                      ? '+' +
                        potConf.toLocaleString('ru-Ru', {
                          style: 'currency',
                          currency: 'RUB',
                          maximumFractionDigits: 0
                        })
                      : '-'
                  }}
                </div>
              </div>
              <div v-else class="rew_summ_b">
                <div class="lft">{{ spotConf.v }}%</div>
                <div class="rft">
                  {{
                    potConf
                      ? '+' +
                        potConf.toLocaleString('ru-Ru', {
                          style: 'currency',
                          currency: 'RUB',
                          maximumFractionDigits: 0
                        })
                      : '-'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12">
        <div class="bvm_border rev_col_header">
          <div class="row">
            <div class="col-md-2 col-2">
              <img src="/images/icons/users.svg" alt="" />
            </div>
            <div class="col-md-5 col-5">
              <div v-if="this.$store.state.user.type == 'zarina'" class="rew_h_text">{{ $t('keyOrderFlow') }}</div>
              <div v-else-if="this.$store.state.user.type == 'goods'" class="rew_h_text">{{ $t('keyOrderFlow') }}</div>
              <div v-else class="rew_h_text">{{ $t('keyIncrease_traffic') }}</div>
            </div>
            <div class="col-md-5 col-5">
              <div @click="$router.push('/growth/customers')" class="rew_btn_f rev_btn">{{ $t('keyMore') }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div v-if="this.$store.state.user.type == 'zarina'" class="rew_summ_b_m">
                <div v-for="(m, i) in marks" :key="m + i">
                  <div class="lft">{{ $t('markets')[i] }} - {{ m.all }} / {{ m.d }}</div>
                  <br />
                </div>
              </div>
              <div v-else-if="this.$store.state.user.type == 'goods'" class="rew_summ_b_m">
                <div v-for="(m, i) in marks" :key="m + i">
                  <div class="lft">{{ $t('markets')[i] }} - {{ m.all }} / {{ m.d }}</div>
                  <br />
                </div>
              </div>
              <div v-else class="rew_summ_b">
                <div class="lft">{{ spotTraff.v }} {{ $t('keyUnits') }}</div>
                <div class="rft">
                  {{
                    potTraff
                      ? '+' +
                        potTraff.toLocaleString('ru-Ru', {
                          style: 'currency',
                          currency: 'RUB',
                          maximumFractionDigits: 0
                        })
                      : '-'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-12">
        <div class="bvm_border rev_col_header">
          <div class="row">
            <div class="col-md-2 col-2">
              <img src="/images/icons/shape_f.svg" alt="" />
            </div>
            <div class="col-md-10 col-10">
              <div class="rew_h_text">{{ $t('keyPotential_revenue_growth') }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="rew_summ_b">
                <div class="lft">
                  {{
                    potCash
                      ? '+' +
                        potCash.toLocaleString('ru-Ru', {
                          style: 'currency',
                          currency: 'RUB',
                          maximumFractionDigits: 0
                        })
                      : '-'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <z-filter
      v-if="fReady"
      @selectPeriod="selectFType"
      @selectFilters="selectFilters"
      :category="category"
      :f-types.sync="fTypes"
      :m-types.sync="mTypes"
      :rangDate.sync="rangDate"
    />
    <div class="row">
      <div class="col-md-12 col-12">
        <div class="bvm_border rev_col_graf">
          <LineChart :ch-options="options" style="height: 400px" v-if="ready" />
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import LineChart from '@/chart/LineChart';
import ZFilter from '@/components/items/zFilter.vue';

export default {
  name: 'rev',
  components: { ZFilter, LineChart },
  data: () => {
    return {
      fTypes: ['day', 'weak', 'month', 'quart'],
      fType: 'month',
      mTypes: [],
      filters: {},
      rangDate: [],
      category: [],
      fReady: false,
      ready: false,
      options: {
        series: [],
        color: ['#718096', '#3A78D3'],
        legend: {
          padding: [0, 0, 45, 30],
          right: 30,
          top: 45,
          width: '50%',
          //type: 'scroll',
          data: [],
          overflow: 'break'
        },
        tooltip: {
          trigger: 'axis',
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], '10%'];
          },
          renderMode: 'html',
          formatter: function(val) {
            let oldY = val[0].data;
            let oldYN = val[1].data;
            if (oldYN == null) {
              oldYN = val[2].data;
            }

            let d = document.createElement('div');
            d.classList.add('rew_tooltip');
            if (oldYN && oldY) {
              let proc = (oldYN - oldY) / oldYN;
              let pc =
                proc >= 0
                  ? '<span class="rew_f_green">' +
                    proc.toLocaleString('ru-Ru', {
                      style: 'percent',
                      currency: 'RUB',
                      maximumFractionDigits: 2
                    }) +
                    '</span>'
                  : '<span class="rew_f_red">' +
                    proc.toLocaleString('ru-Ru', {
                      style: 'percent',
                      currency: 'RUB',
                      maximumFractionDigits: 2
                    }) +
                    '</span>';
              d.innerHTML =
                '' +
                '<div class="row">' +
                '<div class="col-md-8 col-8"><span class="summ">' +
                (oldYN - oldY).toLocaleString('ru-Ru', {
                  style: 'currency',
                  currency: 'RUB',
                  maximumFractionDigits: 0
                }) +
                '</span></div>' +
                '<div class="col-md-4 col-4"><img src="/images/icons/mini_ch.svg" alt=""><span class="proc">' +
                pc +
                '</span></div>' +
                '</div><div class="row">' +
                '<div class="col-md-6 col-6"><span class="oldY">' +
                parseInt(oldYN).toLocaleString('ru-Ru', {
                  style: 'currency',
                  currency: 'RUB',
                  maximumFractionDigits: 0
                }) +
                '</span></div>' +
                '<div class="col-md-6 col-6"><span class="oldYN">' +
                parseInt(oldY).toLocaleString('ru-Ru', {
                  style: 'currency',
                  currency: 'RUB',
                  maximumFractionDigits: 0
                }) +
                '</span></div>' +
                '</div>';
            } else if (oldYN) {
              return parseInt(oldYN).toLocaleString('ru-Ru', {
                style: 'currency',
                currency: 'RUB',
                maximumFractionDigits: 0
              });
            } else if (oldY) {
              return parseInt(oldY).toLocaleString('ru-Ru', {
                style: 'currency',
                currency: 'RUB',
                maximumFractionDigits: 0
              });
            }
            return d;
          },
          axisPointer: {
            animation: false,
            type: 'shadow'
          }
        },
        xAxis: {
          step: 1,
          scale: true,
          splitLine: {
            show: false
          },
          boundaryGap: [0, 0.01],
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          scale: true,
          type: 'value',
          splitLine: {
            show: true
          },
          axisLabel: {
            formatter: function(val) {
              return val.toLocaleString('ru-Ru', {
                style: 'currency',
                currency: 'RUB',
                maximumFractionDigits: 0
              });
            }
          }
        },
        title: {},
        grid: {
          top: 100,
          right: 30
        }
      },
      index: 0,
      pFact: 0,
      pMarg: 0,
      pFactPrev: 0,
      pfData: [],
      plan: 0,
      marg: 0,
      forecast: 0,
      forecastP: 0,
      fP: 0,
      pF: 0,
      tp: 'up',
      tpS: 'summ',
      textTp: '',
      potTraff: 0,
      potConf: 0,
      potAve: 0,
      spotTraff: { v: 0 },
      spotConf: { v: 0 },
      spotConfP: { v: 0 },
      spotAve: { v: 0 },
      marks: { ozon: 0, wilbd: 0 },
      potCash: 0,
      bUrl: '/stat/'
    };
  },
  created() {
    this.getReviewData();
  },
  mounted() {
    this.options.title = {
      text: 'Динамика фактической и потенциальной выручки',
      textAlign: 'left',
      padding: [45, 30],
      overflow: 'break',
      textStyle: {
        width: '50%',
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        overflow: 'break',
        /* identical to box height, or 20px */
        display: 'flex',
        alignItems: 'center',
        color: '#05156D'
      }
    };

    this.init(); //.then(() => {});
  },
  watch: {
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    },
    filters() {
      if (this.index == 0) {
        this.fType = this.filters.fType;
        this.options.legend.data = [];
        this.options.xAxis.data = [];
        this.options.series = [];
        this.getData();
      }
    }
  },
  methods: {
    getReviewData() {
      this.index++;
      this.$h
        .get('/stat/rvw/get-years')
        .then(r => {
          let d = r.data.data;
          for (let l in d) {
            this.rangDate.push(parseInt(d[l].y));
          }
        })
        .then(() => {
          this.fReady = true;
          this.index--;
        });
    },
    getFactor() {
      this.index++;
      this.$h.get('/stat/rvw/get-factor').then(r => {
        this.index--;
      });
    },
    getMain() {
      this.index++;
      this.$h.get(`${this.bUrl}rvw/get-main`).then(r => {
        this.pFact = r.data.data.pFact;
        this.pMarg = r.data.data.pMarg;
        this.pFactPrev = r.data.data.pFactPrev;
        this.pfData = r.data.data.pfData;
        this.plan = r.data.data.plane;
        this.marg = r.data.data.marg;
        this.forecast = r.data.data.forecast;
        this.forecastP = r.data.data.forecastP;
        this.fP = Math.round(this.forecast ? ((this.forecast - this.forecastP) / this.forecast) * 100 : 0);
        this.pF = Math.round(this.pFact ? ((this.pFact - this.pFactPrev) / this.pFact) * 100 : 0);
        this.tp = r.data.data.i;
        this.tpS = r.data.data.sType;
        this.textTp = this.$t('fact')[this.tp][this.tpS];
        this.index--;
      });
    },
    getSubMain() {
      this.index++;
      this.$h.get(`${this.bUrl}rvw/get-sub-main`).then(r => {
        this.potTraff = r.data.data.potTraff;
        this.potConf = r.data.data.cnv;
        this.potAve = r.data.data.ach;
        this.potCash = r.data.data.potCash;
        this.spotConf = r.data.data.custFlow;
        this.spotConfP = r.data.data.custFlowP;
        this.marks = r.data.data.marks;
        this.spotAve = r.data.data.aveCheck;
        this.spotTraff = r.data.data.purchProb;
        this.index--;
      });
    },
    getData() {
      this.options.legend.data.splice(0, this.options.legend.data.length);
      this.options.xAxis.data.splice(0, this.options.xAxis.data.length);
      this.options.series.splice(0, this.options.series.length);
      this.index++;
      this.$h
        .post(`${this.bUrl}rvw/get-data`, { f: this.filters })
        .then(r => {
          let months = r.data.data.m;
          let d1 = r.data.data.d1;
          let d2 = r.data.data.d2;

          let mt = [];

          switch (this.fType) {
            case 'month':
              for (let m in months) {
                mt.push(this.$t('monthNames')[m]);
              }
              break;
            default:
              mt = months;
              break;
          }

          this.options.xAxis.data = mt;

          this.options.legend.data.push({
            name: `Выручка\n${d1.y}`,
            icon: 'image://https://' + window.location.host + '/images/icons/chart/up_f.svg',
            padding: [0, 15]
          });
          this.options.series.push({
            name: `Выручка\n${d1.y}`,
            data: d1.d,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          });
          this.options.legend.data.push({
            name: `Выручка\n${d2.y}`,
            icon: 'image://https://' + window.location.host + '/images/icons/chart/up_s.svg',
            padding: [0, 15]
          });
          this.options.series.push({
            name: `Выручка\n${d2.y}`,
            data: d2.d,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          });
          let p1 = r.data.data.p1;
          let p2 = r.data.data.p2;
          this.options.legend.data.push({
            name: `Прогноз выручки\n${p1.y}`,
            icon: 'image://https://' + window.location.host + '/images/icons/chart/up_f_p.svg',
            padding: [0, 15]
          });
          this.options.series.push({
            name: `Прогноз выручки\n${p1.y}`,
            data: p1.d,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3,
              type: 'dashed'
            }
          });
          this.options.legend.data.push({
            name: `Потенциал выручки\n${p2.y}`,
            icon: 'image://https://' + window.location.host + '/images/icons/chart/up_s_p.svg',
            padding: [0, 15]
          });

          this.options.series.push({
            name: `Потенциал выручки\n${p2.y}`,
            data: p2.d,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3,
              type: 'dashed'
            }
          });
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    selectFType(arg) {
      this.fType = arg;
    },
    selectFilters(arg) {
      this.filters = {};
      for (let a in arg) {
        this.filters[a] = arg[a];
      }
      this.fType = this.filters.fType;
      this.options.xAxis.data.splice(0, this.options.xAxis.data.length);
      this.options.series.splice(0, this.options.series.length);
      this.options.legend.data.splice(0, this.options.legend.data.length);
      this.getData();
    },
    init() {
      this.getMain();
      this.getSubMain();
      this.getFactor();
    }
  }
};
</script>

<style scoped></style>
