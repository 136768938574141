<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="bvm_border rev_graf">
          <label class="graf_title"
            >{{ $t('keySales_structure') }}
            <q-img @click="addFav('keySales_structure', optionsBar, 'bar')" src="/images/icons/chart/b_shape_org.svg" alt="fav" class="favorite_h" />
          </label>
          <div>
            <BarChart :ch-options="optionsBar" style="height: 350px" v-if="ready" />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="bvm_border rev_graf">
          <label class="graf_title"
            >{{ $t('keyRevenue_potential') }}
            <q-img @click="addFav('keyRevenue_potential', optionsPricing, 'bar')" src="/images/icons/chart/b_shape_org.svg" alt="fav" class="favorite_h" />
          </label>
          <div>
            <BarChart :ch-options="optionsPricing" style="height: 350px" v-if="ready" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <label class="rew_title">{{ $t('keyRevenue_increase') }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <Check_slider s-color="org" :s_data="s_dataLeft"></Check_slider>
      </div>
      <div class="col-12 col-md-6">
        <Check_slider s-color="org" :s_data="s_dataRight"></Check_slider>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/chart/BarChart';
import Check_slider from '@/components/items/check_slider';

export default {
  name: 't_pricing',
  components: { Check_slider, BarChart },
  props: {
    fType: {
      type: String
    },
    filters: {
      type: Object
    }
  },
  data: () => {
    return {
      s_dataLeft: [
        {
          title: 'Рекомендации по повышению цены на основе изменения себестоимости',
          kpif: 'Текущий процент маржинальности',
          kpit: 'Средняя маржинальность по категориям',
          object: 'Товары',
          summ: 0,
          modal: {
            show: false,
            rows: [],
            columns: [
              {
                field: 'category',
                label: 'Рекомендации по повышению цены на основе изменения себестоимости'
              },
              {
                field: 'kpif',
                label: 'KPI факт'
              },
              {
                field: 'kpit',
                label: 'KPI цель'
              }
            ],
            loading: false
          }
        }
      ],
      s_dataRight: [
        {
          title: 'Рекомендации по повышению цены на основе рынка',
          kpif: 'Текущая средняя стоимость категории',
          kpit: 'Потенциальная средняя стоимость категории',
          object: 'Товары',
          summ: 0,
          modal: {
            show: false,
            rows: [],
            columns: [
              {
                field: 'category',
                label: 'Рекомендации по повышению цены на основе рынка'
              },
              {
                field: 'kpif',
                label: 'KPI факт'
              },
              {
                field: 'kpit',
                label: 'KPI цель'
              }
            ],
            loading: false
          }
        }
      ],
      slide: 1,
      slide2: 1,
      optionsBar: {
        color: ['#E0641F', '#F39058'],
        legend: {
          padding: [0, 0, 30, 30],
          type: 'plain',
          data: [],
          left: 'auto'
        },
        dataZoom: {
          type: 'slider',
          start: 0,
          end: 10,
          z: 4,
          filterMode: 'filter',
          disabled: false,
          zoomLock: false,
          zoomOnMouseWheel: true,
          moveOnMouseMove: true,
          moveOnMouseWheel: false,
          preventDefaultMouseMove: true,
          throttle: 100,
          startValue: 0,
          endValue: 1,
          height: 18
        },
        xAxis: {
          type: 'category',
          data: [],
          axisPointer: {
            type: 'shadow'
          },
          axisLabel: {
            interval: 0,
            formatter: function(val) {
              return val;
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(val) {
              return val + ' %';
            }
          }
        },
        series: []
      },
      optionsPricing: {
        color: ['#E0641F', '#F39058', '#FFD2B9'],
        legend: {
          padding: [0, 0, 30, 30],
          type: 'plain',
          data: [],
          left: 'auto'
        },
        dataZoom: {
          type: 'slider',
          start: 0,
          end: 10,
          z: 4,
          filterMode: 'filter',
          disabled: false,
          zoomLock: false,
          zoomOnMouseWheel: true,
          moveOnMouseMove: true,
          moveOnMouseWheel: false,
          preventDefaultMouseMove: true,
          throttle: 100,
          startValue: 0,
          endValue: 1,
          height: 18
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(val) {
              return val + ' %';
            }
          }
        },
        series: []
      },
      ready: true,
      index: 0
    };
  },
  created() {
    this.$store.state.color = 'orange-14';
  },
  watch: {
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    },
    fType(val) {
      this.getData();
    },
    filters() {
      if (this.index == 0) {
        this.init().then(() => {});
      }
    }
  },
  mounted() {
    for (let f in this.filters) {
      this.$watch('filters.' + f, v => {});
    }

    if (this.filters.fType) {
      this.init().then(() => {});
    }
  },
  methods: {
    addFav(type, data, g) {
      this.$h.post('/favorite/add', { t: type, d: data, g: g }).then(r => {});
    },
    selectType(t) {
      this.fType = t;
    },
    exportAll() {},

    getData() {
      this.ready = false;
      this.index++;
      let l = this.optionsBar.legend.data.length;
      this.optionsBar.legend.data.splice(0, l);
      let s = this.optionsBar.series.length;
      this.optionsBar.series.splice(0, s);
      this.$h
        .post(`/stat/avercheck/get-pricing`, { f: this.filters })
        .then(r => {
          let cats = r.data.data.m;
          let cats2 = r.data.data.m2;
          let d1 = r.data.data.d1;
          let d2 = r.data.data.d2;
          let mt = [];
          for (let c in cats) {
            mt.push(cats[c]);
          }
          let mt2 = [];
          for (let c in cats2) {
            mt2.push(cats2[c]);
          }
          this.optionsPricing.xAxis[0].data = mt;

          this.optionsBar.xAxis.data.splice(0, this.optionsBar.xAxis.data.length);
          this.optionsBar.series.splice(0, this.optionsBar.series.length);
          this.optionsBar.legend.data.splice(0, this.optionsBar.legend.data.length);

          this.optionsBar.xAxis.data = cats2;

          this.optionsBar.legend.data.push({
            name: '% от сделок'
            // padding: [0, 15]
          });
          this.optionsBar.series.push({
            name: '% от сделок',
            type: 'bar',
            tooltip: {
              valueFormatter: function(value) {
                return value;
              }
            },
            label: {
              show: true,
              position: 'top',
              formatter: '{c}%',
              color: '#F39058',
              fontSize: 8
            },
            itemStyle: {
              borderRadius: [50, 50, 0, 0], //, 0, 0],
              //shadowBlur: 3
              opacity: 1
            },
            data: d2
          });

          let dc2 = r.data.data.cd;
          let ind = 1;

          let h = this.optionsPricing.legend.data.length;
          if (h) {
            this.optionsPricing.legend.data.splice(0, h);
          }
          let s = this.optionsPricing.series.length;
          if (s) {
            this.optionsPricing.series.splice(0, s);
          }
          for (let c in dc2) {
            this.optionsPricing.legend.data.push(dc2[c].title);
            this.optionsPricing.series.push({
              name: dc2[c].title,
              type: 'bar',
              stack: 'category2',
              tooltip: {
                valueFormatter: function(value) {
                  return value;
                }
              },
              itemStyle: {
                borderRadius: c == dc2.length - 1 ? [4, 4, 0, 0] : 0 //, 0, 0],
                //shadowBlur: 3
              },
              data: dc2[c].data
            });
            ind++;
          }
        })
        .then(() => {
          this.index--;
          this.ready = true;
        });
    },
    getCatList() {
      this.index++;
      this.$h.get('/stat/avercheck/get-rec-mcat').then(r => {
        this.s_dataLeft[0].modal.rows = r.data.data.l.list;
        this.s_dataLeft[0].summ = r.data.data.l.sum;
        this.s_dataLeft[0].link = 'pricing_l';

        this.s_dataRight[0].modal.rows = r.data.data.r.list;
        this.s_dataRight[0].summ = r.data.data.r.sum;
        this.s_dataRight[0].link = 'pricing_r';

        this.index--;
      });
    },

    async init() {
      await this.getData();
      await this.getCatList();
    }
  }
};
</script>

<style scoped></style>
