import axios from 'axios';
import login from '@/pages/login';

const token = localStorage.getItem('token');
const locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : navigator.language.split('-')[0];
const user = JSON.parse(localStorage.getItem('user')) || null;
let type = '/goods';

if (user) {
  type = '/' + user.type;
}

var baseUrl = `http://45.10.53.71:8088/v1${type}`;
if (window.location.host == 'localhost:8080') {
  baseUrl = `http://45.10.53.71:8088/v1${type}`;
} else if (window.location.host != '45.10.53.71') {
  baseUrl = `https://api.bvmax.ru/v1${type}`;
}

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    Bvmtoken: 'token ' + token,
    Locale: locale
  }
});

api.interceptors.request.use(
  async config => {
    config.headers.common['Bvmtoken'] = 'token ' + token;
    config.headers.common['Locale'] = locale;
    return config;
  },
  async error => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  async response => {
    const d = response.data;
    if (d.status) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
    return response;
  },
  async error => {
    return error;
  }
);
export default api;
