<template>
  <q-page class="ub-page">
    <div class="row">
      <div class="col-12 col-md-12">
        <c-filter
          v-if="ready && $store.state.user.type == 'services'"
          @selectPeriod="selectFType"
          @selectFilters="selectFilters"
          :category="category"
          :f-types="fTypes"
          :m-types="mTypes"
          :rangDate="rangDate"
          :sRange="true"
        ></c-filter>
        <z-filter
          v-else-if="ready && $store.state.user.type == 'zarina'"
          @selectPeriod="selectFType"
          @selectFilters="selectFilters"
          :category="category"
          :f-types="fTypes"
          :m-types="mTypes"
          :rangDate="rangDate"
          :sRange="true"
        ></z-filter>
        <b-filter
          v-else-if="ready && $store.state.user.type != 'services'"
          @selectPeriod="selectFType"
          @selectFilters="selectFilters"
          :category="category"
          :f-types="fTypes"
          :m-types="mTypes"
          :rangDate="rangDate"
          :sRange="true"
        ></b-filter>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-5">
        <label class="rew_title">{{ $t('a_check')[selectP] }}</label>
      </div>
      <div class="col-12 col-md-7">
        <div class="row">
          <div class="col-12 col-md-4" v-if="$store.state.user.type !== 'services'"></div>
          <div class="col-12 col-md-4" v-if="$store.state.user.type == 'services'">
            <div
              :class="'bvm_border incr_col_header' + (selectP == 'managers' ? '_a' : '')"
              @click="selectP == 'managers' ? '' : $router.push('/increasing/managers')"
            >
              <div class="row">
                <div class="col-md-4 col-4">
                  <img src="/images/icons/Shape.svg" alt="" />
                </div>
                <div class="col-md-8 col-8">
                  <div class="incr_h_text">
                    {{
                      rfm.toLocaleString('ru-Ru', {
                        style: 'currency',
                        currency: 'RUB',
                        maximumFractionDigits: 0
                      })
                    }}
                  </div>
                  <div class="incr_h_text">
                    <b>{{ $t('keySalesFunnels') }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div
              :class="'bvm_border incr_col_header' + (selectP == 'increasing' ? '_a' : '')"
              @click="selectP == 'increasing' ? '' : $router.push('/increasing/')"
            >
              <div class="row">
                <div class="col-md-4 col-4">
                  <img src="/images/icons/Shape.svg" alt="" />
                </div>
                <div class="col-md-8 col-8">
                  <div class="incr_h_text">
                    {{
                      conv.toLocaleString('ru-Ru', {
                        style: 'currency',
                        currency: 'RUB',
                        maximumFractionDigits: 0
                      })
                    }}
                  </div>
                  <div class="incr_h_text">
                    <b>{{ $t('keyConvrsion') }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div
              :class="'bvm_border incr_col_header' + (selectP == 'frequency' ? '_a' : '')"
              @click="selectP == 'frequency' ? '' : $router.push('/increasing/frequency')"
            >
              <div class="row">
                <div class="col-md-4 col-4">
                  <img src="/images/icons/Shape.svg" alt="" />
                </div>
                <div class="col-md-8 col-8">
                  <div class="incr_h_text">
                    {{
                      rfm.toLocaleString('ru-Ru', {
                        style: 'currency',
                        currency: 'RUB',
                        maximumFractionDigits: 0
                      })
                    }}
                  </div>
                  <div class="incr_h_text">
                    <b>{{ $t('a_check.frequency') }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view :f-type.sync="fType" :filters.sync="filters" />
  </q-page>
</template>

<script>
import bFilter from '@/components/items/bFilter';
import cFilter from '@/components/items/crmFilter';
import ZFilter from '@/components/items/zFilter.vue';

export default {
  components: { ZFilter, bFilter, cFilter },
  name: 'increasing',
  props: {
    subTitle: null
  },
  data: () => {
    return {
      fTypes: ['weak', 'month', 'quart', 'year'],
      fType: 'year',
      mTypes: [],
      filters: {},
      rangDate: [],
      category: [],
      fShow: false,
      ready: false,
      setRange: 0,
      selectP: 'pricing',
      conv: 0,
      rfm: 0
    };
  },
  watch: {
    '$route.name'(val) {
      this.selectP = val;
      console.log(val);
    }
  },
  created() {
    if (this.$store.state.user.type !== 'services') {
      this.fTypes = ['day', 'weak', 'month', 'quart'];
      this.fType = 'month';
    }
    this.selectP = this.$route.name;
    this.$store.state.color = 'light-blue';
    this.$store.state.progress = false;
    this.getMain();
  },
  mounted() {
    this.getReviewData();
  },
  methods: {
    selectType(t) {
      //this.fType = t;
    },
    getMain() {
      this.index++;
      this.$h.get('/stat/increasing/get-main').then(r => {
        this.conv = r.data.data.ltv;
        this.rfm = r.data.data.rfm;
        this.category = r.data.data.category;
        this.mTypes = r.data.data.types;
        this.index--;
      });
    },
    getReviewData() {
      this.index++;
      this.ready = false;
      this.$h
        .get('/stat/increasing/get-years')
        .then(r => {
          let d = r.data.data;
          for (let l in d) {
            this.rangDate.push(parseInt(d[l].y));
          }
          this.ready = true;
        })
        .then(() => {
          this.index--;
        });
    },
    selectFType(arg) {
      this.fType = arg;
    },
    selectFilters(arg) {
      this.filters = {};
      for (let a in arg) {
        this.filters[a] = arg[a];
      }
    }
  }
};
</script>

<style scoped></style>
