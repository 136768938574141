<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="bvm_border rev_graf">
        <label class="graf_title"
          >{{ $t('keyConvCount') }}
          <q-img @click="addFav('keyltv', optionsBar, 'bar')" src="/images/icons/b_shape.svg" alt="fav" class="favorite_h" />
        </label>
        <div>
          <LineChart :ch-options="optionsBar" style="height: 350px" v-if="ready" />
        </div>
      </div>
      <div>
        <Check_slider s-color="blue" :s_data="s_dataLeft"></Check_slider>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="bvm_border rev_graf">
        <label class="graf_title"
          >{{ $t('keyConvPayPs') }}
          <q-img @click="addFav('keyRevenue_potential', optionsPricing, 'bar')" src="/images/icons/b_shape.svg" alt="fav" class="favorite_h" />
        </label>
        <div>
          <LineChart :ch-options="optionsPricing" style="height: 350px" v-if="ready" />
        </div>
      </div>
      <div>
        <div class="col-12 col-md-6">
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Check_slider from '@/components/items/check_slider';
import LineChart from '@/chart/LineChart';

export default {
  name: 'conversion',
  props: {
    fType: {
      type: String
    },
    filters: {
      type: Object
    }
  },
  components: { LineChart, Check_slider },
  data: () => {
    return {
      slide: 1,
      slide2: 1,
      optionsBar: {
        color: ['#3CB1D7', '#3A78D3'],
        legend: {
          padding: [0, 0, 30, 30],
          type: 'plain',
          data: [],
          left: 'auto'
        },
        xAxis: {
          type: 'category',
          data: [],
          axisPointer: {
            type: 'shadow'
          }
        },
        yAxis: {
          type: 'value'
        },
        series: []
      },
      optionsPricing: {
        color: ['#3CB1D7', '#3A78D3'],
        legend: {
          padding: [0, 0, 30, 30],
          type: 'plain',
          data: [],
          left: 'auto'
        },
        xAxis: {
          type: 'category',
          data: [],
          axisPointer: {
            type: 'shadow'
          }
        },
        yAxis: {
          type: 'value'
        },
        series: []
      },
      s_dataLeft: [
        {
          title: 'Рекомендация по клиентам',
          kpif: 'Количество клиентов в сегменте',
          kpit: 'Потенциальное количество клиентов в сегменте',
          object: 'Сегмент клиентов',
          summ: 0,
          modal: {
            show: false,
            rows: [],
            columns: [
              {
                field: 'category',
                label: 'Рекомендация по клиентам'
              },
              {
                field: 'kpif',
                label: 'KPI факт'
              },
              {
                field: 'kpit',
                label: 'KPI цель'
              },
              {
                field: 'kpis',
                label: 'Потенциальная выручка'
              }
            ],
            loading: false
          }
        }
      ],
      ready: true,
      index: 0,
      bUrl: '/stat/increasing/'
    };
  },
  watch: {
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    },
    filters() {
      this.optionsBar.xAxis.data.splice(0, this.optionsBar.xAxis.data.length);
      this.optionsBar.legend.data.splice(0, this.optionsBar.legend.data.length);
      this.optionsBar.series.splice(0, this.optionsBar.series.length);

      this.optionsPricing.xAxis.data.splice(0, this.optionsPricing.xAxis.data.length);
      this.optionsPricing.legend.data.splice(0, this.optionsPricing.legend.data.length);
      this.optionsPricing.series.splice(0, this.optionsPricing.series.length);
      this.getData();
      this.getRFM();
    }
  },
  created() {},
  mounted() {
    if (this.filters.fType) {
      this.init();
    }
  },
  methods: {
    selectFilters() {},
    selectType(t) {
      //this.fType = t;
    },
    exportAll() {},
    addFav(type, data, g) {
      this.$h.post('/favorite/add', { t: type, d: data, g: g }).then(r => {});
    },

    getData() {
      this.index++;
      this.$h
        .post(`${this.bUrl}get-conversion`, { f: this.filters })
        .then(r => {
          let months = r.data.data.y;
          let ltv = r.data.data.ltv;
          let ltv2 = r.data.data.ltv2;
          let sltv = r.data.data.sltv;
          let sltv2 = r.data.data.sltv2;
          let mt = [];
          switch (this.filters.fType) {
            case 'month':
              for (let m in months) {
                mt.push(this.$t('monthNames')[m].substring(0, 3));
              }
              break;
            case 'day':
              mt = months;
              break;
            case 'weak':
              mt = months;
              break;
            case 'quart':
              mt = months;
              break;
          }

          this.optionsBar.xAxis.data = mt;
          const met = this.$t('met');

          this.optionsBar.yAxis.axisLabel = {
            formatter: function(val) {
              if (val.toString().length <= 6) {
                return val / 1000 + met.th;
              } else {
                return val / 1000000 + met.mln;
              }
            }
          };
          this.optionsBar.legend.data.push({
            name: 'Заказано',
            padding: [0, 15]
          });
          this.optionsBar.series.push({
            name: 'Заказано',
            areaStyle: {
              color: '#0889B2',
              origin: 'auto',
              shadowBlur: 0,
              opacity: 0.05
            },
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            },
            data: ltv
          });
          this.optionsBar.legend.data.push({
            name: 'Выкуплено',
            padding: [0, 15]
          });
          this.optionsBar.series.push({
            name: 'Выкуплено',
            areaStyle: {
              color: '#0889B2',
              origin: 'auto',
              shadowBlur: 0,
              opacity: 0.05
            },
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            },
            data: ltv2
          });

          this.optionsPricing.xAxis.data = mt;

          this.optionsPricing.yAxis.axisLabel = {
            formatter: function(val) {
              if (val.toString().length <= 6) {
                return val / 1000 + met.th;
              } else {
                return val / 1000000 + met.mln;
              }
            }
          };
          this.optionsPricing.legend.data.push({
            name: 'Заказано',
            padding: [0, 15]
          });
          this.optionsPricing.series.push({
            name: 'Заказано',
            areaStyle: {
              color: '#0889B2',
              origin: 'auto',
              shadowBlur: 0,
              opacity: 0.05
            },
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            },
            data: sltv
          });
          this.optionsPricing.legend.data.push({
            name: 'Выкуплено',
            padding: [0, 15]
          });
          this.optionsPricing.series.push({
            name: 'Выкуплено',
            areaStyle: {
              color: '#0889B2',
              origin: 'auto',
              shadowBlur: 0,
              opacity: 0.05
            },
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            },
            data: sltv2
          });
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    getRFM() {
      this.index++;
      this.$h
        .get(`/stat/increasing/get-rfm`)
        .then(r => {
          this.s_dataLeft[0].modal.rows = r.data.data.seg;
          this.s_dataLeft[0].summ = r.data.data.summ;
          this.s_dataLeft[0].link = 'products_RFM_conv';
        })
        .then(() => {
          this.loading = false;
          this.index--;
        });
    },
    async init() {
      await this.getData();
      await this.getRFM();
    }
  }
};
</script>

<style scoped></style>
