import Vue from 'vue';
import Router from 'vue-router';
import MainLayout from './layouts/mainlayout.vue';

import Login from '@/pages/login.vue';
import Account from '@/pages/account';
import Review from '@/pages/review';
import Revenue_plan from '@/pages/revenue_plan';
import Average_check from '@/pages/average_check';

import Growth from '@/pages/growth';
import Customers from '@/components/growth/customers';
import Pricing from '@/components/average_check/pricing';
import Products_service from '@/components/average_check/products_service';
import Structure_checks from '@/components/average_check/structure_checks';
import Error_p from '@/pages/error_p';
import Revenue_analysis from '@/pages/revenue_analysis';
import Increasing from '@/pages/increasing';
import Frequency from '@/components/increasing/frequency';
import ConversionInc from '@/components/increasing/conversion';
import Favorites from '@/pages/favorites';
import Managers from '@/components/increasing/default/managers';
import Settings from '@/pages/settings.vue';
import User_setting from '@/components/users/user_setting.vue';
import Users from '@/pages/users.vue';
import UsersList from '@/components/users/userlist.vue';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: MainLayout,
      children: [
        {
          path: '/',
          name: 'main',
          component: Review
        },
        {
          path: '/growth',
          name: 'growth',
          component: Growth,
          children: [
            {
              path: 'customers',
              name: 'g_customers',
              component: Customers
            }
          ]
        },
        {
          path: '/account',
          name: 'account',
          component: Account
        },
        {
          path: '/favorites',
          name: 'favorites',
          component: Favorites
        },
        {
          path: '/users',
          name: 'users_list',
          component: Users,
          children: [
            {
              path: '/',
              name: 'users_list',
              component: UsersList
            },
            {
              path: 'setting',
              name: 'user_settings',
              component: User_setting
            }
          ]
        },
        {
          path: '/revenue_plan',
          name: 'revenue_plan',
          component: Revenue_plan
        },
        {
          path: '/revenue_analysis',
          name: 'revenue_analysis',
          component: Revenue_analysis
        },
        {
          path: '/increasing',
          component: Increasing,
          children: [
            {
              path: '/',
              name: 'increasing',
              component: ConversionInc
            },
            {
              path: 'frequency',
              name: 'frequency',
              component: Frequency
            },
            {
              path: 'managers',
              name: 'managers',
              component: Managers
            }
          ]
        },
        {
          path: '/average_check',
          component: Average_check,
          children: [
            {
              path: '/',
              name: 'average_check',
              component: Pricing
            },
            {
              path: 'products_service',
              name: 'products_service',
              component: Products_service
            },
            {
              path: 'structure_checks',
              name: 'structure_checks',
              component: Structure_checks
            }
          ]
        },
        {
          path: '/settings',
          component: Settings,
          name: 'settings'
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '*',
      name: 'error',
      component: Error_p
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      });
    } else {
      let user = JSON.parse(localStorage.getItem('user'));
      if (to.matched.some(rec => rec.meta.is_admin)) {
        if (user.role == 'admin') {
          next();
        } else {
          next({ name: 'showcase' });
        }
      } else {
        next();
      }
      if (to.matched.some(recs => recs.meta.level)) {
        if (user.level >= to.meta.level) {
          next();
        } else {
          next({ name: 'showcase' });
        }
      }
    }
  } else {
    next();
  }
});
export default router;
