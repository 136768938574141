<template>
  <q-page class="ub-page">
    <div class="bvm_border rev_col_header">
      <div class="row">
        <div class="col-6">
          <label class="user_p_date">Компания</label>
          <div class="col-12">
            <div class="row">
              <label class="user_form">Название</label>
              <div class="col-10">
                <input class="user_form_inp" style="width: 100%" type="text" disabled :value="company.title" />
              </div>
            </div>
            <div class="row">
              <label class="user_form">ИИН</label>
              <div class="col-10">
                <input class="user_form_inp" style="width: 100%" type="text" :value="company.inn" />
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <input class="user_form_inp" @click="saveCompany(id)" style="width: 100%;cursor: pointer" type="button" value="save" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <DSettings v-if="company.type == 'def'" />
          <GSettings :c-id="company.company_id" v-else-if="company.type == 'goods' || company.type == 'zarina'" />
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import DSettings from '@/components/users/default/setting.vue';
import GSettings from '@/components/users/goods/setting.vue';

export default {
  name: 'UserSetting',
  components: { DSettings, GSettings },
  data() {
    return {
      company: { title: '', inn: null },
      usId: 0
    };
  },
  computed: {},
  created() {
    if (this.$route.query.id) {
      this.usId = this.$route.query.id;
      this.initialize();
    } else {
      this.$router.push('/users');
    }
  },
  watch: {},
  mounted() {},
  methods: {
    initialize() {
      this.$h.get(`/users/get-info?id=${this.usId}`).then(r => {
        if (r.data.data.comp != null) {
          this.company = r.data.data.comp;
        }
      });
    },
    saveCompany() {
      this.$h.post(`/users/set-company?id=${this.usId}`, { inn: this.company.inn }).then(r => {
        //this.company = r.data.data.comp;
      });
    }
  }
};
</script>
<style>
.q-notification__message {
  font-size: 16px !important;
  font-weight: bold;
  text-align: center;
}
</style>
