<template>
  <div class="bvm_border bvm_user_info">
    <q-tabs v-model="tab" align="left">
      <q-tab name="service" :label="$t('keyTurnover_days')"> </q-tab>
    </q-tabs>
    <Products_service v-if="tab == 'service'" />
  </div>
</template>

<script>
import Products_service from '@/components/settings/default/products_service.vue';

export default {
  name: 'def_settings',
  components: { Products_service },
  data() {
    return {
      tab: 'service'
    };
  },
  created() {
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
  }
};
</script>

<style scoped></style>
