<template>
  <q-page class="ub-page">
    <Z_revenue_analysis v-if="this.$store.state.user.type === 'zarina'" />
    <G_revenue_analysis v-else-if="this.$store.state.user.type === 'goods'" />
    <S_revenue_analysis v-else-if="this.$store.state.user.type === 'services'" />
    <D_revenue_analysis v-else />
  </q-page>
</template>

<script>
import Z_revenue_analysis from '@/components/revenue_analysis/zarina/z_revenue_analysis';
import G_revenue_analysis from '@/components/revenue_analysis/goods/g_revenue_analysis';
import D_revenue_analysis from '@/components/revenue_analysis/default/revenue_analysis';
import S_revenue_analysis from '@/components/revenue_analysis/services/revenue_analysis';
export default {
  name: 'revenue_analysis',
  components: { Z_revenue_analysis, D_revenue_analysis, G_revenue_analysis, S_revenue_analysis },
  data: () => {
    return {};
  }
};
</script>

<style scoped></style>
