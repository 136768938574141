import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { i18n } from './i18n';
import store from './store/store';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

// Sample: to import all

import { DashBlocks } from 'dashblocks';
import * as dashblocksComponents from 'dashblocks';
Vue.use(DashBlocks, {
  components: dashblocksComponents
});

// Dashblocks CSS
//import 'dashblocks/dist/dashblocks.css';

import './quasar';
import './styles/all.css';

import api from './axios';

Vue.prototype.$h = api;

Vue.prototype.$colors = [
  '#42998D',
  '#b4d649',
  '#3869c1',
  '#38b7c1',
  '#d6c349',
  '#e0921b',
  '#8562be',
  '#d168e0',
  '#80930D',
  '#1cc789',
  '#85bbe3',
  '#1f9f98',
  '#a6c236',
  '#fabb43',
  '#6e6579',
  '#a447bb'
];
Vue.prototype.$ltvcolors = ['#e82347', '#e0921b', '#d6c349', '#a6c236', '#80930D'];
//Vue.config.productionTip = false;

Vue.use(VueGoodTablePlugin);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
