<template>
  <q-page class="ub-page">
    <zRev v-if="$store.state.user.type === 'zarina'" />
    <rRev v-else />
  </q-page>
</template>

<script>
import zRev from '@/components/review/zarina/rev.vue';
import rRev from '@/components/review/def/rev.vue';

export default {
  name: 'review',
  components: { zRev, rRev }
};
</script>

<style scoped></style>
