<template>
  <q-page class="ub-page">
    <div class="row" v-if="myInfo && myInfo.tariff">
      <div class="col-12 col-md-2">
        <div class="bvm_border dv_add_user">
          <div class="bvm_new_avatar">
            <img src="/user.svg" alt="new" />
          </div>
          <div class="bvm_button" @click="addUser()">{{ $t('addUser') }}</div>
        </div>
      </div>
      <div class="col-md-9 col-12" v-if="myInfo">
        <div :class="'bvm_border dv_edit_user' + (selectUser == -1 ? '_active' : '')" @click="setInfo(myInfo, -1)">
          <div class="my_avatar">
            <img :src="myInfo.avatar" alt="new" />
          </div>
          <div class="bvm_button">
            {{ myInfo.username }} {{ myInfo.surname }} <br />
            {{ myInfo.description }}
          </div>
        </div>
        <div :class="'bvm_border dv_edit_user' + (selectUser == i ? '_active' : '')" @click="setInfo(user, i)" v-for="(user, i) in myInfo.users" :key="i">
          <div class="bvm_avatar">
            <img :src="user.avatar != '' && user.avatar != null ? user.avatar : '/user.svg'" alt="new" />
          </div>
          <div class="bvm_button">
            {{ user.username }} {{ user.surname }}<br />
            {{ user.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="uInfo">
      <div :class="'col-12 col-md-' + (uInfo.tariff ? 9 : 12)">
        <form class="bvm_border bvm_user_info" @submit.prevent="upInfo($event)">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="user_det">
                <div class="user_ava" @click="addPhoto()">
                  <img class="m_ava" :src.sync="uInfo.avatar" alt="" />
                  <input type="file" hidden ref="userAva" @change="onFileChanged" accept="image/png, image/jpg, image/jpeg" />
                  <img class="add_photo" v-bind:src="'/images/add_photo.png'" alt="" />
                </div>
                <div>
                  <div class="user_name">{{ uInfo.username }} {{ uInfo.surname }}</div>
                  <div class="user_prof">
                    {{ uInfo.description }}
                  </div>
                  <div class="t_settings_up">
                    <q-btn type="submit">{{ uInfo.id ? $t('update') : $t('save') }}</q-btn>
                  </div>
                  <div class="user_del" @click="delAcc(uInfo)">
                    {{ $t('deleteAccount') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div class="user_info" ref="userForm">
                <label class="user_p_date">{{ $t('personalData') }}</label>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <label class="user_form">{{ $t('user_f').username }}</label>
                    <input required type="text" class="user_form_inp" name="username" v-model="uInfo.username" />
                    <label class="user_form">{{ $t('user_f').email }}</label>
                    <input required type="email" class="user_form_inp" name="email" v-model="uInfo.email" />
                    <label class="user_form">{{ $t('user_f').phone }}</label>
                    <input required type="phone" class="user_form_inp" v-model="uInfo.phone" />
                    <label class="user_form">{{ $t('user_f').password }}</label>
                    <input type="password" class="user_form_inp" name="password" v-model="uInfo.password" />
                  </div>
                  <div class="col-md-6 col-12">
                    <label class="user_form">{{ $t('user_f').surname }}</label>
                    <input required type="text" class="user_form_inp" name="surname" v-model="uInfo.surname" />
                    <label class="user_form">{{ $t('user_f').sub_email }}</label>
                    <input required type="email" class="user_form_inp" name="sub_email" v-model="uInfo.sub_email" />
                    <label class="user_form">{{ $t('user_f').job_title }}</label>
                    <input required type="text" class="user_form_inp" name="job_title" v-model="uInfo.description" />
                    <label class="user_form">{{ $t('user_f').cpassword }}</label>
                    <input
                      name="cpassword"
                      :required="!uInfo.id"
                      type="password"
                      :style="uInfo.password != uInfo.cpassword ? 'border-color: red' : ''"
                      class="user_form_inp"
                      v-model="uInfo.cpassword"
                    />
                  </div>
                </div>
                <label class="user_p_date">{{ $t('informationAboutTheCompany') }}</label>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <label class="user_form">{{ $t('user_f').company_name }}</label>
                    <input type="text" class="user_form_inp" disabled v-model="myInfo.company.title" />
                  </div>
                  <div class="col-md-6 col-12">
                    <label class="user_form">{{ $t('user_f').inn }}</label>
                    <input type="number" class="user_form_inp" disabled v-model="myInfo.company.inn" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-6">
                    <label class="user_form">{{ $t('user_f').okved }}</label>
                    <div class="bvm_oved">
                      <div>{{ myInfo.company.ocved }}</div>
                    </div>
                  </div>
                  <div class="col-md-6 col-6">
                    <label class="user_form">{{ $t('user_f').competitors }}</label>
                  </div>
                </div>
                <q-btn ref="submit_f" type="submit" style="display: none"></q-btn>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12 col-md-3" v-if="myInfo && myInfo.tariff">
        <div class="bvm_border bvm_tarif_info">
          <div class="tariff">
            <div class="you_plane">{{ $t('yourTariff') }}</div>
            <div class="t_plane">{{ $t('corporation') }}</div>
            <div class="t_date">{{ $t('activeTo', { dt: '23.06.2023' }) }}</div>
          </div>
          <div class="tariff_bot"></div>
          <div class="t_settings">
            <q-btn>{{ $t('extend') }}</q-btn>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { Notify } from 'quasar';

export default {
  name: 'account',
  data() {
    return {
      selectUser: -1,
      newUser: {},
      uInfo: null,
      myInfo: null,
      userAvatar: null,
      srcAva: ''
    };
  },
  mounted() {
    this.getInfo();
  },
  watch: {
    srcAva(val) {}
  },
  methods: {
    getInfo() {
      this.$h.get('/users/my-info').then(resp => {
        this.myInfo = resp.data.data;
        this.uInfo = this.myInfo;
        for (let col in this.uInfo) {
          if (col != 'users') {
            this.newUser[col] = '';
          }
        }
      });
    },
    setInfo(info, number) {
      this.selectUser = number;
      this.uInfo = info;
    },
    delete() {
      if (this.selectUser >= 0) {
        this.$h.post('/users/del-info', { user: this.uInfo.id }).then(() => {
          Notify.create({
            message: this.$t('deleted'),
            top
          });
        });
        this.myInfo.users.splice(this.selectUser, 1);
        this.uInfo = this.myInfo;
        this.selectUser = -1;
      }
    },
    delAcc() {
      let position = 'center';
      Notify.create({
        message: this.$t('sure'),
        color: 'primary',
        position,
        actions: [
          {
            label: this.$t('cancel'),
            color: 'yellow'
          },
          {
            label: this.$t('delete'),
            color: 'white',
            handler: this.delete()
          }
        ],
        timeout: 0
      });
    },
    addUser() {
      this.myInfo.users.push(this.newUser);
      this.selectUser = this.myInfo.users.length - 1;
      this.uInfo = this.newUser;
    },
    addPhoto() {
      this.$refs.userAva.click();
    },
    async onFileChanged(event) {
      var file = event.target.files[0];
      this.uInfo.avatar = await this.readFileAsDataURL(file);
    },
    async readFileAsDataURL(file) {
      return await new Promise(resolve => {
        let fileReader = new FileReader();
        fileReader.onload = e => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
      });
    },

    upInfo() {
      if (this.uInfo.password == this.uInfo.cpassword) {
        this.$h.post('/users/up-info', { user: this.uInfo }).then(resp => {
          this.uInfo.id = resp.data.data;
          Notify.create({
            message: this.$t('added'),
            top
          });
        });
      }
    }
  }
};
</script>

<style scoped></style>
