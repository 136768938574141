<template>
  <div style="padding-right: 15px"></div>
</template>

<script>
export default {
  name: 'UserSetting',
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {}
  }
};
</script>
<style>
.q-notification__message {
  font-size: 16px !important;
  font-weight: bold;
  text-align: center;
}
</style>
