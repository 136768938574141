<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-12">
        <div class="bvm_border rev_graf">
          <label class="reva_graf_title">
            <q-img @click="addFav('keyConversion_dynamics', optionsCon, 'sankey')" src="/images/icons/favorite_blue.svg" alt="fav" class="favorite_h" />
          </label>
          <div class="row">
            <div class="col-12">
              <SankeyChart v-if="ready" :ch-options="optionsCon" style="height: 500px" />
              <SankeyChart v-if="ready" :ch-options="optionsConC" style="height: 500px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SankeyChart from '@/chart/SankeyChart';

export default {
  name: 'managers',
  components: { SankeyChart },
  props: {
    fType: {
      type: String
    },
    filters: {
      type: Object
    }
  },
  data: () => {
    return {
      bUrl: '/stat/',
      catShow: true,
      index: 0,
      ready: false,
      optionsCon: {
        series: {
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
          },
          type: 'sankey',
          data: [],
          links: [],
          emphasis: {
            focus: 'adjacency'
          },
          levels: [
            {
              depth: 0,
              itemStyle: {
                color: '#fbb4ae'
              },
              label: {
                height: 25
              },
              lineStyle: {
                color: 'source',
                opacity: 0.6
              }
            },
            {
              depth: 1,
              itemStyle: {
                color: '#b3cde3'
              },
              lineStyle: {
                color: 'source',
                opacity: 0.6
              }
            },
            {
              depth: 2,
              itemStyle: {
                color: '#ccebc5'
              },
              lineStyle: {
                color: 'source',
                opacity: 0.6
              }
            },
            {
              depth: 3,
              itemStyle: {
                color: '#b016e0'
              },
              lineStyle: {
                color: 'source',
                opacity: 0.6
              }
            },
            {
              depth: 4,
              itemStyle: {
                color: '#af1a3d'
              },
              lineStyle: {
                color: 'source',
                opacity: 0.6
              }
            },
            {
              depth: 5,
              itemStyle: {
                color: '#4efa0b'
              },
              lineStyle: {
                color: 'source',
                opacity: 0.6
              }
            }
          ]
        }
      },
      optionsConC: {
        series: {
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
          },
          type: 'sankey',
          data: [],
          links: [],
          emphasis: {
            focus: 'adjacency'
          },
          levels: [
            {
              depth: 0,
              itemStyle: {
                color: '#fbb4ae'
              },
              label: {
                height: 25
              },
              lineStyle: {
                color: 'source',
                opacity: 0.6
              }
            },
            {
              depth: 1,
              itemStyle: {
                color: '#b3cde3'
              },
              lineStyle: {
                color: 'source',
                opacity: 0.6
              }
            },
            {
              depth: 2,
              itemStyle: {
                color: '#ccebc5'
              },
              lineStyle: {
                color: 'source',
                opacity: 0.6
              }
            },
            {
              depth: 3,
              itemStyle: {
                color: '#b016e0'
              },
              lineStyle: {
                color: 'source',
                opacity: 0.6
              }
            },
            {
              depth: 4,
              itemStyle: {
                color: '#af1a3d'
              },
              lineStyle: {
                color: 'source',
                opacity: 0.6
              }
            },
            {
              depth: 5,
              itemStyle: {
                color: '#4efa0b'
              },
              lineStyle: {
                color: 'source',
                opacity: 0.6
              }
            }
          ]
        }
      }
    };
  },
  watch: {
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
        this.ready = true;
      } else {
        this.$store.state.progress = true;
      }
    },
    filters() {
      if (this.index == 0) {
        this.init();
      }
    }
  },
  created() {},
  mounted() {
    if (this.filters.fType) {
      this.init();
    }
  },
  methods: {
    addFav() {},
    getMDate() {
      this.ready = false;
      this.index++;
      this.$h
        .post(`/stat/increasing/get-mdata`, { f: this.filters })
        .then(r => {
          this.optionsCon.series.data = r.data.data.items;
          this.optionsCon.series.links = r.data.data.links;
        })
        .then(() => {
          this.index--;
        });
    },
    getCDate() {
      this.ready = false;
      this.index++;
      this.$h
        .post(`/stat/increasing/get-cdata`, { f: this.filters })
        .then(r => {
          this.optionsConC.series.data = r.data.data.items;
          this.optionsConC.series.links = r.data.data.links;
        })
        .then(() => {
          this.index--;
        });
    },
    init() {
      this.getCDate();
      this.getMDate();
    }
  }
};
</script>

<style scoped></style>
