<template>
  <q-page class="ub-page">
    <div class="row">
      <div class="col-12 col-md-6" v-for="g in grafiks" :key="g.key">
        <div class="bvm_border rev_graf">
          <label class="graf_title"
            >{{ $t(g.key) }}
            <img @click="delFav(g)" src="/images/icons/favorite.svg" alt="fav" class="favorite_h" />
          </label>
          <div v-if="g.g == 'line'">
            <LineChart v-if="ready" :ch-options="g.options" style="height: 350px" />
          </div>
          <div v-else-if="g.g == 'bar'">
            <BarChart v-if="ready" :ch-options="g.options" style="height: 350px" />
          </div>
          <div v-else-if="g.g == 'pie'">
            <PieChart v-if="ready" :ch-options="g.options" style="height: 350px" />
          </div>
          <div v-else-if="g.g == 'ave_tab'">
            <q-table
              class="prod-serv-dynamic"
              :data="g.options.r"
              :columns="g.options.c"
              :loading="!ready"
              row-key="id"
              style="height: 350px"
              virtual-scroll
              :rows-per-page-options="[0]"
            >
              <template v-slot:header="props">
                <q-tr>
                  <q-th align="left" style="z-index: 5;width: max-content;" v-for="m in props.cols" :hidden="m.field == 'id'" :key="m.field">
                    {{ labels.tables[m.label] }}
                  </q-th>
                </q-tr>
              </template>
              <template v-slot:body="props">
                <q-tr :id="props.row.title" :data-set="props.row.id">
                  <q-td v-for="col in props.cols" :key="Math.random() + col.field" :hidden="col.field == 'id'">
                    <span v-if="col.field.indexOf('proc') >= 0">
                      <b>{{ col.value }}%</b>
                    </span>
                    <span v-else-if="col.field == 'category'">
                      <span
                        class="cat_info"
                        @click="
                          () => {
                            props.expand = !props.expand;
                            getProd(props.row, !props.expand);
                          }
                        "
                      >
                        <span style="color: #FF975D" class="material-icons">{{ !props.expand ? 'add' : 'remove' }}</span>
                        &nbsp;<b class="c_title">{{ col.value }}</b></span
                      >
                    </span>
                    <div v-else class="type_cat">{{ labels.val[col.value] }}</div>
                  </q-td>
                </q-tr>
                <q-tr
                  style="background-color: rgba(220,220,220,0.38)"
                  :class="'prod' + props.row.id + (t % 2 == 0 ? ' bvm_b_table_grey' : ' bvm_b_table_white')"
                  v-show="props.expand && props.row.sub.length > 0"
                  v-for="(sub, t) in props.row.sub"
                  :key="Math.random() + sub.id"
                >
                  <q-td
                    v-for="(s, i) in sub"
                    :key="Math.random() + i"
                    :hidden="i == 'id'"
                    style="text-align: right; white-space: pre-wrap;max-width: 75px; font-size: 12px;height: max-content !important;"
                  >
                    <span v-if="i == 'category'" class="cat_info" style="min-width: 150px"> {{ s }} </span>
                    <span v-else class="type_cat" style="background-color:#FFECD0;color:#3D1601;width: 75px">{{ s }}</span>
                  </q-td>
                </q-tr>
              </template>
              <template v-slot:bottom class="tt"> </template>
            </q-table>
          </div>
          <div v-else-if="g.g == 'avec_tab'">
            <q-table
              class="ave-serv-dynamic"
              :data="g.options.r"
              :columns="g.options.c"
              :loading="!ready"
              row-key="id"
              style="height: 350px"
              virtual-scroll
              :rows-per-page-options="[0]"
            >
              <template v-slot:header="props">
                <q-tr>
                  <q-th align="left" style="z-index: 5;width: max-content;" v-for="m in props.cols" :hidden="m.field == 'id'" :key="m.field">
                    {{ m.label == 'title' ? $t('category') : m.type != 'year' && m.type != 'weak' ? $t('f')[m.type][m.label] : m.label }}
                  </q-th>
                </q-tr>
              </template>
              <template v-slot:body="props">
                <q-tr :id="props.row.title" :data-set="props.row.id">
                  <q-td v-for="col in props.cols" :key="Math.random() + col.field" :hidden="col.field == 'id'">
                    <div v-if="col.field == 'title'" class="ave_ch_t">
                      <b>{{ col.value }}</b>
                    </div>
                    <div v-else class="ave_ch_val" :style="'background: rgb(217, 111, 51, ' + col.value.p + '%)'">
                      {{ col.value.v }}
                    </div>
                  </q-td>
                </q-tr>
              </template>
              <template v-slot:bottom class="tt"></template>
            </q-table>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import LineChart from '@/chart/LineChart';
import BarChart from '@/chart/BarChart';
import PieChart from '@/chart/PieChart';
export default {
  name: 'favorites',
  components: { PieChart, LineChart, BarChart },
  data: () => {
    return {
      ready: false,
      grafiks: [],
      labels: []
    };
  },
  created() {
    this.labels = this.$t('receiver');
  },
  methods: {
    getGrafics() {
      this.$h
        .get('/favorite')
        .then(r => {
          this.grafiks = r.data.data;
        })
        .then(() => {
          this.ready = true;
        });
    },
    delFav(item) {
      this.$h.get('/favorite/del?id=' + item.id).then(() => {
        let ind = this.grafiks.indexOf(item);
        this.grafiks.splice(ind, 1);
      });
    },
    getProd(ct, p) {
      if (!p) {
        this.index++;
        this.loading = true;
        this.$h.get(`/stat/avercheck/get-table-prod?catId=${ct.id}`).then(r => {
          ct.sub = r.data.data;
          this.loading = false;
          this.index--;
        });
      } else {
        ct.sub = [];
      }
    }
  },
  mounted() {
    this.getGrafics();
  }
};
</script>

<style scoped></style>
