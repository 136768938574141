<template>
  <v-chart style="text-align: right" class="chartL" :option="option" />
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart, BarChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components';
import VChart from 'vue-echarts';

use([CanvasRenderer, PieChart, BarChart, TitleComponent, TooltipComponent, LegendComponent]);

export default {
  name: 'PieChart',
  components: {
    VChart
  },
  props: {
    chOptions: {
      type: Object
    }
  },
  data() {
    return {
      option: {}
    };
  },
  mounted() {
    this.option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      legend: this.chOptions.legend,
      series: this.chOptions.series
    };
  },
  methods: {
    handleClick(...args) {
      console.log(...args, 123);
    }
  }
};
</script>

<style scoped>
.chartL {
  height: 275px;
}
</style>

<style>
body {
  margin: 0;
}
</style>
