<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created: function() {
    this.$h.interceptors.response.use(undefined, function(err) {
      return new Promise(function(resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout');
        }
        throw err;
      });
    });
    document.addEventListener('DOMContentLoaded', function() {
      //amplitude.getInstance().init("f0145a9bb13aebab191f8e25a539aca0");
    });
  }
};
</script>
<style></style>
