<template>
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="bvm_border rev_graf" style="min-height: 500px">
        <div class="row">
          <div @click="catShow = true" :class="'col-5 ave_graf_title ' + (catShow ? 'active' : '')">
            {{ $t('keyNumber_receipts_category') }}
          </div>
          <div @click="catShow = false" :class="'col-5 ave_graf_title ' + (catShow ? '' : 'active')">
            {{ $t('keyBreakdown_categories_receipts') }}
          </div>
          <div class="col-2">
            <div style="display: block;width: 100%;padding: 25px;">
              <q-img
                @click="
                  catShow
                    ? addFav('keyNumber_receipts_category', { r: debRows, c: debColumns }, 'avec_tab')
                    : addFav('keyBreakdown_categories_receipts', { r: debRowsP, c: debColumns }, 'avec_tab')
                "
                src="/images/icons/chart/b_shape_org.svg"
                alt="fav"
                class="favorite_h"
              />
            </div>
          </div>
        </div>
        <div v-if="catShow == true">
          <q-table class="ave-serv-dynamic" :data="debRows" :columns="debColumns" :loading="loading" :rows-per-page-options="[0]">
            <template v-slot:header="props">
              <q-tr>
                <q-th align="left" style="z-index: 5;width: max-content;" v-for="m in props.cols" :hidden="m.field == 'id'" :key="m.field">
                  {{ m.label == 'title' ? $t('category') : m.type != 'year' && m.type != 'weak' ? $t('f')[m.type][m.label] : m.label }}
                </q-th>
              </q-tr>
            </template>
            <template v-slot:body="props">
              <q-tr :id="props.row.title" :data-set="props.row.id">
                <q-td v-for="col in props.cols" class="ave_ch_td" :key="Math.random() + col.field" :hidden="col.field == 'id'">
                  <div v-if="col.field == 'title'" class="bbbk">
                    <div class="ave_ch_t">
                      <b>{{ col.value }}</b>
                    </div>
                  </div>
                  <div v-else class="bbbk">
                    <div class="ave_ch_val" :style="'background: rgb(217, 111, 51, ' + col.value.p + '%)'">
                      {{ col.value.v }}
                    </div>
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:bottom class="tt"></template>
          </q-table>
        </div>
        <div v-else-if="!catShow">
          <q-table class="ave-serv-dynamic" :data="debRowsP" :columns="debColumns" :loading="loading" row-key="id" :rows-per-page-options="[0]">
            <template v-slot:header="props">
              <q-tr>
                <q-th align="left" style="z-index: 5;width: max-content;" v-for="m in props.cols" :hidden="m.field == 'id'" :key="m.field">
                  {{
                    m.label == 'title'
                      ? $t('category')
                      : m.type != 'year' && m.type != 'weak'
                      ? m.type == 'month'
                        ? $t('f')[m.type][m.label]
                        : $t('f')[m.type][m.label]
                      : m.label
                  }}
                </q-th>
              </q-tr>
            </template>
            <template v-slot:body="props">
              <q-tr :id="props.row.title" :data-set="props.row.id">
                <q-td v-for="col in props.cols" :key="Math.random() + col.field" :hidden="col.field == 'id'">
                  <div v-if="col.field == 'title'" class="ave_ch_t">
                    <b>{{ col.value }}</b>
                  </div>
                  <div v-else class="ave_ch_val" :style="'background: rgb(217, 111, 51, ' + col.value.p + '%)'">
                    {{ col.value.v }}
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:bottom class="tt"></template>
          </q-table>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-12">
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="bvm_border metrix_graf">
            <q-table
              class="ave-serv-dynamic-m"
              :data="debRowsMetrix"
              :columns="debColumnsMetrix"
              :loading="loading"
              row-key="id"
              style="height: 140px"
              virtual-scroll
              :rows-per-page-options="[0]"
            >
              <template v-slot:header="props">
                <q-tr>
                  <q-th align="left" style="z-index: 5;width: max-content;" v-for="m in props.cols" :hidden="m.field == 'id'" :key="m.field">
                    {{
                      m.label == 'title'
                        ? $t('category')
                        : m.type != 'year' && m.type != 'weak'
                        ? m.type == 'month'
                          ? $t('f')[m.type][m.label - 1]
                          : $t('f')[m.type][m.label]
                        : m.label
                    }}
                  </q-th>
                </q-tr>
              </template>
              <template v-slot:body="props">
                <q-tr :id="props.row.title" :data-set="props.row.id">
                  <q-td v-for="col in props.cols" :key="Math.random() + col.field" :hidden="col.field == 'id'">
                    <div v-if="col.field == 'title'" class="ave_ch_val_m">
                      <b>{{ col.value }}</b>
                    </div>
                    <div v-else class="ave_ch_val" style="background: #FFECD0;">
                      <span>{{ col.value }}</span>
                    </div>
                  </q-td>
                </q-tr>
              </template>
              <template v-slot:bottom class="tt"></template>
            </q-table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-12">
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="bvm_border metrix_graf">
            <q-table
              class="ave-serv-dynamic-m"
              :data="debRowsMetrixP"
              :columns="debColumnsMetrixP"
              :loading="loading"
              row-key="id"
              style="height: 140px"
              virtual-scroll
              :rows-per-page-options="[0]"
            >
              <template v-slot:header="props">
                <q-tr>
                  <q-th align="left" style="z-index: 5;width: max-content;" v-for="m in props.cols" :hidden="m.field == 'id'" :key="m.field">
                    {{
                      m.label == 'title'
                        ? $t('category')
                        : m.type != 'year' && m.type != 'weak'
                        ? m.type == 'month'
                          ? $t('f')[m.type][m.label - 1]
                          : $t('f')[m.type][m.label]
                        : m.label
                    }}
                  </q-th>
                </q-tr>
              </template>
              <template v-slot:body="props">
                <q-tr :id="props.row.title" :data-set="props.row.id">
                  <q-td v-for="col in props.cols" :key="Math.random() + col.field" :hidden="col.field == 'id'">
                    <div v-if="col.field == 'title'" class="ave_ch_m">
                      <b>{{ col.value }}</b>
                    </div>
                    <div v-else class="ave_ch_val_m" :style="'background-color: ' + (col.value >= 0 ? '#9FE3C7' : '#FFDFDF') + ';'">
                      <span>{{ col.value > 0 ? '+' + col.value : col.value }}%</span>
                    </div>
                  </q-td>
                </q-tr>
              </template>
              <template v-slot:bottom class="tt"></template>
            </q-table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <label class="rew_title">{{ $t('keyRevenue_increase') }}</label>
    </div>
    <div class="col-12 col-md-6">
      <div>
        <Check_slider s-color="org" :s_data="s_dataLeft"></Check_slider>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div>
        <Check_slider s-color="org" :s_data="s_dataRight"></Check_slider>
      </div>
    </div>
  </div>
</template>

<script>
import Check_slider from '@/components/items/check_slider';

export default {
  name: 'products_service',
  components: { Check_slider },
  props: {
    fType: {
      type: String
    },
    filters: {
      type: Object
    }
  },
  data: () => {
    return {
      pagination: {
        rowsPerPage: 0
      },
      index: 0,
      catShow: true,
      slide1: 1,
      slide2: 1,
      ready: true,
      debRows: [],
      debRowsP: [],
      debColumns: [],
      loading: true,
      labels: [],
      months: [],
      debRowsMetrix: [],
      debColumnsMetrix: [],
      debRowsMetrixP: [],
      debColumnsMetrixP: [],
      s_dataRight: [],
      s_dataLeft: [
        {
          title: 'Рекомендация по клиентам',
          kpif: 'Количество клиентов в сегменте',
          kpit: 'Потенциальное количество клиентов в сегменте',
          object: 'Сегмент клиентов',
          summ: 0,
          modal: {
            show: false,
            rows: [],
            columns: [
              {
                field: 'category',
                label: 'Рекомендация по клиентам'
              },
              {
                field: 'kpif',
                label: 'KPI факт'
              },
              {
                field: 'kpit',
                label: 'KPI цель'
              },
              {
                field: 'kpis',
                label: 'Потенциальная выручка'
              }
            ],
            loading: false
          }
        }
      ]
    };
  },
  watch: {
    fType(val) {
      this.getTableData();
      this.getMetrix();
    },
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    },
    filters() {
      this.init().then(() => {});
    }
  },
  created() {
    this.months = this.$t('monthNames');
    this.labels = this.$t('receiver');
  },
  mounted() {
    if (this.filters.fType) {
      this.init().then(() => {});
    }
  },
  methods: {
    setTitleCol(val) {
      const v = val.split('-');
      console.log(v);
      return (
        v[0].toLocaleString('ru-Ru', {
          style: 'currency',
          currency: 'RUB',
          maximumFractionDigits: 0
        }) +
        '-' +
        v[1].toLocaleString('ru-Ru', {
          style: 'currency',
          currency: 'RUB',
          maximumFractionDigits: 0
        })
      );
    },
    selectType(t) {
      this.fType = t;
    },
    addFav(type, data, g) {
      this.$h.post('/favorite/add', { t: type, d: data, g: g }).then(r => {});
    },
    exportAll() {},
    getMetrix() {
      this.index++;
      this.$h.post(`/stat/avercheck/get-metrix`, { f: this.filters }).then(r => {
        this.debColumnsMetrix = r.data.data.f;
        this.debRowsMetrix = r.data.data.c;
        this.debRowsMetrixP = r.data.data.ch;
        this.debColumnsMetrixP = r.data.data.f;
        this.index--;
      });
    },
    getTableData() {
      this.loading = true;
      this.index++;
      this.$h
        .post(`/stat/avercheck/get-aver-check`, { f: this.filters })
        .then(r => {
          this.debColumns = r.data.data.f;
          this.debRows = r.data.data.c;
          this.debRowsP = r.data.data.cp;
        })
        .then(() => {
          this.loading = false;
          this.index--;
        });
    },
    getRFM() {
      this.index++;
      this.$h.get(`/stat/avercheck/get-rfm`).then(r => {
        this.s_dataLeft[0].modal.rows = r.data.data.seg;
        this.s_dataLeft[0].summ = r.data.data.summ;
        this.s_dataLeft[0].link = 'products_RFM_check';
        this.loading = false;
        this.index--;
      });
    },
    async init() {
      await this.getTableData();
      await this.getMetrix();
      await this.getRFM();
    }
  }
};
</script>
<style>
.ave-serv-dynamic .q-table__bottom {
  min-height: 25px;
  background-color: #ffffff !important;
  border: none !important;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.ave-serv-dynamic-m .q-table__bottom {
  min-height: 25px;
  background-color: #ffffff !important;
  border: none !important;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.metrix_graf {
  background: #ffffff;
  border: 1px solid #edf2f7;
  border-radius: 16px;
  margin: 7px 7px;
}
</style>
<style lang="sass">
.av_title
  display: inline-flex

.ave_graf_title
  padding: 25px
  cursor: pointer
  /* width: 100%; */
  font-family: 'Manrope', serif
  font-style: normal
  color: #718096
  font-size: 18px
  font-weight: 600
  display: -webkit-box
  display: -ms-flexbox
  display: block
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center

.ave_graf_title img
  float: right

.ave_graf_title.active
  color: #C94800 !important
  font-size: 18px
  text-decoration: underline

.ave_ch_t
  width: 150px
  height: 25px
  font-size: 12px
  color: #3D1601
  text-align: center
  border-radius: 6px
  font-family: 'Rubik', serif
  font-style: normal
  background-color: #FFF9EE !important


.ave_ch_m
  width: 150px
  height: 25px
  font-size: 12px
  color: #3D1601
  text-align: center
  border-radius: 6px
  font-family: 'Rubik', serif
  font-style: normal
  background-color: #FFFFFF !important

.ave_ch_val
  font-family: 'Rubik', 'serif'
  font-style: normal
  font-weight: 700
  font-size: 10px
  line-height: 100%
  /* identical to box height, or 10px */
  border-radius: 6px
  text-align: center
  color: #3D1601
  min-width: 50px
  height: 18px
  margin: auto
  padding: 5px

.ave_ch_val_m
  font-family: 'Rubik', 'serif'
  font-style: normal
  font-weight: 700
  font-size: 10px
  line-height: 100%
  /* identical to box height, or 10px */
  border-radius: 6px
  text-align: center
  color: #3D1601
  width: 50px
  height: 18px
  margin: auto
  padding: 5px

.bbbk
  background: #FFF9EE
  height: 25px
  display: flex

.ave-serv-dynamic-m
  border-bottom-width: 0 !important
  box-shadow: none !important

  tbody.q-virtual-scroll__padding
    display: none

  tbody th, tbody td
    padding: 10px 0
    background-color: #FFFFFF
    border-bottom-width: 0

  td
    height: 24px !important
    border-bottom-width: 0 !important

  thead tr th
    position: sticky
    z-index: 1
    background-color: #FFFAF2 !important
    text-align: center
    border-bottom-width: 0

  thead tr:last-child th
    z-index: 3

  thead tr:first-child th
    top: 0
    z-index: 1

  tr:first-child th:first-child
    /* highest z-index */
    z-index: 3

  td:first-child
    z-index: 1

  td:first-child, th:first-child
    position: sticky
    left: 0
    width: 150px !important
    white-space: pre-wrap

  tr:hover td
    background-color: #ffffff !important

.ave-serv-dynamic
  border-bottom-width: 0 !important
  box-shadow: none !important


  tbody.q-virtual-scroll__padding
    display: none

  tbody th, tbody td
    padding: 1px 0
    background-color: #FFFFFF
    border-bottom-width: 0

  td
    height: 24px !important
    border-bottom-width: 0 !important

  thead tr th
    position: sticky
    z-index: 1
    background-color: #ffffff !important
    text-align: center
    border-bottom-width: 0

  thead tr:last-child th
    z-index: 3

  thead tr:first-child th
    top: 0
    z-index: 1

  tr:first-child th:first-child
    /* highest z-index */
    z-index: 3

  td:first-child
    z-index: 1

  td:first-child, th:first-child
    position: sticky
    left: 0
    width: 150px !important
    white-space: pre-wrap

  tr:hover td
    background-color: #ffffff !important
</style>
