<template>
  <q-layout view="hHh LpR fFf">
    <q-btn dense flat round icon="menu" @click="leftShown = !leftShown" style="float: right; margin-right: 15px; margin-top: 15px" />
    <menu-drawer v-model="leftShown" :mini.sync="menuMini" :auto-expand="menuAutoExpand">
      <template v-slot:menu>
        <menu-list :menu-items="menuItems" v-on:expansionItemClick="menuMini = false"></menu-list>
      </template>
    </menu-drawer>

    <q-page-container>
      <mheader :menuMini="menuMini" :title="$t('menu')[$route.name]" />
      <transition :name="transitionName">
        <router-view :style="!menuMini ? 'padding: 0 40px 0 0' : 'padding: 0 40px 0 40px'" />
      </transition>
    </q-page-container>
    <q-inner-loading :showing="$store.state.progress" label="Please wait..." label-class="text-teal" label-style="font-size: 1.1em">
      <q-spinner-gears size="50px" :color="$store.state.color" />
      <label class="dinamicAlertText" style="padding: 15px; font-size: 16px; max-width: 400px; text-align: center">{{ $t('pleaseWait')[cNewMessage] }} </label>
    </q-inner-loading>
  </q-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { dbColors } from 'dashblocks';
import MenuDrawer from '../components/menu/menudrawer.vue';
import MenuList from '../components/menu/menulist.vue';
import Mheader from '@/layouts/mheader';

export default {
  name: 'MainLayout',
  components: {
    Mheader,
    MenuDrawer,
    MenuList
  },
  data() {
    return {
      cNewMessage: 0,
      leftShown: true,
      rightShown: false,
      settingsOpen: false,
      messagesOpen: false,
      userInfoOpen: false,
      showSearchToolbar: false,
      searchQuery: '',
      transitionName: '',
      testColors: null,
      menuItems: []
    };
  },
  computed: {
    ...mapState({
      menuAutoExpand: state => state.layout.menuAutoExpand
    }),
    dark: {
      get() {
        return this.$store.state.layout.dark;
      },
      set(value) {
        this.setDark({ dark: value });
      }
    },
    menuMini: {
      get() {
        return this.$store.state.layout.menuMini;
      },
      set(value) {
        this.setMenuMini({ menuMini: value });
      }
    },
    smallScreen() {
      return ['xs', 'sm'].includes(this.$q.screen.name);
    }
  },
  watch: {
    dark: {
      handler: function(val) {
        this.$q.dark.set(val);
      }
    },
    '$route.name'() {
      this.$store.state.subTitle = null;
    },
    '$store.state.progress'(val) {
      if (val) {
        this.sendAlertText();
      }
    }
  },
  mounted() {
    this.makeMenu();
    this.initialize();
    this.$q.dark.set(this.dark);
  },
  methods: {
    ...mapActions({
      setDark: 'layout/setDark',
      setMenuMini: 'layout/setMenuMini'
    }),
    sendAlertText() {
      let l = 0;
      let allerArr = this.$t('pleaseWait');
      setInterval(() => {
        if (l < allerArr.length) {
          // alert[0].innerText = allerArr[l];
          l++;
          this.cNewMessage = l;
        }
      }, 3000);
    },
    makeMenu() {
      this.menuItems = [
        {
          id: 'review',
          title: this.$t('menu').main,
          link: '/',
          img: '/images/menu/rev.svg',
          icon: 'mdi-account-settings',
          level: 0
        },
        {
          id: 'revenue_plan',
          title: this.$t('menu').revenue_plan,
          link: '/revenue_plan',
          img: '/images/menu/plane.svg',
          icon: 'mdi-account-settings',
          level: 0
        },
        {
          id: 'revenue_analysis',
          title: this.$t('menu').revenue_analysis,
          link: '/revenue_analysis',
          img: '/images/menu/analis.svg',
          icon: 'mdi-account-settings',
          level: 0
        },
        {
          id: 'average_check',
          title: this.$t('menu').average_check,
          link: '/average_check',
          img: '/images/menu/avech.svg',
          icon: 'mdi-account-settings',
          level: 0
        },
        {
          id: 'increasing',
          title: this.$t('menu').increasing,
          link: this.$store.state.user.type == 'services' ? '/increasing/managers' : '/increasing',
          img: '/images/menu/convers.svg',
          icon: 'mdi-account-settings',
          level: 0
        },
        {
          id: 'growth_customers',
          title: this.$t('menu').g_customers,
          link: '/growth/customers',
          img: '/images/menu/traff.svg',
          icon: 'mdi-account-settings',
          level: 0
        } /*
        {
          id: 'favorites',
          title: this.$t('menu').favorites,
          link: '/favorites',
          img: '/images/icons/favorite.svg',
          icon: 'mdi-account-settings',
          level: 0,
        },*/,
        {
          id: 'account',
          title: this.$t('menu').account,
          link: '/account',
          img: '/images/menu/setting.svg',
          icon: 'mdi-account-settings',
          level: 0
        },
        {
          id: 'settings',
          title: this.$t('menu').settings,
          link: '/settings',
          img: '/images/menu/setting.svg',
          icon: 'mdi-account-settings',
          level: 0
        },

        {
          id: 'user-list',
          title: this.$t('menu').users,
          link: '/users',
          icon: 'mdi-account-group',
          level: 10
        }
        /*
                {
                  id: '6',
                  title: 'Style',
                  icon: 'mdi-draw',
                  items: [
                    { id: '7', title: 'Typography', link: '/typography', icon: 'mdi-format-text' },
                    { id: '8', title: 'Icons', link: '/icons', icon: 'mdi-dots-horizontal-circle-outline' },
                    {
                      id: '9',
                      title: 'MDI Icons',
                      caption: 'Material Design Icons',
                      link: '/mdi',
                      icon: 'mdi-dots-horizontal-circle-outline',
                      badge: '5045',
                      badgeColor: 'accent',
                      sideCaption: 'icons'
                    }
                  ]
                },
                {
                  id: 'pages',
                  title: 'Pages',
                  icon: 'mdi-link-box-outline',
                  items: [{ id: '7', title: 'Login', link: '/login', icon: 'mdi-login' }]
                },

                { title: 'Requests', link: '/requests', icon: 'sync_alt' },
                { title: 'Errors', link: '/errors', icon: 'error' },
                { title: 'API', link: '/api', icon: 'code' },
                { title: 'API Operation', link: '/apiop', icon: 'settings_ethernet' },
                { title: 'API Responses', link: '/apiresponses', icon: 'pie_chart' },
                { title: 'Rates & Durations', link: '/rates', icon: 'schedule' },
                { title: 'Payload', link: '/payload', icon: 'swap_vert' },
                { title: 'Last Errors', link: '/lasterrors', icon: 'error_outline' },
                { title: 'Longest Requests', link: '/longestrequests', icon: 'hourglass_empty' }*/
      ];
    },
    initialize() {
      let dbc = dbColors;

      //this.testColors = dbColors.getColors(true); // TEMP TODO REMOVE
      let cSteps = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];

      dbColors.setColorScheme('default', {
        light: dbColors.d3ScaleChromatic.schemeTableau10,
        dark: dbColors.grafanaColors
      });

      dbColors.setColorScheme('Grafana', {
        light: dbColors.grafanaColors,
        dark: dbColors.grafanaColors
      });

      dbColors.setColorScheme('Tableau', {
        light: dbColors.d3ScaleChromatic.schemeTableau10,
        dark: dbColors.d3ScaleChromatic.schemeTableau10
      });

      dbColors.setColorScheme('Diverging', {
        light: dbColors.d3ScaleChromatic.schemeRdYlBu[10],
        dark: dbColors.d3ScaleChromatic.schemeRdYlBu[10]
      });

      dbColors.setColorScheme('Categorical', {
        light: dbColors.d3ScaleChromatic.schemeDark2,
        dark: dbColors.d3ScaleChromatic.schemeSet3 // schemeBuGn[9],
      });

      dbColors.setColorScheme('Warm', {
        light: cSteps.map(x => dbColors.d3ScaleChromatic.interpolateWarm(x)),
        dark: cSteps.map(x => dbColors.d3ScaleChromatic.interpolateWarm(x))
      });

      dbColors.setColorScheme('Cool', {
        light: cSteps.map(x => dbColors.d3ScaleChromatic.interpolateCool(x)),
        dark: cSteps.map(x => dbColors.d3ScaleChromatic.interpolateCool(x))
      });

      dbColors.setColorScheme('Calm', {
        light: ['#912e4d', '#00bd56', '#f02192', '#acd36d', '#8079ff', '#919200', '#f1adff', '#547600', '#ff8241', '#f8ba7a'],
        dark: ['#ce4c3a', '#60b14d', '#8162cb', '#bab141', '#c964b5', '#4bb092', '#c25874', '#717e37', '#688ccd', '#c78344']
      });
      //['#cc4ba2', '#64ac48', '#8361cd', '#9a963f', '#5f8cce', '#cd5136', '#4aac8d', '#c7596d', '#c78543', '#b578b6']
      //['#ce4c3a', '#60b14d', '#8162cb', '#bab141', '#c964b5', '#4bb092', '#c25874', '#717e37', '#688ccd', '#c78344']

      dbColors.setColorScheme('Fancy', {
        light: ['#38646f', '#4e2300', '#274f8e', '#6b5e1f', '#794f81', '#2a2e00', '#00485e', '#7c553f', '#2e0a06', '#2b2219'],
        dark: ['#b1d8a0', '#74aff3', '#dbcd9d', '#7bcaed', '#ebaba7', '#74d6e0', '#deb1e0', '#a1e9d1', '#adbce9', '#8dc4af']
      });

      dbColors.setColorScheme('Colorblind Friendly', {
        light: ['#37efab', '#58006c', '#b3e370', '#9a73ec', '#b1a200', '#0051ab', '#ff9e6a', '#601016', '#685d00', '#de3357'],
        dark: ['#78a563', '#666fe8', '#c1b01b', '#014ca6', '#ffca5e', '#e2b1ff', '#008418', '#ff77bf', '#811e00', '#ff8c56']
      });
    }
  }
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
