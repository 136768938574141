<template>
  <q-page style="margin: 15px">
    <div class="row">
      <div class="col-sm-6">
        <div class="row cats_sel">
          <div class="col-5">
            <label for="cat_all">
              <input type="checkbox" v-model="all_cat" id="cat_all" />
              {{ !all_cat ? $t('ch_all') : $t('unch_all') }}
            </label>
          </div>
          <div class="col-5">
            <label>
              {{ $t('keyTooRec') }}
            </label>
          </div>
          <div class="col-2">
            <select v-model="all_days">
              <option v-for="p in periods" :value="p" :key="'p' + p">{{ p }}</option>
            </select>
          </div>
        </div>
        <hr />
        <div v-for="cat in categoryes" :key="cat.label + cat.id" class="row cats_sel">
          <div class="col-6">
            <label :for="cat.label + cat.id">
              <input type="checkbox" :id="cat.label + cat.id" v-model="categoryId" :value="cat.id" />
              {{ cat.label }}
            </label>
          </div>
          <div class="col-4">
            <label :for="cat.label + cat.id + 'r'">
              <input type="checkbox" :id="cat.label + cat.id + 'r'" v-model="cat.in_rec" :value="cat.in_rec" />
            </label>
          </div>
          <div class="col-2">
            <select v-model="cat.days" :disabled="all_cat">
              <option v-for="p in periods" :value="p" :key="'p' + p">{{ p }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <q-btn color="white" text-color="black" @click="setCategory">{{ $t('save') }}</q-btn>
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'products_service',
  data() {
    return {
      categoryId: [],
      categoryes: [],
      all_cat: false,
      all_days: 90,
      periods: [],
      index: 0
    };
  },
  watch: {
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    },
    all_cat(val) {
      if (val) {
        for (let c in this.categoryes) {
          let id = this.categoryes[c].id;
          if (this.categoryId.indexOf(id) < 0) {
            this.categoryId.push(id);
          }
        }
      } else {
        this.categoryId = [];
      }
    },
    all_days(val) {
      for (let c in this.categoryes) {
        let id = this.categoryes[c].id;
        if (this.categoryId.indexOf(id) >= 0) {
          this.categoryes[c].days = val;
        }
      }
    }
  },
  created() {
    for (let t = 1; t <= 180; t++) {
      this.periods.push(t);
    }
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      this.index++;
      this.$h
        .get('/users/get-category')
        .then(r => {
          this.categoryes = r.data.data;
        })
        .then(() => {
          this.index--;
        });
    },
    setCategory() {
      this.index++;
      this.$h
        .post('/users/set-category', { cats: this.categoryes })
        .then(r => {
          this.categoryes = r.data.data;
        })
        .then(() => {
          this.index--;
        });
    }
  }
};
</script>

<style scoped>
.cats_sel:hover {
  background-color: #027be3;
}
.cats_sel label,
.cats_sel select {
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px !important;
  margin-top: 3px;
  display: block;
}
</style>
