<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="bvm_border rev_graf">
          <label class="graf_title"
            >{{ $t('keyCategoryMarginality') }}
            <select class="select_types" v-model="optType">
              <option v-for="t in gTypes" :value="t" :key="'b' + t">{{ $t('ket_gtypes')[t] }}</option>
            </select>
            <q-img @click="addFav('keyCategoryMarginality', optionsBar, 'bar')" src="/images/icons/chart/b_shape_org.svg" alt="fav" class="favorite_h" />
          </label>
          <div v-if="catShow">
            <q-table class="ave-serv-dynamic" :data="catRows" :columns="catColumns" :loading="!ready" :rows-per-page-options="[0]">
              <template v-slot:header="props">
                <q-tr>
                  <q-th align="left" style="z-index: 5;width: max-content;" v-for="m in props.cols" :hidden="m.field == 'id'" :key="m.field">
                    {{ m.label == 'title' ? $t('category') : m.type != 'day' && m.type != 'weak' ? $t('f')[m.type][m.label] : m.label }}
                  </q-th>
                </q-tr>
              </template>
              <template v-slot:body="props">
                <q-tr :id="props.row.title" :data-set="props.row.id">
                  <q-td v-for="col in props.cols" class="ave_ch_td" :key="Math.random() + col.field" :hidden="col.field == 'id'">
                    <div v-if="col.field == 'title'" class="bbbk" style="text-align: left">
                      <div class="ave_ch_t">
                        <b>{{ col.value }}</b>
                      </div>
                    </div>
                    <div v-else class="bbbk">
                      <div class="ave_ch_val" :style="'background: rgb(217, 111, 51, ' + col.value + '%)'">
                        {{ col.value }}
                      </div>
                    </div>
                  </q-td>
                </q-tr>
              </template>
              <template v-slot:bottom class="tt"></template>
            </q-table>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="bvm_border rev_graf">
          <label class="graf_title"
            >{{ $t('keyRevenue_potential') }}
            <q-img @click="addFav('keyRevenue_potential', optionsPricing, 'bar')" src="/images/icons/chart/b_shape_org.svg" alt="fav" class="favorite_h" />
          </label>
          <div>
            <BarChart :ch-options="optionsPricing" style="height: 350px" v-if="ready" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <label class="rew_title">{{ $t('keyRevenue_increase') }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <Check_slider s-color="org" :s_data="s_dataLeft"></Check_slider>
      </div>
    </div>
  </div>
</template>

<script>
import Check_slider from '@/components/items/check_slider';
import BarChart from '@/chart/BarChart.vue';

export default {
  name: 'z_pricing',
  components: { BarChart, Check_slider },
  props: {
    fType: {
      type: String
    },
    filters: {
      type: Object
    }
  },
  data: () => {
    return {
      gTypes: ['cats'],
      optType: 'cats',
      barType: 'collect',

      catShow: true,
      collShow: false,
      regShow: false,
      catRows: [],
      catColumns: [],
      loading: false,
      s_dataLeft: [
        {
          title: 'Рекомендации по повышению цены на основе изменения себестоимости',
          kpif: 'Текущий процент маржинальности',
          kpit: 'Средняя маржинальность по категориям',
          object: 'Товары',
          summ: 0,
          modal: {
            show: false,
            rows: [],
            columns: [
              {
                field: 'category',
                label: 'Рекомендации по повышению цены на основе изменения себестоимости'
              },
              {
                field: 'kpif',
                label: 'KPI факт'
              },
              {
                field: 'kpit',
                label: 'KPI цель'
              }
            ],
            loading: false
          }
        }
      ],
      slide: 1,
      slide2: 1,
      optionsPricing: {
        color: ['#E0641F', '#F39058', '#FFD2B9'],
        legend: {
          padding: [0, 0, 30, 30],
          type: 'plain',
          data: [],
          left: 'auto'
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(val) {
              return val + ' %';
            }
          }
        },
        series: []
      },
      ready: true,
      index: 0
    };
  },
  created() {
    this.$store.state.color = 'orange-14';
  },
  watch: {
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    },
    optType() {
      this.getData();
    },
    fType(val) {
      this.getData();
    },
    filters() {
      if (this.index == 0) {
        this.init().then(() => {});
      }
    }
  },
  mounted() {
    for (let f in this.filters) {
      this.$watch('filters.' + f, v => {});
    }

    if (this.filters.fType) {
      this.init().then(() => {});
    }
  },
  methods: {
    addFav(type, data, g) {
      this.$h.post('/favorite/add', { t: type, d: data, g: g }).then(r => {});
    },
    selectType(t) {
      this.fType = t;
    },
    exportAll() {},

    getData() {
      this.ready = false;
      this.index++;
      this.$h
        .post(`/stat/avercheck/get-pricing-${this.optType}`, { f: this.filters })
        .then(r => {
          this.catColumns = r.data.data.m;
          this.catRows = r.data.data.d1;
        })
        .then(() => {
          this.index--;
          this.ready = true;
        });
    },
    getPricingData() {
      this.ready = false;
      this.index++;
      this.$h
        .post(`/stat/avercheck/get-pricing`, { f: this.filters })
        .then(r => {
          let cats = r.data.data.m;
          let cats2 = r.data.data.m2;

          let mt = [];
          for (let c in cats) {
            mt.push(cats[c]);
          }

          this.optionsPricing.xAxis[0].data = mt;

          let dc2 = r.data.data.cd;

          let h = this.optionsPricing.legend.data.length;
          if (h) {
            this.optionsPricing.legend.data.splice(0, h);
          }
          let s = this.optionsPricing.series.length;
          if (s) {
            this.optionsPricing.series.splice(0, s);
          }
          for (let c in dc2) {
            this.optionsPricing.legend.data.push(dc2[c].title);
            this.optionsPricing.series.push({
              name: dc2[c].title,
              type: 'bar',
              stack: 'category2',
              tooltip: {
                valueFormatter: function(value) {
                  return value;
                }
              },
              itemStyle: {
                borderRadius: c == dc2.length - 1 ? [4, 4, 0, 0] : 0 //, 0, 0],
                //shadowBlur: 3
              },
              data: dc2[c].data
            });
          }
        })
        .then(() => {
          this.index--;
          this.ready = true;
        });
    },
    getCatList() {
      this.index++;
      this.$h.get('/stat/avercheck/get-rec-mcat').then(r => {
        this.s_dataLeft[0].modal.rows = r.data.data.l.list;
        this.s_dataLeft[0].summ = r.data.data.l.sum;
        this.s_dataLeft[0].link = 'pricing_l';

        this.index--;
      });
    },

    async init() {
      await this.getData();
      await this.getPricingData();
      await this.getCatList();
    }
  }
};
</script>

<style scoped></style>
