<template>
  <div v-if="cId != 0" style="padding-right: 5px">
    <label class="user_p_date">Wildberries</label>
    <div class="row">
      <div class="col-8">
        <label class="user_form">Клиент токен</label>
        <input class="user_form_inp" v-model="wb.apiKey" style="width: 100%" type="text" />
      </div>
      <div class="col-1"></div>
      <div class="col-1">
        <label class="user_form">ВКЛ</label>
        <input class="user_form_inp" style="width: 25px" v-model="wb.enable" type="checkbox" />
      </div>
      <div class="col-2">
        <label class="user_form">&nbsp;</label>
        <input class="user_form_inp" style="cursor: pointer" @click="saveMarket(wb, 'wb')" value="Сохранить" type="button" />
      </div>
    </div>
    <label class="user_p_date">Ozon</label>
    <div class="row">
      <div class="col-2">
        <label class="user_form">Клиент ид</label>
        <input class="user_form_inp" style="width: 100%" v-model="ozon.clientId" type="number" />
      </div>
      <div class="col-1"></div>
      <div class="col-5">
        <label class="user_form">Клиент токен</label>
        <input class="user_form_inp" style="width: 100%" v-model="ozon.authKey" type="text" />
      </div>
      <div class="col-1"></div>
      <div class="col-1">
        <label class="user_form">ВКЛ</label>
        <input class="user_form_inp" style="width: 25px" v-model="ozon.enable" type="checkbox" />
      </div>
      <div class="col-2">
        <label class="user_form">&nbsp;</label>
        <input class="user_form_inp" style="cursor: pointer" @click="saveMarket(ozon, 'ozon')" value="Сохранить" type="button" />
      </div>
    </div>
    <label class="user_p_date">Lamoda</label>
    <div class="row">
      <div class="col-4">
        <label class="user_form">Клиент ид</label>
        <input class="user_form_inp" style="width: 100%" v-model="lamoda.client_id" type="text" />
      </div>

      <div class="col-4">
        <label class="user_form">Клиент токен</label>
        <input class="user_form_inp" style="width: 100%" v-model="lamoda.client_secret" type="text" />
      </div>
      <div class="col-1"></div>
      <div class="col-1">
        <label class="user_form">ВКЛ</label>
        <input class="user_form_inp" style="width: 25px" v-model="lamoda.enable" type="checkbox" />
      </div>
      <div class="col-2">
        <label class="user_form">&nbsp;</label>
        <input class="user_form_inp" style="cursor: pointer" @click="saveMarket(lamoda, 'lamoda')" value="Сохранить" type="button" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserSetting',
  props: {
    cId: {
      default: 0
    }
  },
  components: {},
  data() {
    return {
      wb: { apiKey: null, enable: 0 },
      ozon: { authKey: null, clientId: null, enable: 0 },
      lamoda: { client_id: null, client_secret: null, enable: 0 }
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$h.get(`/users/markets?cid=${this.cId}`).then(r => {
        console.log(r);
        if (r.data.data.ozon != null) {
          this.ozon = r.data.data.ozon;
        }
        if (r.data.data.wb) {
          this.wb = r.data.data.wb;
        }
        if (r.data.data.lm) {
          this.lamoda = r.data.data.lm;
        }
        console.log(this.lamoda);
      });
    },
    saveMarket(market, type) {
      this.$h.post(`/users/set-markets?cid=${this.cId}`, { market, type }).then(r => {});
    }
  }
};
</script>
<style>
.q-notification__message {
  font-size: 16px !important;
  font-weight: bold;
  text-align: center;
}
</style>
