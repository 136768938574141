<template>
  <div class="bvm_border rev_filter">
    <div class="row">
      <div class="col-md-3 col-12" v-if="sRange">
        <div class="rew_f_p">
          <img src="/images/icons/calendar.svg" alt="calendar" @click="calendarOpen = !calendarOpen" />
          <date-picker
            v-model="filters.dateFrom"
            :show-week-number="true"
            :min="rangDate[0]"
            :disabled-date="disabledDate"
            :type="calenType == 'day' ? 'month' : 'year'"
            placeholder="Select range"
            value-type="format"
            format="YYYY-MM-DD"
            :range="calenType == 'year'"
          >
          </date-picker>
        </div>
      </div>
      <div :class="sRange ? 'col-md-6 col-12' : 'col-md-8 col-12'">
        <div class="rew_f_p">
          <q-btn v-for="typ in fTypes" :key="typ" :class="'f_type_b' + (typ == filters.fType ? '_active' : '')" @click="selectType(typ)">
            {{ $t('ftypes')[typ] }}
          </q-btn>
        </div>
      </div>
      <div class="col-md-1 col-12">
        <div v-if="exp" class="rew_f_p">
          <q-btn class="f_type_b_exp">
            <div style="padding: 0 5px">{{ $t('keyExport') }}</div>
          </q-btn>
        </div>
      </div>
      <div class="col-md-2 col-12" v-if="filters.markets.length">
        <div class="rew_f_p" style="cursor: pointer" @click="filtShow = !filtShow">
          <img src="/images/icons/filter.svg" alt="filter" />
          <span class="rev_f_text">{{ $t('keyFilter') }}</span>
          <img src="/images/icons/f_vectr.svg" style="margin-left: 15px" :class="filtShow ? 'rotate-180' : ''" alt="f_filter" />
        </div>
      </div>
    </div>
    <div v-show="calendarOpen" class="row" style="position: absolute">
      <div class="col-md-2 col-12"></div>
    </div>
    <div v-if="filters.markets.length" id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" @click="closeNav()">&times;</a>
      <div class="filter_block" @mouseleave="closeNav">
        <div class="filters_cats" style="cursor: pointer; text-align: left" @click="markShow = !markShow">
          <img class="filt_icon" src="/images/icons/filter/markets.svg" alt="f_filter" />
          <span class="rev_f_text">{{ $t('keyMarkets') }}</span>
          <img src="/images/icons/f_vectr.svg" style="margin-left: 15px" :class="markShow ? 'rotate-180' : ''" alt="f_filter" />
        </div>
        <div v-show="markShow" class="filters_cats_list">
          <div class="row" v-for="(c, i) in mTypes" :key="'mark' + i">
            <div class="col-12 f_rows">
              <label class="filt_check" :for="'market' + i">
                <input type="radio" :checked="filters.markets.indexOf(c.id) >= 0" :id="'market' + i" @change="selectMarket(c.id)" /> &nbsp;&nbsp;{{ c.title }}
              </label>
            </div>
          </div>
        </div>
        <div class="filters_cats" style="cursor: pointer; text-align: left" @click="catShow = !catShow">
          <img class="filt_icon" src="/images/icons/filter/category.svg" alt="f_filter" />
          <span class="rev_f_text">{{ $t('category') }}</span>
          <img src="/images/icons/f_vectr.svg" style="margin-left: 15px" :class="catShow ? 'rotate-180' : ''" alt="f_filter" />
        </div>
        <div v-show="catShow" class="filters_cats_list">
          <div class="row" v-for="c in category" :key="'cat' + c.id + c.type">
            <div class="col-12 f_rows" v-show="filters.markets.indexOf(parseInt(c.type)) >= 0">
              <label class="filt_check" :for="'checkbox' + c.id + c.type">
                <input type="checkbox" :id="'checkbox' + c.id + c.type" @change="selectCat(c.id)" />
                &nbsp;&nbsp;{{ c.title }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  name: 'zFilter',
  components: { DatePicker },
  props: {
    exp: {
      type: Boolean,
      default: false
    },
    rangDate: {
      type: Array
    },
    sRange: {
      type: Boolean,
      default: true
    },
    fTypes: {
      type: Array
    },
    mTypes: {
      type: Array
    },
    category: {
      type: Array
    },
    sType: {
      default: 'month'
    },
    fShow: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      value: null,
      cat: [],
      calendarOpen: false,
      calenType: 'month',
      filtShow: false,
      catShow: false,
      markShow: false,
      markets: [],
      filters: {
        markets: [],
        cats: [],
        dateFrom: [],
        fType: 'month'
      }
    };
  },
  created() {
    for (let f in this.filters) {
      this.$watch('filters.' + f, v => {
        this.selectFilters();
      });
    }
    this.markets = this.$t('markets');
    let l = this.rangDate.length;
    this.filters.dateFrom = [this.rangDate[0] + '-01-01', this.rangDate[0] + '-12-31'];
  },
  watch: {
    filtShow(v) {
      if (v) {
        document.getElementById('mySidenav').style.width = '250px';
      } else {
        document.getElementById('mySidenav').style.width = '0';
      }
    }
  },
  mounted() {},
  methods: {
    disabledDate(date) {
      let d = new Date(date).getFullYear();
      return this.rangDate.indexOf(d) >= 0 ? false : true;
    },
    selectFilters() {
      this.$emit('selectFilters', this.filters);
    },
    selectCat(id) {
      let ind = this.filters.cats.indexOf(id);

      if (ind >= 0) {
        this.filters.cats.splice(ind, 1);
      } else {
        this.filters.cats.push(id);
      }
    },
    selectMarket(id) {
      this.filters.markets = [];
      this.filters.cats = [];
      this.filters.markets.push(id);
      if (this.filters.markets.indexOf(id) > 0) {
        this.filters.markets = [];
      }
    },
    closeNav() {
      this.filtShow = false;
    },
    selectType(t) {
      switch (t) {
        case 'year':
          this.calenType = 'year';
          break;
        case 'month':
        case 'quart':
          this.calenType = 'month';
          break;
        case 'weak':
          this.calenType = 'week';
          break;
        case 'day':
          this.calenType = 'day';
          break;
      }
      this.filters.fType = t;
    }
  }
};
</script>

<style>
.rew_f_p .q-btn__wrapper .q-btn__content {
}

/* The side navigation menu */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 60px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 12px;
  margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}
</style>
