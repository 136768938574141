<template>
  <q-layout>
    <q-page-container>
      <q-page class="flex flex-center">
        <q-card class="ub-login-card">
          <q-card-section class="ub-login-hdr text-white text-center">
            <q-img src="/images/Frame_9.webp" style="width: 128px;"></q-img>
          </q-card-section>
          <q-form @submit.prevent="login">
            <q-card-section>
              <q-card-section>
                <q-input v-model="email" clearable :label="$t('user_f').email">
                  <template v-slot:prepend>
                    <q-icon name="mdi-account" />
                  </template>
                </q-input>
                <q-input v-model="password" type="password" clearable :label="$t('user_f').password">
                  <template v-slot:prepend>
                    <q-icon name="mdi-lock" />
                  </template>
                </q-input>
              </q-card-section>
            </q-card-section>

            <q-card-actions align="right">
              <q-btn type="submit" color="primary" unelevated>Login</q-btn>
            </q-card-actions>
          </q-form>
        </q-card>
      </q-page>
    </q-page-container>
    <q-dialog v-model="$store.state.error">
      <q-card>
        <q-card-section class="q-pt-none">
          {{ $store.state.message }}
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-layout>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: ''
    };
  },
  computed: {
    dark: {
      get() {
        return this.$store.state.layout.dark;
      }
    }
  },
  mounted() {
    this.$q.dark.set(this.dark);
  },
  methods: {
    login: function() {
      let email = this.email;
      let password = this.password;
      this.$store
        .dispatch('login', { email, password })
        .then(() => {
          window.location.href = '/';
        })
        .catch(err => console.log(err));
    }
  }
};
</script>

<style lang="scss">
.q-field__marginal {
  background-color: #ffffff !important;
}
.ub-login-card {
  width: 100%;
  max-width: 400px;
  border-radius: 12px !important;

  .ub-login-hdr {
    background-color: #3cb1d7;
    // background: #0f2027; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3cb1d7, #203a43); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3cb1d7, #203a43); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}
</style>
