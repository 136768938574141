<template>
  <q-page class="ub-page">
    <div class="row">
      <div class="col-12 col-md-12">
        <b-filter
          v-if="fReady"
          @selectPeriod="selectFType"
          @selectFilters="selectFilters"
          :category="category"
          :f-types="fTypes"
          :m-types="mTypes"
          :rangDate="rangDate"
        ></b-filter>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="bvm_border rev_graf">
          <label class="graf_title"
            >{{ $t('keyBenchmarking_Revenue') }}
            <q-img @click="addFav('keyBenchmarking_Revenue', options, 'line')" src="/images/icons/favorite.svg" alt="fav" class="favorite_h"
          /></label>
          <div>
            <LineChart :ch-options="options" style="height: 350px" v-if="ready" />
          </div>
          <div class="row">
            <div class="col-md-5 col-12">
              <div class="rew_f_s" style="display: none !important;">
                <img src="/images/icons/chart/setting.svg" />
                <span class="rev_f_text">{{ $t('keyChange_plan_manually') }}</span>
              </div>
            </div>
            <div class="col-md-5 col-12">
              <div class="rew_f_s" style="display: none !important;">
                <img src="/images/icons/chart/import_b.svg" alt="import_b" />
                <span class="rev_f_text">{{ $t('keyImport_new_plan') }}</span>
              </div>
            </div>
            <div class="col-md-2 col-12">
              <div class="rew_f_s" style="display:none;cursor: pointer" @click="openLink('plan')">
                <img src="/images/icons/chart/import.svg" alt="import" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="bvm_border rev_graf">
          <label class="graf_title"
            >{{ $t('keyRevenue_forecast') }}
            <q-img @click="addFav('keyRevenue_forecast', optionsBar, 'bar')" src="/images/icons/favorite.svg" alt="fav" class="favorite_h"> </q-img
          ></label>
          <div>
            <BarChart :ch-options="optionsBar" style="height: 350px" v-if="ready" />
          </div>
          <div class="row" style="display: none !important;">
            <div class="col-md-6 col-12">
              <div class="rew_f_s">
                <img src="/images/icons/chart/import_b.svg" alt="setting" />
                <span class="rev_f_text">{{ $t('keyCreate_plan_based') }}</span>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="rew_f_r">
                <div style="padding-top: 10px">
                  <div class="tit_slide">{{ setRange }}%</div>
                  <q-slider
                    class="rev_slide"
                    :s_data="s_data"
                    v-model="setRange"
                    :min="-100"
                    :max="100"
                    :step="1"
                    snap
                    color="wheat"
                    selection-color="transparent"
                  />
                  <div class="slide_proc"><span style="float: left">-100%</span><span style="float: right">100%</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import LineChart from '@/chart/LineChart';
import BarChart from '@/chart/BarChart';
import bFilter from '@/components/items/crmFilter';
import { Notify } from 'quasar';

export default {
  name: 'revenue_plan',
  components: { bFilter, BarChart, LineChart },
  data: () => {
    return {
      s_data: [],
      fTypes: ['weak', 'month', 'quart', 'year'],
      fType: 'year',
      mTypes: [],
      category: [],
      fShow: false,
      ready: false,
      fReady: false,
      setRange: 0,
      filters: {},
      rangDate: [],
      options: {
        color: ['#AB3AD3', '#718096', '#3A78D3', '#3A78D3'],
        legend: {
          padding: [0, 0, 30, 30],
          width: '100%',
          //type: 'scroll',
          data: [],
          overflow: 'break'
        },
        tooltip: {
          trigger: 'axis',
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], '10%'];
          },
          renderMode: 'html',
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          step: 1,
          splitLine: {
            show: true
          },
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true
          },
          axisLabel: {
            formatter: function(val) {
              return val / 1000000 + ' M';
            }
          }
        },
        title: {},
        series: []
      },
      optionsBar: {
        color: ['#718096', '#176877', '#00898A', '#23AA8F', '#64C987', '#AAE479', '#CDFA6E'],
        legend: {
          padding: [0, 0, 30, 30],
          width: '80%',
          type: 'scroll',
          data: []
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(val) {
              return val / 1000000 + ' M';
            }
          }
        },
        series: []
      },
      index: 0,
      mt: [],
      bUrl: '/stat/'
    };
  },
  created() {
    this.getReviewData();
  },
  watch: {
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    },
    filters() {
      if (this.index == 0) {
        let x = this.options.xAxis.data.length;
        this.options.xAxis.data.splice(0, x);
        let t = this.options.legend.data.length;
        this.options.legend.data.splice(0, t);
        let f = this.options.series.length;
        this.options.series.splice(0, f);

        let l = this.optionsBar.legend.data.length;
        this.optionsBar.legend.data.splice(0, l);
        let r = this.optionsBar.series.length;
        this.optionsBar.series.splice(0, r);
        this.init();
      }
    }
  },
  methods: {
    openLink(fileName) {
      this.$store.state.progress = true;
      this.$h
        .get(`/export/csv/${fileName}`)
        .then(function(response) {
          if (response.data.error && response.data.error == true) {
            Notify.create({
              position: 'top-right',
              type: 'errors',
              message: 'Файл не готов',
              timeout: 2000
            });
          } else {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // IE variant
              window.navigator.msSaveOrOpenBlob(new Blob([response.data], { type: 'text/csv' }), fileName);
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }
          }
        })
        .then(() => {
          this.$store.state.progress = false;
        });
    },
    exportAll() {},
    addFav(type, data, g) {
      this.$h.post('/favorite/add', { t: type, d: data, g: g }).then(r => {});
    },
    selectFType(arg) {
      //this.fType = arg;
    },
    selectFilters(arg) {
      this.filters = {};
      for (let a in arg) {
        this.filters[a] = arg[a];
      }
      //\\
    },
    getReviewData() {
      this.index++;
      this.$h
        .get(`${this.bUrl}revenue/get-years`)
        .then(r => {
          this.category = r.data.data.category;
          this.mTypes = r.data.data.types;
          let d = r.data.data.date;
          for (let l in d) {
            this.rangDate.push(parseInt(d[l].y));
          }
        })
        .then(() => {
          this.index--;
        })
        .then(() => {
          this.fReady = true;
        });
    },
    async getPlane() {
      this.ready = false;
      this.index++;
      await this.$h
        .post(`${this.bUrl}revenue/get-plane`, { f: this.filters })
        .then(r => {
          let months = r.data.data.m;
          let d1 = r.data.data.d1;
          let d2 = r.data.data.d2;
          let p1 = r.data.data.p1;
          let p2 = r.data.data.p2;

          switch (this.filters.fType) {
            case 'month':
              for (let m in months) {
                this.mt.push(this.$t('monthNames')[m].substring(0, 3));
              }
              this.options.xAxis.axisLabel.interval = 0;
              break;
            case 'year':
              this.mt = months;
              this.options.xAxis.axisLabel.interval = 0;
              break;
            case 'weak':
              this.mt = months;
              this.options.xAxis.axisLabel.interval = 3;
              break;
            case 'quart':
              this.mt = months;
              this.options.xAxis.axisLabel.interval = 0;
              break;
          }
          this.options.xAxis.data = this.mt;
          this.options.legend.data.push({
            name: 'Потенциальная\nвыручка',
            icon: 'image://https://' + window.location.host + '/images/icons/chart/up_p.svg',
            padding: [0, 15]
          });
          this.options.series.push({
            name: 'Потенциальная\nвыручка',
            data: d1,
            areaStyle: {
              color: '#AB3AD3',
              origin: 'auto',
              shadowBlur: 0,
              opacity: 0.05
            },
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          });
          this.options.legend.data.push({
            name: 'Плановая\nвыручка',
            icon: 'image://https://' + window.location.host + '/images/icons/chart/up_f.svg',
            padding: [0, 15]
          });
          this.options.series.push({
            name: 'Плановая\nвыручка',
            data: d2,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          });
          this.options.legend.data.push({
            name: 'Фактическая выручка',
            icon: 'image://https://' + window.location.host + '/images/icons/chart/up_s.svg',
            padding: [0, 15]
          });
          this.options.series.push({
            name: 'Фактическая выручка',
            data: p1,
            type: 'line',
            smooth: true,
            lineStyle: {
              type: 'line'
            }
          });
          this.options.legend.data.push({
            name: 'Прогнозная\nвыручка',
            icon: 'image://https://' + window.location.host + '/images/icons/chart/up_s_p.svg',
            padding: [0, 15]
          });
          this.options.series.push({
            name: 'Прогнозная\nвыручка',
            data: p2,
            type: 'line',
            smooth: true,
            lineStyle: {
              type: 'dashed'
            }
          });
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    async getCats() {
      this.ready = false;
      this.index++;
      await this.$h
        .post(`${this.bUrl}revenue/get-cats`, { f: this.filters })
        .then(r => {
          let dc = r.data.data.cd;
          let ind = 1;
          this.optionsBar.xAxis[0].data = this.mt;

          for (let c in dc) {
            this.optionsBar.legend.data.push(dc[c].title);
            this.optionsBar.series.push({
              name: dc[c].title,
              type: 'bar',
              stack: 'category',
              tooltip: {
                valueFormatter: function(value) {
                  return value.toLocaleString('ru-Ru', {
                    style: 'currency',
                    currency: 'RUB',
                    maximumFractionDigits: 0
                  });
                }
              },
              itemStyle: {
                borderRadius: ind == dc.length ? [50, 50, 0, 0] : 0, //, 0, 0],
                //shadowBlur: 3
                opacity: 1
              },
              data: dc[c].data
            });
            ind++;
          }

          let dc2 = r.data.data.cd2;
          ind = 1;
          for (let c in dc2) {
            this.optionsBar.series.push({
              name: dc2[c].title,
              type: 'bar',
              stack: 'category2',
              tooltip: {
                valueFormatter: function(value) {
                  let ret = value.toLocaleString('ru-Ru', {
                    style: 'currency',
                    currency: 'RUB',
                    maximumFractionDigits: 0
                  });
                  return ret;
                }
              },
              itemStyle: {
                borderRadius: ind == dc2.length ? [50, 50, 0, 0] : 0, //, 0, 0],
                //shadowBlur: 3
                opacity: 0.3
              },
              data: dc2[c].data
            });
            ind++;
          }
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    async init() {
      await this.getCats();
      await this.getPlane();
    }
  }
};
</script>

<style scoped></style>
