<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="bvm_border rev_graf">
        <label class="graf_title">Выручка по сегментам<img src="/images/icons/b_shape.svg" alt="fav" class="favorite_h"/></label>
        <div>
          <BarChart :ch-options="optionsSeg" style="height: 350px" v-if="ready" />
        </div>
      </div>
      <div class="bvm_border rev_graf">
        <label class="graf_title">Количество клиентов по сегментам<img src="/images/icons/b_shape.svg" alt="fav" class="favorite_h"/></label>
        <div>
          <BarChart :ch-options="optionsCli" style="height: 350px" v-if="ready" />
        </div>
      </div>
      <Slider s-color="blue"></Slider>
    </div>
    <div class="col-12 col-md-6">
      <div class="bvm_border rev_graf">
        <label class="graf_title">Выручка по сегментам, % <img src="/images/icons/b_shape.svg" alt="fav" class="favorite_h"/></label>
        <div>
          <BarChart :ch-options="optionsSegP" style="height: 350px" v-if="ready" />
        </div>
      </div>
      <div class="bvm_border rev_graf">
        <label class="graf_title">Количество клиентов по сегментам, %<img src="/images/icons/b_shape.svg" alt="fav" class="favorite_h"/></label>
        <div>
          <BarChart :ch-options="optionsCliP" style="height: 350px" v-if="ready" />
        </div>
      </div>
      <div>
        <Slider s-color="blue"></Slider>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/chart/BarChart';
import Slider from '@/components/items/slider';
export default {
  name: 'frequency',
  components: { Slider, BarChart },
  data: () => {
    return {
      index: 0,
      slide: 1,
      slide2: 1,
      optionsSeg: {
        color: ['#3CB1D7', '#6DC4E0', '#89D4EC'],
        legend: {
          padding: [0, 30, 30, 30],
          type: 'scroll',
          data: [],
          left: 'auto',
          selected: {}
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(val) {
              return val + ' M';
            }
          }
        },
        series: []
      },
      optionsSegP: {
        color: ['#3CB1D7', '#6DC4E0', '#89D4EC'],
        legend: {
          padding: [0, 30, 30, 30],
          type: 'scroll',
          data: [],
          left: 'auto'
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: {
          type: 'value',
          max: 100,
          axisLabel: {
            formatter: function(val) {
              return val + ' %';
            }
          }
        },
        series: []
      },
      optionsCli: {
        color: ['#3CB1D7', '#6DC4E0', '#89D4EC'],
        legend: {
          padding: [0, 30, 30, 30],
          type: 'scroll',
          data: [],
          left: 'auto'
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(val) {
              return val;
            }
          }
        },
        series: []
      },
      optionsCliP: {
        color: ['#3CB1D7', '#6DC4E0', '#89D4EC'],
        legend: {
          padding: [0, 30, 30, 30],
          type: 'scroll',
          data: [],
          left: 'auto'
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: {
          type: 'value',
          max: 100,
          axisLabel: {
            formatter: function(val) {
              return val + ' %';
            }
          }
        },
        series: []
      },
      ready: true
    };
  },
  watch: {
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    }
  },
  created() {},
  mounted() {
    this.init().then(() => {});
  },
  methods: {
    selectType(t) {
      this.fType = t;
    },
    exportAll() {},
    getReviewData() {
      this.index++;
      this.$h.get('/stat/increasing/get-years').then(r => {
        this.index--;
      });
    },
    getSegmentsData() {
      this.index++;
      this.$h
        .get('/stat/increasing/get-seg-rev')
        .then(r => {
          let months = r.data.data.m;
          let p = r.data.data.p;
          let mt = [];
          for (let m in months) {
            mt.push(this.$t('monthNames')[m].substring(0, 3));
          }

          this.optionsSeg.xAxis[0].data = mt;
          let dc = r.data.data.r;
          let ind = 1;
          for (let c in dc) {
            this.optionsSeg.legend.selected[dc[c].title] = true;
            this.optionsSeg.legend.data.push(dc[c].title);
            this.optionsSeg.series.push({
              name: dc[c].title,
              type: 'bar',
              stack: 'category2',
              tooltip: {
                valueFormatter: function(value) {
                  return value;
                }
              },
              label: {
                show: true,
                formatter: function(val) {
                  const r = val.value;
                  return r < 1 ? '' : r;
                },
                color: '#ffffff',
                fontSize: 8
              },
              itemStyle: {
                borderRadius: c == dc.length - 1 ? [4, 4, 0, 0] : 0 //, 0, 0],
                //shadowBlur: 3
              },
              data: dc[c].data
            });
            ind++;
          }
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    getSegmentsPData() {
      this.index++;
      this.$h
        .get('/stat/increasing/get-seg-p-rev')
        .then(r => {
          let months = r.data.data.m;
          let p = r.data.data.p;
          let mt = [];
          for (let m in months) {
            mt.push(this.$t('monthNames')[m].substring(0, 3));
          }

          this.optionsSegP.xAxis[0].data = mt;

          let dc2 = r.data.data.r;
          let ind2 = 1;
          for (let c in dc2) {
            this.optionsSegP.legend.data.push(dc2[c].title);
            this.optionsSegP.series.push({
              name: dc2[c].title,
              type: 'bar',
              stack: 'category2',
              tooltip: {
                valueFormatter: function(value) {
                  return value;
                }
              },
              label: {
                show: true,
                position: 'inside',
                formatter: function(val) {
                  const r = parseInt(val.data);
                  return r < 1 ? '' : r + '%';
                },
                color: '#ffffff',
                fontSize: 8
              },
              itemStyle: {
                borderRadius: c == dc2.length - 1 ? [4, 4, 0, 0] : 0 //, 0, 0],
                //shadowBlur: 3
              },
              data: dc2[c].data
            });
            ind2++;
          }
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    getClientsData() {
      this.index++;
      this.$h
        .get('/stat/increasing/get-c-seg-rev')
        .then(r => {
          let months = r.data.data.m;
          let p = r.data.data.p;
          let mt = [];
          for (let m in months) {
            mt.push(this.$t('monthNames')[m].substring(0, 3));
          }

          this.optionsCli.xAxis[0].data = mt;
          let dc = r.data.data.r;
          let ind = 1;
          for (let c in dc) {
            this.optionsCli.legend.data.push(dc[c].title);
            this.optionsCli.series.push({
              name: dc[c].title,
              type: 'bar',
              stack: 'category2',
              tooltip: {
                valueFormatter: function(value) {
                  return value;
                }
              },
              label: {
                show: true,
                // position: 'center',
                formatter: function(val) {
                  const r = val.value;
                  return r < 10 ? '' : r;
                },
                color: '#ffffff',
                fontSize: 8
              },
              itemStyle: {
                borderRadius: c == dc.length - 1 ? [4, 4, 0, 0] : 0 //, 0, 0],
                //shadowBlur: 3
              },
              data: dc[c].data
            });
            ind++;
          }

          let dc2 = r.data.data.d2;
          let ind2 = 1;
          for (let c in dc2) {
            this.optionsCliP.legend.data.push(dc2[c].title);
            this.optionsCliP.series.push({
              name: dc2[c].title,
              type: 'bar',
              stack: 'category2',
              tooltip: {
                valueFormatter: function(value) {
                  return value;
                }
              },
              label: {
                show: true,
                position: 'inside',
                formatter: function(val) {
                  const r = parseInt(val.data);
                  return r < 10 ? '' : r + '%';
                },
                color: '#ffffff',
                fontSize: 8
              },
              itemStyle: {
                borderRadius: c == dc2.length - 1 ? [4, 4, 0, 0] : 0 //, 0, 0],
                //shadowBlur: 3
              },
              data: dc2[c].data
            });
            ind2++;
          }
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    getClientsPData() {
      this.index++;
      this.$h
        .get('/stat/increasing/get-c-seg-p-rev')
        .then(r => {
          let months = r.data.data.m;

          let mt = [];
          for (let m in months) {
            mt.push(this.$t('monthNames')[m].substring(0, 3));
          }

          this.optionsCliP.xAxis[0].data = mt;

          let dc2 = r.data.data.r;
          let ind2 = 1;
          for (let c in dc2) {
            this.optionsCliP.legend.data.push(dc2[c].title);
            this.optionsCliP.series.push({
              name: dc2[c].title,
              type: 'bar',
              stack: 'category2',
              tooltip: {
                valueFormatter: function(value) {
                  return value;
                }
              },
              label: {
                show: true,
                position: 'inside',
                formatter: function(val) {
                  const r = parseInt(val.data);
                  return r < 10 ? '' : r + '%';
                },
                color: '#ffffff',
                fontSize: 8
              },
              itemStyle: {
                borderRadius: c == dc2.length - 1 ? [4, 4, 0, 0] : 0 //, 0, 0],
                //shadowBlur: 3
              },
              data: dc2[c].data
            });
            ind2++;
          }
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    async init() {
      await this.getReviewData();
      await this.getSegmentsData();
      await this.getSegmentsPData();
      await this.getClientsData();
      await this.getClientsPData();
    }
  }
};
</script>

<style scoped></style>
