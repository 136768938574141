<template>
  <q-page className="ub-page">
    <Z_revenue_plan v-if="this.$store.state.user.type === 'zarina'" />
    <G_revenue_plan v-else-if="this.$store.state.user.type === 'goods'" />
    <S_revenue_plan v-else-if="this.$store.state.user.type === 'services'" />
    <D_revenue_plan v-else />
  </q-page>
</template>

<script>
import Z_revenue_plan from '@/components/revenue_plan/zarina/revenue_plan';
import G_revenue_plan from '@/components/revenue_plan/goods/revenue_plan';
import S_revenue_plan from '@/components/revenue_plan/services/revenue_plan';
import D_revenue_plan from '@/components/revenue_plan/default/revenue_plan';

export default {
  name: 'revenue_plan',
  components: { Z_revenue_plan, D_revenue_plan, G_revenue_plan, S_revenue_plan },
  data: () => {
    return {};
  }
};
</script>

<style scoped></style>
