<template>
  <q-page class="ub-page">
    <div class="row">
      <div class="col-12 col-md-12">
        <b-filter
          v-if="fReady"
          @selectPeriod="selectFType"
          @selectFilters="selectFilters"
          :category="category"
          :f-types="fTypes"
          :m-types="mTypes"
          :rangDate="rangDate"
        ></b-filter>
      </div>
    </div>
    <div class="row">
      <div :class="fType == 'quart' ? 'col-12 col-md-6' : 'col-12 col-md-12'">
        <div class="bvm_border rev_graf">
          <label class="graf_title"
            >Выручка
            <select class="select_types" v-model="optType">
              <option v-for="t in gTypes" :value="t" :key="'b' + t">{{ $t('ket_gtypes')[t] }}</option>
            </select>
            <q-img @click="addFav('keyBenchmarking_Revenue', options, 'line')" src="/images/icons/favorite.svg" alt="fav" class="favorite_h" />
          </label>
          <div>
            <BarChart :ch-options="options" style="height: 350px" v-if="ready" />
          </div>
          <div class="row">
            <div class="col-md-5 col-12">
              <div class="rew_f_s" style="display: none !important;">
                <img src="/images/icons/chart/setting.svg" />
                <span class="rev_f_text">{{ $t('keyChange_plan_manually') }}</span>
              </div>
            </div>
            <div class="col-md-5 col-12">
              <div class="rew_f_s" style="display: none !important;">
                <img src="/images/icons/chart/import_b.svg" alt="import_b" />
                <span class="rev_f_text">{{ $t('keyImport_new_plan') }}</span>
              </div>
            </div>
            <div class="col-md-2 col-12">
              <div class="rew_f_s" style="display:none;cursor: pointer" @click="openLink('plan')">
                <img src="/images/icons/chart/import.svg" alt="import" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-12">
        <div class="bvm_border rev_col_graf">
          <LineChart :ch-options="optionsData" style="height: 400px" v-if="ready" />
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import BarChart from '@/chart/BarChart';
import bFilter from '@/components/items/zFilter';
import { Notify } from 'quasar';
import LineChart from '@/chart/LineChart.vue';

export default {
  name: 'revenue_plan',
  components: { LineChart, bFilter, BarChart },
  data: () => {
    return {
      s_data: [],
      fTypes: ['day', 'weak', 'month', 'quart'],
      gTypes: ['cats', 'channel'],
      optType: 'cats',
      barType: 'collect',
      fType: 'year',
      mTypes: [],
      category: [],
      fShow: false,
      ready: false,
      fReady: false,
      setRange: 0,
      filters: {},
      rangDate: [],
      options: {
        color: ['#718096', '#176877', '#00898A', '#23AA8F', '#64C987', '#AAE479', '#CDFA6E'],
        legend: {
          padding: [0, 0, 30, 30],
          width: '80%',
          type: 'scroll',
          data: []
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(val) {
              return val / 1000000 + ' M';
            }
          }
        },
        series: []
      },
      index: 0,
      mt: [],
      bUrl: '/stat/',
      optionsData: {
        series: [],
        color: ['#718096', '#3A78D3'],
        legend: {
          padding: [0, 0, 45, 30],
          right: 30,
          top: 45,
          width: '50%',
          //type: 'scroll',
          data: [],
          overflow: 'break'
        },
        tooltip: {
          trigger: 'axis',
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], '10%'];
          },
          renderMode: 'html',
          formatter: function(val) {
            let oldY = val[0].data;
            let oldYN = val[1].data;
            if (oldYN == null) {
              oldYN = val[2].data;
            }

            let d = document.createElement('div');
            d.classList.add('rew_tooltip');
            if (oldYN && oldY) {
              let proc = (oldYN - oldY) / oldYN;
              let pc =
                proc >= 0
                  ? '<span class="rew_f_green">' +
                    proc.toLocaleString('ru-Ru', {
                      style: 'percent',
                      currency: 'RUB',
                      maximumFractionDigits: 2
                    }) +
                    '</span>'
                  : '<span class="rew_f_red">' +
                    proc.toLocaleString('ru-Ru', {
                      style: 'percent',
                      currency: 'RUB',
                      maximumFractionDigits: 2
                    }) +
                    '</span>';
              d.innerHTML =
                '' +
                '<div class="row">' +
                '<div class="col-md-8 col-8"><span class="summ">' +
                (oldYN - oldY).toLocaleString('ru-Ru', {
                  style: 'currency',
                  currency: 'RUB',
                  maximumFractionDigits: 0
                }) +
                '</span></div>' +
                '<div class="col-md-4 col-4"><img src="/images/icons/mini_ch.svg" alt=""><span class="proc">' +
                pc +
                '</span></div>' +
                '</div><div class="row">' +
                '<div class="col-md-6 col-6"><span class="oldY">' +
                parseInt(oldYN).toLocaleString('ru-Ru', {
                  style: 'currency',
                  currency: 'RUB',
                  maximumFractionDigits: 0
                }) +
                '</span></div>' +
                '<div class="col-md-6 col-6"><span class="oldYN">' +
                parseInt(oldY).toLocaleString('ru-Ru', {
                  style: 'currency',
                  currency: 'RUB',
                  maximumFractionDigits: 0
                }) +
                '</span></div>' +
                '</div>';
            } else if (oldYN) {
              return parseInt(oldYN).toLocaleString('ru-Ru', {
                style: 'currency',
                currency: 'RUB',
                maximumFractionDigits: 0
              });
            } else if (oldY) {
              return parseInt(oldY).toLocaleString('ru-Ru', {
                style: 'currency',
                currency: 'RUB',
                maximumFractionDigits: 0
              });
            }
            return d;
          },
          axisPointer: {
            animation: false,
            type: 'shadow'
          }
        },
        xAxis: {
          step: 1,
          scale: true,
          splitLine: {
            show: false
          },
          boundaryGap: [0, 0.01],
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          scale: true,
          type: 'value',
          splitLine: {
            show: true
          },
          axisLabel: {
            formatter: function(val) {
              return val.toLocaleString('ru-Ru', {
                style: 'currency',
                currency: 'RUB',
                maximumFractionDigits: 0
              });
            }
          }
        },
        title: {},
        grid: {
          top: 100,
          right: 30
        }
      }
    };
  },
  created() {
    this.getReviewData();
  },
  watch: {
    optType(val) {
      let l = this.options.legend.data.length;
      this.options.legend.data.splice(0, l);
      let r = this.options.series.length;
      this.options.series.splice(0, r);
      this.getPlane();
    },
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    },
    filters() {
      if (this.index == 0) {
        this.fType = this.filters.fType;
        this.optionsData.xAxis.data.splice(0, this.optionsData.xAxis.data.length);
        this.optionsData.series.splice(0, this.optionsData.series.length);
        this.optionsData.legend.data.splice(0, this.optionsData.legend.data.length);
        let t = this.options.legend.data.length;
        this.options.legend.data.splice(0, t);
        let f = this.options.series.length;
        this.options.series.splice(0, f);

        this.init();
      }
    }
  },
  methods: {
    openLink(fileName) {
      this.$store.state.progress = true;
      this.$h
        .get(`/export/csv/${fileName}`)
        .then(function(response) {
          if (response.data.error && response.data.error == true) {
            Notify.create({
              position: 'top-right',
              type: 'errors',
              message: 'Файл не готов',
              timeout: 2000
            });
          } else {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // IE variant
              window.navigator.msSaveOrOpenBlob(new Blob([response.data], { type: 'text/csv' }), fileName);
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }
          }
        })
        .then(() => {
          this.$store.state.progress = false;
        });
    },
    exportAll() {},
    addFav(type, data, g) {
      this.$h.post('/favorite/add', { t: type, d: data, g: g }).then(r => {});
    },
    selectFType(arg) {
      //this.fType = arg;
    },
    selectFilters(arg) {
      this.filters = {};
      for (let a in arg) {
        this.filters[a] = arg[a];
      }
      //\\
    },
    getReviewData() {
      this.index++;
      this.$h
        .get(`${this.bUrl}revenue/get-years`)
        .then(r => {
          this.category = r.data.data.category;
          this.mTypes = r.data.data.types;
          let d = r.data.data.date;
          for (let l in d) {
            this.rangDate.push(parseInt(d[l].y));
          }
        })
        .then(() => {
          this.index--;
        })
        .then(() => {
          this.fReady = true;
        });
    },
    getData() {
      this.index++;
      this.$h
        .post(`${this.bUrl}rvw/get-data`, { f: this.filters })
        .then(r => {
          let months = r.data.data.m;
          let d1 = r.data.data.d1;
          let d2 = r.data.data.d2;
          let p1 = r.data.data.p1;
          let p2 = r.data.data.p2;
          let mt = [];

          switch (this.fType) {
            case 'month':
              for (let m in months) {
                mt.push(this.$t('monthNames')[m]);
              }
              break;
            default:
              mt = months;
              break;
          }

          this.optionsData.xAxis.data = mt;

          this.optionsData.legend.data.push({
            name: `Выручка\n${d1.y}`,
            icon: 'image://https://' + window.location.host + '/images/icons/chart/up_f.svg',
            padding: [0, 15]
          });
          this.optionsData.series.push({
            name: `Выручка\n${d1.y}`,
            data: d1.d,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          });
          this.optionsData.legend.data.push({
            name: `Выручка\n${d2.y}`,
            icon: 'image://https://' + window.location.host + '/images/icons/chart/up_s.svg',
            padding: [0, 15]
          });
          this.optionsData.series.push({
            name: `Выручка\n${d2.y}`,
            data: d2.d,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          });
          this.optionsData.legend.data.push({
            name: `Прогноз выручки\n${p1.y}`,
            icon: 'image://https://' + window.location.host + '/images/icons/chart/up_f_p.svg',
            padding: [0, 15]
          });
          this.optionsData.series.push({
            name: `Прогноз выручки\n${p1.y}`,
            data: p1.d,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3,
              type: 'dashed'
            }
          });
          this.optionsData.legend.data.push({
            name: `Потенциал выручки\n${p2.y}`,
            icon: 'image://https://' + window.location.host + '/images/icons/chart/up_s_p.svg',
            padding: [0, 15]
          });

          this.optionsData.series.push({
            name: `Потенциал выручки\n${p2.y}`,
            data: p2.d,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3,
              type: 'dashed'
            }
          });
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },
    async getPlane() {
      this.ready = false;
      this.index++;
      await this.$h
        .post(`${this.bUrl}revenue/get-${this.optType}`, { f: this.filters })
        .then(r => {
          let dc = r.data.data.cd;
          let months = r.data.data.m;
          let mt = [];
          console.log(this.months);
          switch (this.fType) {
            case 'month':
              for (let m in months) {
                console.log(m);
                mt.push(this.$t('monthNames')[m].substring(0, 3));
              }
              break;
            case 'year':
              mt = months;
              break;
            case 'weak':
              mt = months;
              break;
            case 'quart':
              mt = months;
              break;
            case 'day':
              mt = months;
              break;
          }
          this.options.xAxis[0].data = mt;
          let ind = 1;
          for (let c in dc) {
            this.options.legend.data.push(dc[c].title);
            this.options.series.push({
              name: dc[c].title,
              type: 'bar',
              stack: 'category',
              tooltip: {
                valueFormatter: function(value) {
                  return value.toLocaleString('ru-Ru', {
                    style: 'currency',
                    currency: 'RUB',
                    maximumFractionDigits: 0
                  });
                }
              },
              itemStyle: {
                borderRadius: ind == dc.length ? [50, 50, 0, 0] : 0, //, 0, 0],
                //shadowBlur: 3
                opacity: 1
              },
              data: dc[c].data
            });
            ind++;
          }

          let dc2 = r.data.data.cd2;
          ind = 1;
          for (let c in dc2) {
            this.options.series.push({
              name: dc2[c].title,
              type: 'bar',
              stack: 'category2',
              emphasis: {
                focus: 'series'
              },
              tooltip: {
                valueFormatter: function(value) {
                  let ret = value.toLocaleString('ru-Ru', {
                    style: 'currency',
                    currency: 'RUB',
                    maximumFractionDigits: 0
                  });
                  return ret;
                }
              },
              itemStyle: {
                borderRadius: ind == dc2.length ? [50, 50, 0, 0] : 0, //, 0, 0],
                //shadowBlur: 3
                opacity: 0.3
              },
              data: dc2[c].data
            });
            ind++;
          }
        })
        .then(() => {
          this.ready = true;
          this.index--;
        });
    },

    async init() {
      await this.getPlane();
      this.getData();
    }
  },
  mounted() {
    this.optionsData.title = {
      text: 'Динамика фактической и потенциальной выручки',
      textAlign: 'left',
      padding: [45, 30],
      overflow: 'break',
      textStyle: {
        width: '50%',
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        overflow: 'break',
        /* identical to box height, or 20px */
        display: 'flex',
        alignItems: 'center',
        color: '#05156D'
      }
    };
  }
};
</script>

<style scoped></style>
