<template>
  <div>
    <z_customers :f-type="fType" :filters="filters" v-if="$store.state.user.type === 'zarina'"></z_customers>
    <g_customers :f-type="fType" :filters="filters" v-else-if="$store.state.user.type === 'goods'"></g_customers>
    <d_customers :f-type="fType" :filters="filters" v-else></d_customers>
  </div>
</template>

<script>
import d_customers from '@/components/growth/default/customers';
import z_customers from '@/components/growth/zarina/customers';
import g_customers from '@/components/growth/goods/customers';
export default {
  name: 'customers',
  components: { z_customers, d_customers, g_customers },
  props: {
    fType: {
      type: String
    },
    filters: {
      type: Object
    }
  }
};
</script>
