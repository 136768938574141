<template>
  <q-toolbar style="padding: 40px">
    <label class="bvm_h6">{{ title }}<br /><label class="bvm_h5"></label></label>
    <q-space></q-space>
    <!--
    <q-btn
      v-if="smallScreen"
      :text-color="showSearchToolbar ? 'primary' : ''"
      dense
      unelevated
      icon="search"
      class="q-ma-xs"
      @click="showSearchToolbar = !showSearchToolbar"
    ></q-btn>
    -->
    <q-btn dense unelevated class="q-ma-xs bvm_alrt_btn" @click="prompt = true">
      <div class="row">
        <img style="padding: 0 10px" src="/images/add_user.svg" alt="" />
        <span style="padding-right: 10px">{{ $t('inviteAFriend') }}</span>
      </div>
    </q-btn>
    <q-btn dense unelevated icon="mdi-bell" class="q-ma-xs bvm_alrt_btn">
      <q-badge color="red" v-if="cNewMessage" floating>{{ cNewMessage }}</q-badge>
      <q-menu v-model="messagesOpen">
        <messages @close="messagesOpen = false"></messages>
      </q-menu>
    </q-btn>
    <!--
    <q-btn-dropdown dense unelevated v-model="settingsOpen" dropdown-icon="settings" class="ub-btn-dropdown-bare q-ma-xs">
      <settings @close="settingsOpen = false"></settings>
    </q-btn-dropdown>
-->
    <q-btn-dropdown unelevated v-model="userInfoOpen" no-caps class="bvm_acc">
      <template v-slot:label>
        {{ $store.state.user.username }}
        {{ $store.state.user.surname }}
      </template>
      <user-info></user-info>
    </q-btn-dropdown>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 420px">
        <q-card-section class="invite_f" v-if="!success && !send">
          <div style="display: flow-root;">
            <q-btn style="float: right;" flat v-close-popup round dense icon="close" />
          </div>
          <div class="invite_img">
            <img src="/images/invite.png" />
            <div class="inv_text">
              Пригласите друга на бесплатный пробный период и получите месяц в подарок!
            </div>
            <div class="inv_text_m">
              <span v-if="errorText" style="padding: 10px 0;color: red">Этот пользователь уже был приглашен</span>
              <span v-else style="padding: 10px 0">Кого вы хотите пригласить?</span>
              <div :class="'inv_text_inp ' + (errorText ? 'inv_red' : 'inv_grey')">
                <input type="email" v-model="invemail" />
                <div class="close_img_inp" @click="invemail = ''">
                  <img src="/images/icons/clear.svg" />
                </div>
              </div>
              <div class="inv_text_but" @click="sendInv()">
                Отправить
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-section class="invite_f" v-else-if="success && !send">
          <div style="display: flow-root;">
            <q-btn style="float: right;" flat v-close-popup round dense icon="close" />
          </div>
          <div class="invite_img">
            <img src="/images/invite_ok.png" />
            <div class="inv_text">
              Приглашение отправлено!
            </div>
            <div class="inv_text_m">
              <q-btn class="inv_text_but_a" v-close-popup>
                <div>Отлично!</div>
              </q-btn>
            </div>
          </div>
        </q-card-section>
        <q-card-section class="invite_f" v-else-if="send">
          <div class="inv_text_m">
            <q-inner-loading :showing="send" label="Please wait..." label-class="text-teal" label-style="font-size: 1.1em">
              <q-spinner-gears size="50px" :color="$store.state.color" />
            </q-inner-loading>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary"> </q-card-actions>
      </q-card>
    </q-dialog>
  </q-toolbar>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import UserInfo from '@/components/user/userinfo';
import Messages from '@/components/user/messages';

export default {
  name: 'mheader',
  components: {
    UserInfo,
    Messages
  },
  props: {
    page: {
      type: String,
      default: 'turnover'
    },
    title: {
      type: String
    },
    menuMini: {
      type: Boolean
    },
    subTitle: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      invemail: '',
      errorText: false,
      send: false,
      prompt: false,
      cNewMessage: 0,
      leftShown: true,
      rightShown: false,
      settingsOpen: false,
      messagesOpen: false,
      userInfoOpen: false,
      showSearchToolbar: false,
      searchQuery: '',
      transitionName: '',
      testColors: null,
      menuItems: [],
      success: false
    };
  },
  watch: {},
  methods: {
    sendInv() {
      if (this.invemail) {
        this.send = !this.send;
        this.$h.post('/users/invite', { e: this.invemail }).then(r => {
          this.send = !this.send;
          this.success = r.data.data;
          this.errorText = r.data.data;
        });
      } else {
        this.errorText = true;
      }
    }
  }
};
</script>

<style scoped>
.invite_f {
  width: 420px;
  padding: 25px;
}
.invite_f .invite_img {
  margin: auto;
  display: grid;
  justify-content: center;
}
.invite_img img {
  margin: auto;
}
.inv_text {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  /* or 24px */

  text-align: center;

  color: #252f69;
}
.inv_text_m {
  text-align: center;
  margin: 20px;
  display: grid;
  justify-content: center;

  color: #828ab6;
}

.inv_text_inp input {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  line-height: 100%;
  width: 85%;
  height: 100%;
  float: left;
  border: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.inv_text_inp input:focus-visible {
  border: none;
  border-color: #1fb978;
}
.inv_grey {
  width: 260px;
  height: 38px;

  background: #fdfdfd;
  /* Grey */
  text-align: center;
  border: 1px solid #bac0e2;
  border-radius: 10px;
}
.inv_red {
  width: 260px;
  height: 38px;

  background: #fdfdfd;
  /* Grey */
  text-align: center;
  border: 1px solid red;
  border-radius: 10px;
}

.inv_text_but {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 15px;
  gap: 10px;
  margin: 15px 0;
  width: 260px;
  height: 39px;

  background: #cecece;
  border-radius: 10px;
  color: white;
}
.close_img_inp {
  width: 15%;
  float: right;
  display: grid;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}
.inv_text_but:hover {
  background: #3a78d3;
  color: white;
}
.inv_text_but_a,
.inv_text_but_a:hover {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 15px 0;
  width: 260px;
  height: 39px;

  background: #1fb978;
  border-radius: 10px;
  color: white;
}
</style>
