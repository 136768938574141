<template>
  <div v-if="s_data.length">
    <q-carousel v-model="nSlide" swipeable :control-color="'s_' + sColor" height="165px" class="bvm_border ave_graf" ref="carousel">
      <q-carousel-slide v-for="(s, i) in s_data" :name="i" :key="i" class="column no-wrap">
        <div class="row bslide_m">
          <div class="col-5">
            <label :class="'bslide_tip bsl_color_' + sColor">Задача:</label>
            <div class="bslide_text">{{ s.title }}</div>
          </div>
          <div class="col-7">
            <div class="row">
              <div class="col-4">
                <div :class="'bslide_tip bsl_color_' + sColor">
                  <img :src="'/images/icons/slide/pric_info_' + sColor + '.svg'" :alt="$t('fav')" />
                  {{ $t('KPI_fact') }}
                </div>
                <div class="bslide_tip_text ">{{ s.kpif }}</div>
              </div>
              <div class="col-4">
                <div :class="'bslide_tip bsl_color_' + sColor">
                  <img :src="'/images/icons/slide/pric_target_' + sColor + '.svg'" :alt="$t('fav')" />
                  {{ $t('KPI_goal') }}
                </div>
                <div class="bslide_tip_text">{{ s.kpit }}</div>
              </div>
              <div class="col-4">
                <div :class="'bslide_tip bsl_color_' + sColor">
                  <img :src="'/images/icons/slide/pric_shape_' + sColor + '.svg'" :alt="$t('fav')" />
                  {{ $t('keyObject') }}
                </div>
                <div class="bslide_tip_text">{{ s.object }}</div>
              </div>
            </div>
          </div>
        </div>
        <div :class="'foot_slide_more bcbsl_color_' + sColor">
          <div class="row">
            <div class="col-1">
              <img :src="'/images/icons/almaz_' + sColor + '.svg'" :alt="$t('fav')" />
            </div>
            <div class="col-2">
              <div class="foot_info">
                <div class="p_t">{{ $t('keyRevenue_up') }}</div>
                <div :class="'summ bsl_color_' + sColor">
                  {{
                    s.summ.toLocaleString('ru-Ru', {
                      style: 'currency',
                      currency: 'RUB',
                      maximumFractionDigits: 0
                    })
                  }}
                </div>
              </div>
            </div>
            <div class="col-9">
              <div class="foot_info">
                <div class="row">
                  <div class="col-6">
                    <div @click="s.modal.show = !s.modal.show" :class="'slide_t_more bsl_color_' + sColor">
                      <img :src="'/images/icons/slide/more_' + sColor + '.svg'" :alt="$t('fav')" />
                      <span>{{ $t('key_detailed') }}</span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div v-if="s.link" @click="openLink(s.link)" :class="'slide_but bbsl_color_' + sColor">
                      {{ $t('take_work') }}
                    </div>
                    <div v-else :class="'slide_but bbsl_color_' + sColor">{{ $t('take_work') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </q-carousel-slide>
    </q-carousel>
    <div class="row justify-center">
      <div :class="'carousel_pll_rr bsl_color_' + sColor" @click="nSlide = 0">
        <div class="r_arrow">&#10094;</div>
      </div>
      <div class="carousel_pll_rt" v-for="(q, i) in s_data" :key="'qq' + i">
        <div @click="nSlide = i" :class="'r_arrow bbsl_color_' + sColor + (i == nSlide ? ' point_rr_e' : ' point_rr')"></div>
      </div>

      <div :class="'carousel_pll_rr bsl_color_' + sColor" @click="nSlide = s_data.length - 1">
        <div class="r_arrow">&#10095;</div>
      </div>
    </div>
    <q-dialog v-for="(s, i) in s_data" :key="'modal_' + i" v-model="s.modal.show">
      <q-card class="bvm_modal_rec">
        <q-card-section>
          <q-table
            class="prod-serv-dynamic"
            :data="s.modal.rows"
            :columns="s.modal.columns"
            :loading="s.modal.loading"
            row-key="id"
            style="height: 400px"
            :rows-per-page-options="[0]"
          >
            <template v-slot:header="props">
              <q-tr>
                <q-th align="left" style="z-index: 5;width: max-content;" v-for="m in props.cols" :hidden="m.field == 'id'" :key="Math.random() + m.field">
                  {{ m.label }}
                </q-th>
              </q-tr>
            </template>
            <template v-slot:body="props">
              <q-tr :id="props.row.title" :data-set="props.row.id">
                <q-td v-for="col in props.cols" :key="Math.random() + col.field" :hidden="col.field == 'id'">
                  <span v-if="col.field == 'category'">
                    <span class="cat_info" @click="props.expand = !props.expand">
                      &nbsp;<b class="c_title">{{ col.value }}</b></span
                    >
                  </span>
                  <div v-else :class="'type_cat type_cat_' + sColor" style="width: auto;text-align: right">{{ col.value }}</div>
                </q-td>
              </q-tr>
              <q-tr style="background-color: rgba(220,220,220,0.38)" v-show="props.expand" v-for="sub in props.row.sub" :key="Math.random() + sub.id">
                <q-td
                  v-for="(s, i) in sub"
                  :key="Math.random() + i"
                  :hidden="i == 'id'"
                  style="text-align: right; white-space: pre-wrap;max-width: 75px; font-size: 12px;height: max-content !important;"
                >
                  <span v-if="i == 'category'" class="cat_info" style="min-width: 150px"> {{ s }} </span>
                  <span v-else :class="'type_cat type_cat_' + sColor" style="width: auto;text-align: right">{{ s }}</span>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:bottom class="tt"></template>
          </q-table>
        </q-card-section>
        <q-card-actions align="center">
          <div style="text-align: center">
            <div @click="s.modal.show = false" :class="'slide_t_more bsl_color_' + sColor">
              <img :src="'/images/icons/slide/more_' + sColor + '.svg'" :alt="$t('fav')" />
              <span>{{ $t('key_turn') }}</span>
            </div>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { Notify } from 'quasar';

export default {
  name: 'check_slider',
  props: {
    sColor: {
      type: String,
      default: 'black'
    },
    s_data: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data: () => {
    return {
      fixed: false,
      nSlide: 0,
      autoplay: false
    };
  },
  methods: {
    openLink(fileName) {
      this.$store.state.progress = true;
      this.$h
        .get(`/export/csv/${fileName}.csv`)
        .then(function(response) {
          if (response.data.error && response.data.error == true) {
            Notify.create({
              position: 'top-right',
              type: 'errors',
              message: 'Файл не готов',
              timeout: 2000
            });
          } else {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // IE variant
              window.navigator.msSaveOrOpenBlob(new Blob([response.data], { type: 'text/csv' }), fileName);
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }
          }
        })
        .then(() => {
          this.$store.state.progress = false;
        });
    }
  }
};
</script>

<style scoped>
.bvm_modal_rec {
  min-width: 700px;
  width: 75%;
  border-radius: 16px;
}
</style>
