<template>
  <div>
    <Z_products_service :f-type="fType" :filters="filters" v-if="$store.state.user.type === 'zarina'"></Z_products_service>
    <T_products_service :f-type="fType" :filters="filters" v-else></T_products_service>
  </div>
</template>

<script>
import T_products_service from '@/components/average_check/default/t_products_service';
import Z_products_service from '@/components/average_check/zarina/z_products_service';
export default {
  name: 'products_service',
  components: { Z_products_service, T_products_service },
  props: {
    fType: {
      type: String
    },
    filters: {
      type: Object
    }
  }
};
</script>

<style lang="sass">
.prod-serv-tr
  height: 30px
  border-radius: 6px
  border: none
  border-color: rgba(0, 0, 0, 0) !important

.prod-serv-dynamic

  tbody.q-virtual-scroll__padding
    display: none

  tbody th, tbody td
    padding: 3px

  tbody td
    background: #FFF8EE
    padding: 0 2px
    height: 24px !important

  thead tr th
    position: sticky
    z-index: 1
    background-color: #ffffff !important
    text-align: center

  thead tr:last-child th
    z-index: 3

  thead tr:first-child th
    top: 0
    z-index: 1

  tr:first-child th:first-child
    /* highest z-index */
    z-index: 3

  td:first-child
    z-index: 1

  td:first-child, th:first-child
    position: sticky
    left: 0
    width: 200px !important
    white-space: pre-wrap
</style>
